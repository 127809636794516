import produce from 'immer'
import { max } from 'lodash'
import { DateTime } from 'luxon'

export const CART_ADD_WORKPLACE = 'CART::ADD_WORKPLACE'
export const CART_REMOVE_WORKPLACE = 'CART::REMOVE_WORKPLACE'
export const CART_INCREMENT_WORKPLACE_QUANTITY =
  'CART::INCREMENT_WORKPLACE_QUANTITY'
export const CART_DECREMENT_WORKPLACE_QUANTITY =
  'CART::DECREMENT_WORKPLACE_QUANTITY'
export const CART_INCREMENT_WORKPLACE_ACCESSORY_QUANTITY =
  'CART::INCREMENT_WORKPLACE_ACCESSORY_QUANTITY'
export const CART_DECREMENT_WORKPLACE_ACCESSORY_QUANTITY =
  'CART::DECREMENT_WORKPLACE_ACCESSORY_QUANTITY'
export const CART_WORKPLACE_CHANGE_TERM = 'CART::WORKPLACE_CHANGE_TERM'
export const CART_CLEAR = 'CART::CLEAR'

export const clearCart = () => ({
  type: CART_CLEAR,
})

export const addWorkplaceToCart = ({ workplace }) => ({
  type: CART_ADD_WORKPLACE,
  payload: { workplace },
})

export const incrementWorkplaceQuantity = index => ({
  type: CART_INCREMENT_WORKPLACE_QUANTITY,
  index,
})

export const decrementWorkplaceQuantity = index => ({
  type: CART_DECREMENT_WORKPLACE_QUANTITY,
  index,
})

export const incrementWorkplaceAccessoryQuantity = (index, field) => ({
  type: CART_INCREMENT_WORKPLACE_ACCESSORY_QUANTITY,
  payload: { index, field },
})

export const decrementWorkplaceAccessoryQuantity = (index, field) => ({
  type: CART_DECREMENT_WORKPLACE_ACCESSORY_QUANTITY,
  payload: { index, field },
})

export const removeWorkplaceFromCart = index => ({
  type: CART_REMOVE_WORKPLACE,
  index,
})

export const changeTerm = (index, term) => ({
  type: CART_WORKPLACE_CHANGE_TERM,
  payload: { index, term },
})

// eslint-disable-next-line no-unused-vars
export const initialState = {
  workplaces: [],
  expiresAt: DateTime.local()
    .plus({ days: 1 })
    .toISO(),
}

const getInitialState = () =>
  // localStorage.getItem('cart')
  //   ? JSON.parse(localStorage.getItem('cart'))
  //   :
  initialState

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case CART_CLEAR: {
      return getInitialState()
    }
    case CART_ADD_WORKPLACE: {
      return produce(state, draft => {
        draft.workplaces.push(action.payload.workplace)
      })
    }
    case CART_INCREMENT_WORKPLACE_QUANTITY: {
      return produce(state, draft => {
        draft.workplaces[action.index].quantity += 1
      })
    }
    case CART_DECREMENT_WORKPLACE_QUANTITY: {
      const newQuantity = state.workplaces[action.index].quantity - 1
      if (newQuantity > 0) {
        return produce(state, draft => {
          draft.workplaces[action.index].quantity = newQuantity
        })
      }
      return state
    }
    case CART_INCREMENT_WORKPLACE_ACCESSORY_QUANTITY: {
      return produce(state, draft => {
        draft.workplaces[action.payload.index][
          action.payload.field
        ].quantity += 1
      })
    }
    case CART_DECREMENT_WORKPLACE_ACCESSORY_QUANTITY: {
      return produce(state, draft => {
        const newQty = max([
          draft.workplaces[action.payload.index][action.payload.field]
            .quantity - 1,
          1,
        ])
        draft.workplaces[action.payload.index][
          action.payload.field
        ].quantity = newQty
      })
    }
    case CART_REMOVE_WORKPLACE: {
      return produce(state, draft => {
        draft.workplaces.splice(action.index, 1)
      })
    }
    case CART_WORKPLACE_CHANGE_TERM: {
      return produce(state, draft => {
        draft.workplaces[action.payload.index].term = action.payload.term
      })
    }
    default:
      return state
  }
}

export default reducer
