import colors from 'Assets/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const NoResultsContainer = styled.div`
  text-align: center;
  font-size: 30px;
  color: ${colors.brownishGrey};
  .searchValue {
    ${props => `color: ${props.theme.firstColor}`}
  }
`

const NoResults = ({ filter }) => (
  <NoResultsContainer>
    Keine Ergebnisse für <span className="searchValue">{filter}</span> gefunden
  </NoResultsContainer>
)

NoResults.propTypes = {
  filter: PropTypes.string.isRequired,
}

NoResults.defaultProps = {}

export default NoResults
