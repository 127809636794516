export default {
  orderDate: 'Bestelldatum',
  header: {
    orderNumber: 'Nummer',
    orderDate: 'Datum',
    company: 'Firma',
    contact: 'Kontakt',
    status: 'Status',
    quantity: '# bestellte Geräte',
    actions: 'Aktionen',
  },
  actions: {
    view: 'Ansehen',
  },
}
