import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 36px;
  ${props =>
    props.active &&
    `
border: 0px;

  &:after {
    content: "";
    position: absolute;
    top: -4px;
    width: calc(100% + 8px);
    background-color: ${props.theme.firstColor};
    height: calc(100% + 8px);
    z-index: 2;
    border-radius: 3px;
  }
`}
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  ${props =>
    props.active &&
    `
    z-index: 4;
    `}
`
export const Icon = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.firstColor};
  ${props =>
    props.active &&
    `
    color: ${colors.white};
    `}
`

export const Arrow = styled.div`
  position: absolute;
  bottom: -18px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid;
  border-top-color: ${props => props.theme.firstColor};
`

export const Title = styled.span`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 0.4px;
  color: ${colors.somecolor};
  ${props =>
    props.active &&
    `
    color: ${colors.white};
    `}
`
