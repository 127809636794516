import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;
  width: 600px;
  & > * {
    flex: 1;
  }
`

export const PeriodLabel = styled.div`
  padding: 8px 0;
  font-size: 22px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: #4f4f4f;
`

export const ContentHeader = styled.div`
  font-family: Titillium Web;
  width: 100%;
  font-size: 23px;
  font-weight: bold;
  line-height: 1.09;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${colors.greyishBrownFive};
  & > div {
    padding: 20px;
  }
`

export const QuantityHeader = styled.div`
  width: 15%;
`
export const ProductTitle = styled.div`
  margin-left: 336px;
`
export const TextNumber = styled.div`
  text-align: right;
`
