import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './styles'

/* eslint-disable react/style-prop-object */

const useStyles = createUseStyles(styles)

const Button = ({
  quantity,
  style,
  height,
  children,
  onClick,
  onDecreaseClick,
  onIncreaseClick,
  loading,
  hollow,
  hideAngle,
}) => {
  const classes = useStyles({ hollow })
  return (
    <div className={classes.box} style={{ ...style, height: `${height}px` }}>
      {quantity && (
        <div className={classes.quantity}>
          <i
            onClick={onIncreaseClick}
            className={classnames('fa', 'fa-caret-up', classes.quantityButton)}
            aria-hidden="true"
          />
          <span className={classes.quantityNumber}>{quantity}</span>
          <i
            onClick={onDecreaseClick}
            className={classnames(
              'fa',
              'fa-caret-down',
              classes.quantityButton,
            )}
            aria-hidden="true"
          />
        </div>
      )}
      <button
        type="button"
        onClick={onClick}
        className={classes.text}
        disabled={loading}
      >
        {children}
        &nbsp;{' '}
        {!hideAngle &&
          (loading ? (
            <FontAwesomeIcon
              style={{ marginLeft: '4px' }}
              icon="circle-notch"
              spin
            />
          ) : (
            <FontAwesomeIcon style={{ marginLeft: '4px' }} icon="angle-right" />
          ))}
      </button>
    </div>
  )
}

Button.propTypes = {
  quantity: PropTypes.number,
  style: PropTypes.object.isRequired, // eslint-disable-line
  height: PropTypes.number,
  children: PropTypes.node.isRequired, // eslint-disable-line
  onClick: PropTypes.func.isRequired,
  onDecreaseClick: PropTypes.func,
  onIncreaseClick: PropTypes.func,
  hideAngle: PropTypes.bool,
  loading: PropTypes.bool,
  hollow: PropTypes.bool,
}

Button.defaultProps = {
  height: 40,
  quantity: undefined,
  loading: false,
  hideAngle: false,
  onDecreaseClick: undefined,
  onIncreaseClick: undefined,
  hollow: false,
}

export default Button

/* eslint-ensable react/style-prop-object */
