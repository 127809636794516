import classnames from 'classnames'
import EmptyCart from 'Components/Common/Cart/EmptyCart'
import {
  ContentHeader,
  ProductTitle,
  QuantityHeader,
  TextNumber,
} from 'Components/Common/UI/Cart'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import WorkplaceLine from './WorkplaceLine'

/* eslint-disable react/no-array-index-key */

const CartContent = () => {
  const alsoCart = useSelector(state => state.alsoCart)

  if (alsoCart.workplaces.length === 0) {
    return <EmptyCart />
  }

  return (
    <>
      <ContentHeader className={classnames('grid-x')}>
        <QuantityHeader className={classnames('cell', 'medium-2')}>
          <Trans ns="cart">quantity</Trans>
        </QuantityHeader>
        <ProductTitle className={classnames('cell', 'auto')}>
          <Trans ns="cart">product</Trans>
        </ProductTitle>
        <TextNumber className={classnames('cell', 'medium-2')}>
          <Trans ns="cart">monthly</Trans>
        </TextNumber>
      </ContentHeader>
      {alsoCart.workplaces
        .filter(w => w)
        .map((w, i) => (
          <WorkplaceLine
            key={`workplace-${w.variant}-${i}`}
            index={i}
            workplace={w}
          />
        ))}
    </>
  )
}

export default CartContent
