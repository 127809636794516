import { createSelector } from 'reselect'
import get from 'lodash/get'

const getUniqueServiceSlugs = serviceList =>
  serviceList
    .reduce((prev, service) => {
      if (prev.findIndex(s => s === service.typeSlug) < 0) {
        prev.push(service.typeSlug)
      }
      return prev
    }, [])
    .map(slug => ({ slug, '@type': 'CloudService' }))

export default slug =>
  createSelector(
    ({ devices: { list } }) => list,
    ({ products: { list } }) => list,
    ({ categories: { list } }) => list,
    ({ pages: { list } }) => list,
    ({ services: { list } }) => getUniqueServiceSlugs(list),
    ({ storeView }) => [
      {
        slug: get(storeView, 'data.seoConfiguration.productOverviewSlug'),
        '@type': 'Category',
      },
    ],
    (devices, products, categories, pages, services, allProducts) =>
      [
        ...devices,
        ...products,
        ...categories,
        ...pages,
        ...services,
        ...allProducts,
      ].find(i => i.slug === slug),
  )
