import React from 'react'
import { createUseStyles } from 'react-jss'
import { compose, withHandlers } from 'recompose'
import { useTranslation } from 'react-i18next'
import Select from 'Components/Common/Layout/Select'
import colors from 'Assets/styles/colors'

import { inputStyles } from '../../Layout/Input'

const useStyles = createUseStyles(inputStyles)

const enhance = compose(
  withHandlers({
    handleChange: ({ input: { onChange } }) => ({ value }) => onChange(value),
  }),
)

const dropdownStyles = {
  container: provided => ({
    ...provided,
    height: '57px',
  }),
  valueContainer: (provided, { selectProps: { icon } }) => ({
    ...provided,
    height: '57px',
    backgroundImage: `url(/assets/icons/${icon}.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px',
    backgroundPositionY: 'center',
    backgroundPositionX: '16px',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    paddingLeft: '40px',
    color: colors.warmGreyFive,
  }),
  input: provided => ({
    ...provided,
    paddingLeft: '40px',
  }),
  singleValue: provided => ({
    ...provided,
    paddingLeft: '40px',
    color: colors.warmGreyFive,
  }),
}

const DropDown = ({
  input: { value },
  label,
  icon,
  dark,
  handleChange,
  placeholder,
  options,
  meta: { touched, error, warning },
}) => {
  const classes = useStyles()
  const { t } = useTranslation('validation')

  return (
    <div>
      <div className={classes.container}>
        <Select
          className="r-select"
          classNamePrefix="r-select"
          styles={dropdownStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
          placeholder={placeholder}
          options={options}
          onChange={handleChange}
          icon={icon}
        />
      </div>
      {touched &&
        ((error && (
          <span className={dark ? classes.darkError : classes.error}>
            {t(`error.${error}`)}
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  )
}

export default enhance(DropDown)
