import colors from 'Assets/styles/colors'
import { DeviceImageType } from 'Lib/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import Image from '../Layout/Image'

const SmallImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SmallImageInner = styled.div`
  width: 110px;
  height: 110px;
  border: 1px solid ${colors.whiteFive};
  border-color: ${props =>
    props.selected ? props.theme.firstColor : colors.whiteFive};
  cursor: pointer;
  margin: 4px;
  border-radius: 3px;
  padding: 4px;
`

const SmallImage = ({ image, set, i }) => (
  <SmallImageContainer>
    <SmallImageInner
      onClick={() => set(i)}
      tabIndex={0}
      onKeyPress={() => set(i)}
      role="button"
    >
      <Image
        src={image.previewImage}
        alt={`small-product-image-${i}`}
        width={100}
        height={100}
      />
    </SmallImageInner>
  </SmallImageContainer>
)

SmallImage.propTypes = {
  image: DeviceImageType.isRequired,
  set: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired, // eslint-disable-line
}

export default SmallImage
