import DeviceForm from 'Components/Aluxo/Device/DeviceForm'
import DynamicTitle from 'Components/Common/DynamicTitle'
import Heading from 'Components/Common/Layout/Heading'
import MetaData from 'Components/Common/Layout/MetaData'
import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Device = () => {
  const { slug } = useParams()

  const device = useSelector(({ devices: { list } }) =>
    list.find(d => d.slug === slug),
  )

  const isAffiliateReseller = useSelector(({ resellerInfo }) =>
    get(resellerInfo, 'data.affiliateReseller'),
  )

  if (!device) {
    return null
  }

  return (
    <>
      <MetaData
        title={device.name}
        metaData={device.metaData}
        canonical={device.slug}
        robots={isAffiliateReseller && 'noindex'}
      />
      <Heading>
        <DynamicTitle
          highlighted={device.manufacturer.name}
          normal={device.name}
        />
      </Heading>
      <DeviceForm device={device} />
    </>
  )
}

export default Device
