import colors from 'Assets/styles/colors'
import LineItem from 'Components/Common/Cart/LineItem'
import Specs from 'Components/Common/CategoryPage/Specs'
import { get, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import DeviceAccessory from './DeviceAccessory'

const styles = {
  accessoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '48px',
    maxWidth: '400px',
    '& > * ': {
      margin: '4px',
    },
  },
  selectContainer: {
    border: {
      style: 'solid',
      width: 1,
      color: colors.whiteSix,
    },
    padding: 8,
    font: {
      size: 15,
    },
  },
  selectType: {
    color: colors.warmGreyFive,
  },
}

export const servicesSelector = createSelector(
  (state, props) =>
    Object.values(props.deviceConfiguration.services).map(service =>
      state.services.list.find(s => s.id === service),
    ),
  services => services,
)

export const deviceSelector = createSelector(
  (state, props) =>
    state.devices.list.find(
      ({ id }) => id === props.deviceConfiguration.device,
    ),
  device => device,
)

const accessorySelector = createSelector(
  (state, props) =>
    props.deviceConfiguration.accessories.map(a => ({
      ...a,
      slug: state.products.list.find(p => p.id === a.product).slug,
    })),
  accessories => accessories,
)

const serviceExtensionSelector = createSelector(
  (state, props) =>
    state.serviceExtensions.list.find(
      ({ id }) => id === props.deviceConfiguration.serviceExtension,
    ),
  serviceExtension => serviceExtension,
)

const useStyles = createUseStyles(styles)

const Device = ({ deviceConfiguration, index }) => {
  const classes = useStyles()
  const { t } = useTranslation('catalog')

  const { protectionPlanType } = deviceConfiguration

  const { device, services, accessories, serviceExtension } = useSelector(
    state => ({
      device: deviceSelector(state, { deviceConfiguration }),
      services: servicesSelector(state, { deviceConfiguration }),
      accessories: accessorySelector(state, { deviceConfiguration }),
      serviceExtension: serviceExtensionSelector(state, {
        deviceConfiguration,
      }),
    }),
  )

  const { slug, images, name, variants } = device

  const { id: variantId, specs } = variants.find(
    v => v.id === deviceConfiguration.variant,
  )

  const image = get(sortBy(images, 'position'), '0.@id')

  const accessoryRows = Math.floor(accessories.length / 4) + 1
  return (
    <LineItem
      imageEntity={image}
      name={name}
      link={{ pathname: `/${slug}`, state: { variantId } }}
    >
      <Specs specs={specs} />
      <div
        className={classes.accessoryContainer}
        style={{ height: `${accessoryRows * 66}px` }}
      >
        {accessories.map(a => (
          <DeviceAccessory {...a} index={index} />
        ))}
      </div>

      {services.map(service => (
        <div key={service.id} className={classes.selectContainer}>
          <span className={classes.selectType}>{service.type}: </span>
          {service.name}
        </div>
      ))}
      <div className={classes.selectContainer}>
        <span className={classes.selectType}>{t('protectionPlan')}: </span>
        {t(protectionPlanType)}
      </div>
      {serviceExtension && (
        <div className={classes.selectContainer}>
          <span className={classes.selectType}>{t('serviceExtension')}: </span>
          {serviceExtension.name}
        </div>
      )}
    </LineItem>
  )
}

const AccessoryShape = {
  productVariant: PropTypes.number.isRequired,
  product: PropTypes.number.isRequired,
}

Device.propTypes = {
  deviceConfiguration: PropTypes.shape({
    accessories: PropTypes.arrayOf(PropTypes.shape(AccessoryShape)),
    device: PropTypes.string.isRequired,
    protectionPlan: PropTypes.string,
    protectionPlanType: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    service: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

export default Device
