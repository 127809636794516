import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import Input, { inputStyles } from '../Input'
import TextareaInput from '../TextareaInput'

const useStyles = createUseStyles(inputStyles)

export const renderField = ({
  expand,
  input,
  label,
  icon,
  dark,
  meta: { touched, error, submitError, warning },
  disabled,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('validation')
  return (
    <div>
      <Input
        className={touched && (error || submitError) ? 'error' : undefined}
        input={input}
        placeholder={label}
        expand={expand}
        icon={icon}
        disabled={disabled}
      />
      {touched &&
        (((error || submitError) && (
          <span className={dark ? classes.darkError : classes.error}>
            {t(`validation:error.${error || submitError}`)}
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  )
}

export const renderTextArea = ({
  expand,
  input,
  label,
  icon,
  dark,
  rows,
  meta: { touched, error, submitError, warning },
}) => {
  const classes = useStyles()

  const { t } = useTranslation('validation')

  return (
    <div>
      <TextareaInput
        className={touched && (error || submitError) ? 'error' : undefined}
        input={input}
        placeholder={label}
        expand={expand}
        icon={icon}
        rows={rows}
      />
      {touched &&
        (((error || submitError) && (
          <span className={dark ? classes.darkError : classes.error}>
            {t(`validation:error.${error || submitError}`)}
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  )
}

export const renderCheckbox = ({
  input,
  dark,
  label,
  meta: { touched, error, submitError, warning },
  primaryColor,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('validation')

  return (
    <div>
      <div>
        <input
          className={touched && (error || submitError) ? 'error' : undefined}
          {...input}
          placeholder={label}
        />
        <span
          className={classnames(classes.checkboxLabel, {
            [classes.primaryColorLabel]: primaryColor,
          })}
        >
          {label}
        </span>
        <br />
        {touched &&
          (((error || submitError) && (
            <span className={dark ? classes.darkError : classes.error}>
              {t(`validation:error.${error || submitError}`)}
            </span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}
