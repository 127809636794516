import placeholder from 'Assets/images/placeholders/600x600.png'
import classnames from 'classnames'
import FormPriceButton from 'Components/Common/Button/FormPriceButton'
import AnimatedImage from 'Components/Common/Device/AnimatedImage'
import ImageSlider from 'Components/Common/Device/ImageSlider'
import ListItem from 'Components/Common/Device/ListItem'
import SmallImage from 'Components/Common/Device/SmallImage'
import 'Components/Common/Device/style.css'
import {
  ImageSliderContainer,
  SelectContainer,
} from 'Components/Common/UI/Device'
import { ProductType } from 'Lib/alsoPropTypes'
import { get } from 'lodash'
import React, { useState } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { variantsForProductSelector } from 'Selectors/also/productSelectors'
import ServiceSelects from 'Components/Also/Device/ServiceSelects'
import { StyledAlsoTermSelect } from '../TermSelect'
import AlsoPriceField from './AlsoPriceField'
import DeviceVariantSelectDropdown from './DeviceVariantSelectDropdown'

const DeviceDetails = ({ device }) => {
  const variants = useSelector(state =>
    variantsForProductSelector(state, { product: device.id }),
  )

  const { t } = useTranslation('catalog')

  const [value, setValue] = useState(0)

  return (
    <SelectContainer className={classnames('grid-x', 'grid-padding-x')}>
      <Field
        name="WorkplaceUserDevice.variant"
        subscription={{ value: true }}
        render={({ input }) => {
          const variant = variants.find(v => v.id === input.value)

          const variantImages =
            variant && variant.images.length > 0 ? variant.images : []

          const images = [...variantImages, ...device.images]

          const image = get(images, `${value}.image`)

          return (
            <div key={variant.id} className="cell large-8">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    width: '600px',
                    height: '600px',
                  }}
                >
                  <AnimatedImage
                    image={image || placeholder}
                    alt={variant.name}
                  />
                </div>
              </div>

              {images.length > 1 && (
                <ImageSliderContainer>
                  <ImageSlider>
                    {images.map((image, i) => (
                      <div key={image.id}>
                        <SmallImage
                          image={image}
                          i={i}
                          set={setValue}
                          selected={i === value}
                        />
                      </div>
                    ))}
                  </ImageSlider>
                </ImageSliderContainer>
              )}
            </div>
          )
        }}
      />

      <div className="cell large-4">
        <ListItem header={t('variants')}>
          <Field name="term" subscription={{ value: true }}>
            {({ input: { value: term } }) => (
              <Field
                name="WorkplaceUserDevice.variant"
                render={({ input }) => (
                  <DeviceVariantSelectDropdown
                    selected={input.value}
                    variants={variants}
                    term={term}
                    setVariant={input.onChange}
                  />
                )}
              />
            )}
          </Field>
        </ListItem>
        <ServiceSelects />

        <ListItem header={t('rentalPeriod')}>
          <Field
            name="WorkplaceUserDevice.variant"
            subscription={{ value: true }}
          >
            {({ input: { value: workplaceUserDevice } }) => (
              <Field
                name="term"
                render={({ input }) => (
                  <StyledAlsoTermSelect
                    term={input.value}
                    onChange={input.onChange}
                    workplaceUserDevice={workplaceUserDevice}
                  />
                )}
              />
            )}
          </Field>
        </ListItem>
        <ListItem>
          <FormPriceButton
            type="submit"
            height={64}
            exact
            priceField={
              <FormSpy subscription={{ values: true }}>
                {({ values }) => <AlsoPriceField values={values} />}
              </FormSpy>
            }
          >
            {t('addToCart')}
          </FormPriceButton>
        </ListItem>
      </div>
    </SelectContainer>
  )
}

DeviceDetails.propTypes = {
  device: ProductType.isRequired,
}

export default DeviceDetails
