import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCookieConsent } from 'Ducks/common/config'
import useStoreView from 'Hooks/useStoreView'

function loadScript(url, id, callback) {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  if (script.readyState) {
    // IE
    script.onreadystatechange = function() {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    // Others
    script.onload = function() {
      callback()
    }
  }
  script.id = 'Cookiebot'
  script.src = url
  script.dataset.cbid = id
  document.getElementsByTagName('head')[0].appendChild(script)
}

const CookieBotIntegration = () => {
  const cookieBotId = useStoreView('cookieBotId')
  const dispatch = useDispatch()
  useEffect(() => {
    if (!cookieBotId) {
      return
    }
    loadScript('https://consent.cookiebot.com/uc.js', cookieBotId, () => {
      window.Cookiebot.onaccept = () => {
        dispatch(setCookieConsent(window.Cookiebot.consent))
      }
    })
  }, [cookieBotId])

  return null
}

export default CookieBotIntegration
