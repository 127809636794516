import React from 'react'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import colors from 'Assets/styles/colors'

const styles = {
  listItem: {
    '&:not(:first-child)': {
      marginTop: 16,
    },
  },
  detailsHeader: {
    font: {
      size: '18px',
      weight: 'bold',
    },
    lineHeight: 1.39,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    color: colors.greyishBrownFour,
  },
  infoLink: {
    fontSize: 17,
    color: colors.brownishGrey,
    textDecoration: 'none',
  },
}

const useStyles = createUseStyles(styles)

const ListItem = ({ children, header, info }) => {
  const classes = useStyles()

  return (
    <div className={classes.listItem}>
      <div className={classes.detailsHeader}>{header}</div>
      {children}
      {info && (
        <a href={info} className={classes.infoLink}>
          <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
          &nbsp;click here for more information
        </a>
      )}
    </div>
  )
}

export default ListItem
