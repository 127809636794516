import {
  TableRow,
  TotalsContainer,
  TotalsCurrencyCell,
  TotalsTableHeader,
  TotalsTextCell,
  TotalsTotalsRow,
} from 'Components/Common/UI/CartTotals'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  discountedTotalMonthlySelector,
  totalMonthlySelector,
} from 'Selectors/common/priceSelectors'

/* eslint-disable react/style-prop-object */

const Totals = () => {
  const { t } = useTranslation('cart')
  const { monthlyTotal, discountedMonthlyTotal } = useSelector(state => ({
    monthlyTotal: totalMonthlySelector(state),
    discountedMonthlyTotal: discountedTotalMonthlySelector(state),
  }))

  const vat = useSelector(({ config: { vat } }) => vat)

  const monthlyDiscount = monthlyTotal - discountedMonthlyTotal

  return (
    <TotalsContainer>
      <div>
        <TotalsTableHeader>
          <TotalsTextCell />
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>{t('monthly')}</TotalsCurrencyCell>
        </TotalsTableHeader>
        <TableRow>
          <TotalsTextCell>{t('price')}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={monthlyTotal}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TableRow>
        <TableRow discount>
          <TotalsTextCell>{t('discount')}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={monthlyDiscount}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TableRow>
        <TableRow>
          <TotalsTextCell>{t('subtotal')}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={discountedMonthlyTotal}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TableRow>
        <TableRow>
          <TotalsTextCell>{t('tax', { vat: vat * 100 })}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={discountedMonthlyTotal * vat}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TableRow>
        <TotalsTotalsRow>
          <TotalsTextCell>{t('total')}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={discountedMonthlyTotal * (1 + vat)}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TotalsTotalsRow>
      </div>
    </TotalsContainer>
  )
}

export default Totals

/* eslint-enable react/style-prop-object */
