import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import {
  ImageSliderContainer,
  SelectContainer,
} from 'Components/Common/UI/Device'
import { get, sortBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'

import React, { useState } from 'react'
import { Field, FormSpy, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import placeholder from 'Assets/images/placeholders/600x600.png'

import {
  NO_PROTECTION_PLAN,
  PROTECTION_PLAN,
  PROTECTION_PLAN_WITH_THEFT_PROTECTION,
} from 'Lib/constants'
import FormPriceButton from 'Components/Common/Button/FormPriceButton'
import { StyledPeriodSelect } from 'Components/Common/PeriodSelect'
import AnimatedImage from 'Components/Common/Device/AnimatedImage'
import DeviceDetailsFormAccessories from 'Components/Aluxo/Device/DeviceDetailsFormAccessories'
import DeviceVariantSelectDropdown from 'Components/Aluxo/Device/DeviceVariantSelectDropdown'
import ImageSlider from 'Components/Common/Device/ImageSlider'
import ListItem from 'Components/Common/Device/ListItem'
import PriceField from 'Components/Common/Device/PriceField'
import ServiceSelect from 'Components/Aluxo/Device/ServiceSelect'
import ServiceExtensionSelect from 'Components/Aluxo/Device/ServiceExtensionSelect'
import SmallImage from 'Components/Common/Device/SmallImage'
import 'Components/Common/Device/style.css'
import Bundles from 'Components/Aluxo/Device/Bundles'
import { BundleBanner } from 'Components/Common/Accessory'
import Select from 'Components/Common/Layout/Select'

const DeviceDetails = ({ device, name }) => {
  const { images, variants } = device
  const sortedImages = sortBy(images, 'position')
  const [value, setValue] = useState(0)
  const image = get(sortedImages, `${value}.image`)
  const { t } = useTranslation('catalog')

  const services = useSelector(({ services: { list } }) => list)

  const serviceTypes = uniqBy(
    services.map(({ type, typeSlug }) => ({
      type,
      typeSlug,
    })),
    'type',
  )

  const {
    values: { variant },
  } = useFormState({ subscription: { values: true } })

  const bundles = useSelector(({ promoBundles: { list } }) =>
    list.filter(b => b.device.id === variant),
  )

  return (
    <SelectContainer className={classnames('grid-x', 'grid-padding-x')}>
      <div className="cell large-8">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              width: '600px',
              height: '600px',
              position: 'relative',
            }}
          >
            {bundles.length > 0 && <BundleBanner />}
            <AnimatedImage image={image || placeholder} alt={name} />
          </div>
        </div>

        {sortedImages.length > 1 && (
          <ImageSliderContainer>
            <ImageSlider>
              {sortedImages.map((image, i) => (
                <div key={image.id}>
                  <SmallImage
                    image={image}
                    i={i}
                    set={setValue}
                    selected={i === value}
                  />
                </div>
              ))}
            </ImageSlider>
          </ImageSliderContainer>
        )}
      </div>

      <div className="cell large-4">
        <Field name="qualifiedBundles" subscription={{ value: true }}>
          {({ input: { value } }) =>
            value && value.length > 0 ? (
              <ListItem header={t('bundles')}>
                <Bundles bundles={value} />
              </ListItem>
            ) : null
          }
        </Field>
        <ListItem header={t('variants')}>
          <Field
            name="variant"
            render={({ input }) => (
              <DeviceVariantSelectDropdown
                selected={input.value}
                variants={variants.filter(v => v.active)}
                setVariant={input.onChange}
              />
            )}
          />
        </ListItem>
        <Field name="variant" subscription={{ value: true }}>
          {({ input: { value: variant } }) => (
            <Field
              name="accessories"
              render={({ input }) => (
                <DeviceDetailsFormAccessories
                  input={input}
                  variant={variant}
                  device={device.id}
                />
              )}
            />
          )}
        </Field>

        <ListItem header={t('protectionPlan')} info="/schutzbriefe">
          <Field
            name="protectionPlanType"
            render={({ input }) => {
              const options = [
                NO_PROTECTION_PLAN,
                PROTECTION_PLAN,
                PROTECTION_PLAN_WITH_THEFT_PROTECTION,
              ].map(p => ({
                value: p,
                label: t(p),
              }))
              return (
                <Select
                  className="r-select"
                  classNamePrefix="r-select"
                  defaultValue={options.find(e => e.value === PROTECTION_PLAN)}
                  options={options}
                  onChange={({ value }) => input.onChange(value)}
                  value={options.find(v => v.value === input.value)}
                />
              )
            }}
          />
        </ListItem>

        <ServiceExtensionSelect />

        {serviceTypes.map(({ type, typeSlug }) => (
          <ListItem key={typeSlug} header={type} info={`/${typeSlug}`}>
            <ServiceSelect type={type} />
          </ListItem>
        ))}
        <ListItem header={t('rentalPeriod')}>
          <Field
            name="period"
            render={({ input }) => (
              <StyledPeriodSelect
                period={input.value}
                onChange={input.onChange}
              />
            )}
          />
        </ListItem>
        <ListItem>
          <FormPriceButton
            className="price-button"
            type="submit"
            height={64}
            exact
            priceField={
              <FormSpy subscription={{ values: true }}>
                {({ values }) =>
                  values.rate ? (
                    <PriceField price={values.rate} />
                  ) : (
                    <FontAwesomeIcon
                      style={{ marginLeft: '4px' }}
                      icon="circle-notch"
                      spin
                    />
                  )
                }
              </FormSpy>
            }
          >
            {t('addToCart')}
          </FormPriceButton>
        </ListItem>
      </div>
    </SelectContainer>
  )
}

DeviceDetails.propTypes = {
  device: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string,
}

DeviceDetails.defaultProps = {
  name: '',
}

export default DeviceDetails
