import {
  TableRow,
  TotalsContainer,
  TotalsCurrencyCell,
  TotalsTableHeader,
  TotalsTextCell,
  TotalsTotalsRow,
  PrintLink,
} from 'Components/Common/UI/CartTotals'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { totalMonthlySelector } from 'Selectors/also/priceSelectors'
import { Link } from 'react-router-dom'

/* eslint-disable react/style-prop-object */

const Totals = () => {
  const monthlyTotal = useSelector(totalMonthlySelector)
  const { t } = useTranslation('cart')

  const vat = useSelector(({ config: { vat } }) => vat)

  return (
    <TotalsContainer>
      <div>
        <TotalsTableHeader>
          <TotalsTextCell />
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>{t('monthly')}</TotalsCurrencyCell>
        </TotalsTableHeader>
        <TableRow>
          <TotalsTextCell>{t('price')}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={monthlyTotal}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TableRow>
        <TableRow>
          <TotalsTextCell>{t('tax', { vat: vat * 100 })}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={monthlyTotal * vat}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TableRow>
        <TotalsTotalsRow>
          <TotalsTextCell>{t('total')}</TotalsTextCell>
          <TotalsCurrencyCell />
          <TotalsCurrencyCell>
            <FormattedNumber
              value={monthlyTotal * (1 + vat)}
              style="currency"
              currency="EUR"
            />
          </TotalsCurrencyCell>
        </TotalsTotalsRow>
        <PrintLink>
          <Link to="/warenkorb/print" target="_blank">
            Zur Druckversion
          </Link>
        </PrintLink>
      </div>
    </TotalsContainer>
  )
}

export default Totals

/* eslint-enable react/style-prop-object */
