import classnames from 'classnames'
import AlsoDetailsForm from 'Components/Also/CheckoutForm/AlsoDetailsForm'
import BankDetailsForm from 'Components/Also/CheckoutForm/BankDetailsForm'
import SubmitButton from 'Components/Common/Button/SubmitButton'
import AcceptTermsCheckBox from 'Components/Common/Checkout/AcceptTermsCheckBox'
import AddressForm from 'Components/Common/Checkout/AddressForm'
import Condition from 'Components/Common/Checkout/CheckoutForm/Condition'
import validate from 'Components/Common/Checkout/CheckoutValidation'
import { TextPrivacyStatement } from 'Components/Common/Checkout/PrivacyStatement'
import {
  FieldGroup,
  FieldgroupComment,
  FieldGroupHeader,
  FieldSection,
} from 'Components/Common/Checkout/StyledComponents'
import {
  renderCheckbox,
  renderField,
  renderTextArea,
} from 'Components/Common/Layout/Form/FormField'
import deepFilter from 'deep-filter'
import { clearCart } from 'Ducks/also/alsoCart'
import { getIn } from 'final-form'
import createDecorator from 'final-form-focus'
import { unflatten } from 'flat'
import useStoreViewNotes from 'Hooks/useStoreViewNotes'
import { fetchCustomerData, postAlsoOrder } from 'Lib/fetch'
import history from 'Lib/history'
import parseDomain from 'parse-domain'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const getFormInputs = name => () => {
  if (typeof document === 'undefined') {
    return []
  }
  const form = document.forms[name]
  const inputs = form && form.length ? Array.prototype.slice.call(form) : [] // cast cheat to get from HTMLFormElement children to FocusableInput
  const selectFields = Array.prototype.slice.call(
    document.querySelectorAll('[data-select]'),
  )

  const allInputs = inputs.concat(selectFields)

  return allInputs
}

const findInput = (inputs, errors) =>
  inputs.find(input => {
    const name = input.name || input.dataset.name
    return name && getIn(errors, name)
  })

const focusOnErrors = createDecorator(getFormInputs('checkout'), findInput)

const createCartForForm = state => {
  const { period, workplaces } = state.alsoCart

  const workplaceLines = workplaces.map(
    ({ quantity, term, Services, ...configuration }) => ({
      quantity,
      term,
      workplaceConfigurationFields: Object.entries(configuration).map(
        ([type, { variant, quantity }]) => ({
          productVariant: variant,
          quantity,
          type,
        }),
      ),
      workplaceServices: Object.values(Services || {}).map(service => ({
        service,
      })),
    }),
  )

  return {
    period,
    workplaceLines,
  }
}

const CheckoutForm = () => {
  const { t } = useTranslation('checkout')

  const { initialValues, authenticated, token, lang } = useSelector(state => {
    const {
      auth: { token, newCustomer },
      config: { lang },
    } = state

    return {
      initialValues: {
        shipToBilling: true,
        domain: '',
        createAccount: newCustomer,
        ...createCartForForm(state),
      },
      authenticated: token !== null,
      token,
      lang,
    }
  })

  const [state, setState] = useState({
    billingAddress: null,
    shippingAddress: null,
    bankDetails: null,
  })

  const dispatch = useDispatch()

  const fetchUserData = async () => {
    try {
      const {
        data: { addresses, bankDetails },
      } = await fetchCustomerData()
      const billingAddress = addresses.find(({ type }) => type === 'billing')
      const shippingAddress = addresses.find(({ type }) => type === 'shipping')

      setState({ billingAddress, shippingAddress, bankDetails })
    } catch (error) {
      // eslint-disable-line
      // if (error.response.status === 401 || error.response.status === 403) {
      //   this.props.history.push('/account/login')
      // }
    }
  }

  useEffect(() => {
    if (authenticated) {
      fetchUserData()
    }
  }, [])

  const { billingAddress, shippingAddress, bankDetails } = state

  const notes = useStoreViewNotes()

  const onSubmit = async values => {
    const filteredValues = deepFilter(values, (value, prop, subject) => {
      if (['@id', 'id', '@type'].includes(prop)) {
        return false
      }
      if (subject.shipToBilling && prop === 'shippingAddress') {
        return false
      }
      return true
    })

    const parsedDomain = parseDomain(values.domain)
    const domain = parsedDomain
      ? `${parsedDomain.domain}.${parsedDomain.tld}`
      : 'example.com'

    try {
      await postAlsoOrder(
        { values: { ...filteredValues, domain }, token },
        lang,
      )
      dispatch(clearCart())
      history.push('/bestellbestaetigung')
    } catch (e) {
      if (e.response.status === 400) {
        // TODO: this might fail
        const flattened = e.response.data.violations.reduce(
          (prev, v) => ({
            ...prev,
            [v.propertyPath]: v.message,
          }),
          {},
        )
        const vioerrors = unflatten(flattened)
        return vioerrors
      }
    }
    return false
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      initialValues={{
        ...initialValues,
        billingAddress,
        shippingAddress,
        ...bankDetails,
      }}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form
          name="checkout"
          onSubmit={handleSubmit}
          className={classnames('checkout', 'small-guter-form')}
        >
          <FieldSection>
            <AddressForm
              source="billingAddress"
              note={notes['checkout.billingAddress']}
            />
            <FieldGroup className={classnames('grid-x', 'grid-padding-x')}>
              <div className="cell medium-12">
                <Field
                  className="input-checkbox"
                  component={renderCheckbox}
                  primaryColor
                  name="shipToBilling"
                  id="shipToBilling"
                  type="checkbox"
                  label={t('form.shipToBilling')}
                />
              </div>
            </FieldGroup>
          </FieldSection>

          <Condition when="shipToBilling" is={false}>
            <FieldSection>
              <AddressForm
                source="shippingAddress"
                note={notes['checkout.shippingAddress']}
              />
            </FieldSection>
          </Condition>

          <FieldSection>
            <BankDetailsForm note={notes['checkout.bankDetails']} />
          </FieldSection>

          <FieldSection>
            <AlsoDetailsForm note={notes['checkout.alsoAdditionalInfo']} />
          </FieldSection>
          <FieldSection>
            <FieldGroup className={classnames('grid-x', 'grid-padding-x')}>
              <div className="cell small-12">
                <FieldGroupHeader>{t('form.comment')}</FieldGroupHeader>
              </div>
              {notes['checkout.comment'] && (
                <div className="cell small-12">
                  <FieldgroupComment>
                    {notes['checkout.comment']}
                  </FieldgroupComment>
                </div>
              )}
              <div className="cell small-12">
                <Field
                  type="text"
                  expand
                  component={renderTextArea}
                  label={t('form.comment')}
                  name="comment"
                  id="comment"
                />
              </div>
            </FieldGroup>
          </FieldSection>
          <Field name="createAccount" subscription={{ value: true }}>
            {({ input: { value } }) =>
              value ? (
                <FieldSection>
                  <FieldGroup
                    className={classnames('grid-x', 'grid-padding-x')}
                  >
                    <div className="cell small-12">
                      <FieldGroupHeader>{t('form.account')}</FieldGroupHeader>
                    </div>
                    {notes['checkout.account'] && (
                      <div className="cell small-12">
                        <FieldgroupComment>
                          {notes['checkout.account']}
                        </FieldgroupComment>
                      </div>
                    )}
                    <div className="cell medium-4">
                      <Field
                        type="text"
                        expand
                        component={renderField}
                        label={t('form.email')}
                        name="customer.email"
                      />
                    </div>
                    <div className="cell medium-4">
                      <Field
                        type="password"
                        expand
                        component={renderField}
                        label={t('form.password')}
                        name="customer.password"
                      />
                    </div>
                  </FieldGroup>
                </FieldSection>
              ) : null
            }
          </Field>
          <FieldSection>
            <FieldGroup className={classnames('grid-x', 'grid-padding-x')}>
              <AcceptTermsCheckBox />
              <TextPrivacyStatement />
            </FieldGroup>
          </FieldSection>

          <SubmitButton
            height={64}
            style={{ marginTop: '24px' }}
            fetching={submitting}
            disabled={submitting}
          >
            {t('form.submit')}
          </SubmitButton>
        </form>
      )}
    />
  )
}

export default CheckoutForm
