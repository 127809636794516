import { get } from 'lodash'
import { useSelector } from 'react-redux'

const useSectionVisible = name => {
  const res = useSelector(state => state.storeView)

  const visible = get(res, `data.sectionsVisible.${name}`)

  return visible
}

export default useSectionVisible
