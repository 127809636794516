export default {
  home: 'Home',
  rentNow: 'Jetzt mieten',
  aboutUs: 'Über uns',
  faq: 'FAQ',
  impressum: 'Impressum',
  privacyPolicy: 'Datenschutzerklärung',
  legalNotice: 'Rechtliche Hinweise',
  terms: 'Allgemeine Geschäftsbedingungen',
  contactUs: 'Kontakt',
}
