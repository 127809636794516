export default {
  shippingData: 'Ihre Daten für den Versand',
  shippingDataPrompt: 'Bitte geben Sie hier Daten ein:',
  billingAddress: 'Rechnungsadresse',
  shippingAddress: 'Lieferadresse',
  ceo: 'Geschäftsführer',
  title: 'Kasse',
  newCustomer: 'Neukunde',
  registerAsNewCustomer: 'Als Neukunde registrieren',
  continue: 'Fortfahren',
  form: {
    company: 'Firmenname',
    legalForm: 'Rechtsform',
    salutation: 'Anrede',
    firstName: 'Vorname',
    lastName: 'Nachname',
    submit: 'Mietvertrag unverbindlich anfordern',
    street: 'Straße',
    postCode: 'Postleitzahl',
    city: 'Ort',
    phone: 'Telefonnummer',
    email: 'E-Mail-Adresse',
    bank: 'Kreditinstitut',
    IBAN: 'IBAN',
    BIC: 'BIC',
    paymentData: 'Kontodaten',
    additionalData: 'Weitere Informationen',
    domains: 'Domains',
    website: 'Firmen Webseite',
    VATID: 'Umsatzsteuer-ID',
    password: 'Passwort',
    account: 'Kundenkonto',
    comment: 'Kommentar',
    accept: 'Ich habe die <0></0> gelesen und verstanden.',
    acceptNewsletter: 'Ich möchte den kostenlosen Newsletter abonnieren.',
    shipToBilling: 'Lieferadresse wie Rechnungsadresse',
    copyToCeo: 'Adressdaten für Geschäftsführer übernehmen',
    ceo: {
      salutation: 'Anrede',
      firstName: 'Vorname',
      lastName: 'Nachname',
      submit: 'Mietvertrag unverbindlich anfordern',
      street: 'Straße',
      postCode: 'Postleitzahl',
      city: 'Ort',
      phone: 'Telefonnummer',
      fax: 'Faxnummer',
      birthdate: 'Geburtsdatum',
    },
  },
}
