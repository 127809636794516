import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const PeriodList = styled.div`
  height: 64px;
`

export const Period = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;

  border: solid 2px ${colors.whiteSeven};
  border-left: solid 1px ${colors.whiteSeven};
  border-right: solid 1px ${colors.whiteSeven};

  border-color: ${props =>
    props.active ? props.theme.firstColor : colors.whiteSeven};

  &:first-child {
    border-right-style: ${props => !props.active && 'none'};
    border-left: solid 2px
      ${props => (props.active ? props.theme.firstColor : colors.whiteSeven)};
  }

  &:last-child {
    border-left-style: ${props => !props.active && 'none'};
    border-right: solid 2px
      ${props => (props.active ? props.theme.firstColor : colors.whiteSeven)};
  }
  color: ${colors.whiteSeven};
  &:hover {
    color: ${props => !props.active && colors.warmGrey};
  }
`

export const PeriodMonths = styled.div`
  font-size: 17px;
  line-height: 1.29;
  letter-spacing: 0.3px;
  text-align: center;
  transition: color 0.2s ease;
  color: ${props => props.active && colors.warmGrey};
`

export const PeriodNumber = styled(PeriodMonths)`
  font-size: 27px;
  font-weight: bold;
  line-height: 0.93;
  letter-spacing: normal;
  transition: color 0.2s ease;
  color: ${props => props.active && colors.warmGrey};
`
