import React from 'react'
import { createUseStyles } from 'react-jss'
import { compose } from 'recompose'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { slice } from 'ramda'

import { footerLinkSelector } from 'Selectors/common/linkSelectors'
import styles from './styles'
import Link from '../../Link'

const mapStateToProps = state => ({
  links: footerLinkSelector(state),
})

const useStyles = createUseStyles(styles)

const enhance = compose(connect(mapStateToProps))

export default enhance(({ links }) => {
  const classes = useStyles()

  return (
    <div className={classes.fullHeight}>
      <div
        className={classnames('grid-x', 'align-justify', classes.fullHeight)}
      >
        <div className={classnames('cell', 'small-6', classes.fullHeight)}>
          <div className={classnames('grid-y', classes.fullHeight)}>
            {slice(0, 4, links).map(({ id, ...link }) => (
              <Link
                key={id}
                link={link}
                className={classnames(
                  'cell',
                  'shrink',
                  classes.navigationItems,
                )}
              />
            ))}
          </div>
        </div>
        <div className={classnames('cell', 'small-6', classes.fullHeight)}>
          <div className={classnames('grid-y', classes.fullHeight)}>
            {slice(4, 8, links).map(({ id, ...link }) => (
              <Link
                key={id}
                link={link}
                className={classnames(
                  'cell',
                  'shrink',
                  classes.navigationItems,
                )}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})
