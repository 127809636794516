import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import padStart from 'lodash/padStart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import colors from 'Assets/styles/colors'

const spinnerStyles = theme => ({
  quantity: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: props => props.size || 22,
  },
  quantityButton: {
    fontSize: props => props.size || 22,
    color: theme.firstColor,
    zIndex: 5,
    cursor: 'pointer',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
    transition: 'opacity ease-out 400ms',
  },
  quantityNumber: {
    color: colors.warmGreyFour,
  },
})

const useStyles = createUseStyles(spinnerStyles)

const QuantitySpinner = ({
  quantity,
  increment,
  decrement,
  size,
  style,
  className,
}) => {
  const classes = useStyles({ size })

  return (
    <div className={classnames(classes.quantity, className)} style={style}>
      <div
        role="button"
        tabIndex={0}
        onKeyPress={increment}
        onClick={increment}
        className={classes.quantityButton}
      >
        <FontAwesomeIcon icon="caret-up" aria-hidden="true" />
      </div>
      <span className={classes.quantityNumber}>
        {padStart(quantity, 2, '0')} Stk.
      </span>

      <div
        role="button"
        tabIndex={0}
        onKeyPress={decrement}
        onClick={decrement}
        className={classes.quantityButton}
      >
        <FontAwesomeIcon icon="caret-down" aria-hidden="true" />
      </div>
    </div>
  )
}

QuantitySpinner.propTypes = {
  size: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
}

QuantitySpinner.defaultProps = {
  size: 22,
}

export default QuantitySpinner
