import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
// import officeInclusiveImage from 'Assets/images/office-inklusive.png'
import { BundleBanner } from 'Components/Common/Accessory'
import colors from 'Assets/styles/colors'
import { DeviceType, VariantType } from 'Lib/propTypes'
import {
  findLeasingFactor,
  leasingFactorsSelector,
} from 'Selectors/common/priceSelectors'
import PriceButtonLink from 'Components/Common/Button/PriceButtonLink'
import { ProductImage } from 'Components/Common/Layout/Image'

const useStyles = createUseStyles(theme => ({
  box: {
    width: '273px',
    height: '434px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: colors.greyish29,
    borderRadius: '3px',
    padding: '30px 20px',
  },
  productImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '160px',
    width: '100%',
    position: 'relative',
  },
  name: {
    display: 'block',
    fontSize: '21px',
    fontWeight: '600',
    color: theme.firstColor,
    textAlign: 'center',
    letterSpacing: '0.4px',
    lineHeight: '1.19',
    marginTop: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  descriptionList: {
    display: 'flex',
    fontSize: '17px',
    color: colors.warmGrey,
    textAlign: 'center',
    marginTop: '16px',
    justifyContent: 'space-between',
    '& > div:not(:last-child)': {
      borderRight: {
        width: '1px',
        style: 'solid',
        color: colors.greyish29,
      },
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  descriptionSpec: {
    fontSize: '20px',
    lineHeight: 0.83,
    letterSpacing: 0.4,
    fontWeight: 900,
  },
  descriptionTitle: {
    fontSize: '12px',
    lineHeight: 1.26,
    letterSpacing: 0.2,
    marginTop: 4,
    fontWeight: 900,
  },
  subDescription: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: 0.3,
    color: colors.warmGrey,
    textAlign: 'center',
    marginBottom: '12px',
    minHeight: '27px',
  },
  monthlyPricing: {
    display: 'block',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: 0.3,
    color: colors.warmGrey,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: '16px',
    marginBottom: '12px',
  },
}))

const rateSelector = createSelector(
  leasingFactorsSelector,
  (_, { variant: { netPrice } }) => netPrice,
  (leasingFactors, price) => {
    const { leasingFactor } = findLeasingFactor(36, price)(leasingFactors)

    return (price * (leasingFactor + 0.02)) / 100
  },
)

const DeviceBox = ({
  device: { images, slug, name },
  variant: { id: variantId, specs, netPrice },
}) => {
  const classes = useStyles()
  const { t } = useTranslation('catalog')

  const rate = useSelector(state =>
    rateSelector(state, { variant: { netPrice } }),
  )
  const bundles = useSelector(({ promoBundles: { list } }) =>
    list.filter(b => b.device.id === variantId),
  )
  return (
    <div className={classes.box}>
      <div className={classes.productImageContainer}>
        {bundles.length > 0 && <BundleBanner />}
        <ProductImage
          imageEntity={get(images, '0.@id')}
          alt={name}
          height={160}
          width={231}
        />
      </div>
      <span className={classes.name}>{name}</span>
      <div className={classes.descriptionList}>
        {orderBy(specs, specs, s => s.title).map(({ spec, title }) => (
          <div key={title} className={classes.description}>
            <span className={classes.descriptionSpec}>{spec}</span>
            <span className={classes.descriptionTitle}>{title}</span>
          </div>
        ))}
      </div>
      <span className={classes.monthlyPricing}>{t('monthlyPricing')}</span>
      <span className={classes.subDescription}>
        {/* <Image
        src={officeInclusiveImage}
        alt="office-included"
        width={83}
        height={27}
      /> */}
      </span>
      <PriceButtonLink
        price={rate}
        to={{
          pathname: `/${slug}`,
          state: { variantId },
        }}
        label={t('goToProduct')}
      />
    </div>
  )
}

DeviceBox.propTypes = {
  device: DeviceType.isRequired,
  variant: VariantType.isRequired,
}

export default DeviceBox
