import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Measure from 'react-measure'
import colors from 'Assets/styles/colors'
import { TestimonialShape } from 'Lib/propTypes'
import Image from '../Layout/Image'

const styles = theme => ({
  box: {
    width: 350,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: colors.greyish29,
    borderRadius: '3px',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '60px',
  },
  starContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    '& > *': {
      margin: [0, 4],
    },
  },
  star: {
    color: theme.firstColor,
  },
  comment: {
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: '300',
    color: colors.brownishGrey,
    textAlign: 'center',
    letterSpacing: '0.4px',
    lineHeight: '1.61',
    flexGrow: 1,
    position: 'relative',
    minHeight: props => props.minHeight,
  },
  name: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.47,
    letterSpacing: 0.3,
    color: theme.darkerFirstColor,
  },
  upper: {
    padding: {
      top: '45px',
      left: '45px',
      right: '45px',
      bottom: '20px',
    },
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  lower: {
    borderTop: {
      style: 'solid',
      width: '1px',
      color: colors.whiteSix,
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: [21, 0],
  },
})

const useStyles = createUseStyles(styles)

const TestimonialBox = props => {
  const { testimonial, updateHeight } = props
  const classes = useStyles(props)
  const { image, name, comment } = testimonial
  return (
    <Measure
      bounds
      onResize={contentRect => updateHeight(contentRect.bounds.height)}
    >
      {({ measureRef }) => (
        <div className={classes.box}>
          <div className={classes.upper}>
            <div className={classes.iconContainer}>
              <Image src={image} alt={name} width={120} height={60} />
            </div>
            <div className={classes.starContainer}>
              <FontAwesomeIcon icon="star" className={classes.star} />
              <FontAwesomeIcon icon="star" className={classes.star} />
              <FontAwesomeIcon icon="star" className={classes.star} />
              <FontAwesomeIcon icon="star" className={classes.star} />
              <FontAwesomeIcon icon="star" className={classes.star} />
            </div>
            <div className={classes.comment} ref={measureRef}>
              <span>„</span>
              <span>{comment}</span>
              <span>”</span>
            </div>
          </div>
          <div className={classes.lower}>
            <span className={classes.name}>{name}</span>
          </div>
        </div>
      )}
    </Measure>
  )
}

TestimonialBox.propTypes = {
  ...TestimonialShape,
}

TestimonialBox.defaultProps = {
  minHeight: 0,
}

export default TestimonialBox
