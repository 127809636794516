/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'

const PriceField = ({ price }) => (
  <FormattedNumber style="currency" currency="EUR" value={price} />
)

PriceField.propTypes = {
  price: PropTypes.number.isRequired,
}

export default PriceField
