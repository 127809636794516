import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { max } from 'lodash'
import { createUseStyles } from 'react-jss'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TestimonialType } from 'Lib/propTypes'
import useSectionVisible from 'Hooks/useSectionVisible'
import Section from '../Layout/Section'
import Slider from '../Layout/Slider'
import TestimonialBox from './TestimonialBox'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import Title from '../Title'

export const styles = {
  text: {
    backgroundColor: 'white',
    color: '#636363',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: 1.78,
    letterSpacing: 0.7,
    textAlign: 'center',
    marginTop: '78px',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 20px',
  },
}

const useStyles = createUseStyles(styles)

const enhance = compose(
  withRouter,
  connect(({ testimonials: { list: testimonials, fetching } }) => ({
    testimonials,
    fetching,
  })),
  branch(({ fetching }) => fetching, renderComponent(LoadingIndicator)),
  branch(({ testimonials }) => testimonials.length === 0, renderNothing),
)

const TestimonialSection = ({ testimonials }) => {
  const classes = useStyles()
  const visible = useSectionVisible('testimonials')
  const [maxHeight, setHeight] = useState(0)
  const updateHeight = newHeight => setHeight(max([maxHeight, newHeight]))
  if (!visible) {
    return null
  }
  return (
    <Section title={<Title i18nKey="testimonials" />}>
      <Slider>
        {testimonials.map(testimonial => (
          <div key={testimonial.id}>
            <div className={classnames(classes.item)}>
              <TestimonialBox
                updateHeight={updateHeight}
                minHeight={maxHeight}
                testimonial={testimonial}
              />
            </div>
          </div>
        ))}
      </Slider>
    </Section>
  )
}

TestimonialSection.propTypes = {
  testimonials: PropTypes.arrayOf(TestimonialType).isRequired,
}

export default enhance(TestimonialSection)
