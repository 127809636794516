export default {
  forgotPassword: 'Passwort vergessen?',
  email: 'E-Mail-Adresse',
  password: 'Passwort',
  passwordRepeat: 'Passwort erneut',
  submitLogin: 'Einloggen',
  submitPasswordReset: 'Passwort setzen',
  submitPasswordResetRequest: 'Passwort zurücksetzen',
  'Bad credentials': 'E-Mail Adresse oder Passwort ungültig',
  'invalid-credentials': 'E-Mail Adresse oder Passwort ungültig',
  genericError: 'Es ist ein Fehler aufgetreten',
  enterEmail: 'Bitte geben Sie ihre E-Mail-Adresse ein',
  enterPassword: 'Bitte geben Sie ihr Passwort ein',
  enterNewPassword: 'Bitte geben Sie ein Passwort ein',
  enterNewPasswordConfirmation: 'Bitte geben Sie ihr Passwort erneut ein',
  existingCustomer: 'Bestandskunde',
  passwordResetSuccess:
    'Ihr Passwort wurde erfolgreich gesetzt. Sie können sich nun <0>einloggen</0>',
  passwordResetEmailSent:
    'Wir haben eine E-Mail mit weiteren Anweisungen an {{email}} geschickt.',
  login: 'Einloggen',
  signup: 'Registrieren',
  submitSignup: 'Registrieren',
  alreadySignedUp: 'Bereits registriert?',
  notSignedUpYet: 'Noch kein Kundenkonto vorhanden?',
}
