import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { branch, compose, renderComponent } from 'recompose'
import withAlsoSlugSelector from 'Selectors/also/withSlugSelector'
import CategoryPage from 'Components/Common/CategoryPage/CategoryPage'
import Device from 'Components/Common/Device'
import Page from 'Components/Common/Page/Page'
import NotFound from 'Components/Common/Page/NotFound'

const alsoMapStateToProps = (state, props) => ({
  item: withAlsoSlugSelector(props.match.params.slug)(state, props),
})

const branchComponentTypes = types =>
  compose(
    ...types.map(type =>
      branch(
        ({ item }) => item['@type'] === type.type,
        renderComponent(type.render),
      ),
    ),
  )

const alsoEnhance = compose(
  withRouter,
  connect(alsoMapStateToProps),
  branch(({ item }) => !item, renderComponent(NotFound)),
  branchComponentTypes([
    { type: 'also_product', render: Device },
    { type: 'also_category', render: CategoryPage },
    { type: 'Page', render: Page },
  ]),
)

const Slug = () => <NotFound />

export default alsoEnhance(Slug)
