/* eslint-disable react/style-prop-object */
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { workplacePriceSelector } from 'Selectors/also/priceSelectors'

const AlsoPriceField = props => {
  const price = useSelector(state => workplacePriceSelector(state, props))
  return <FormattedNumber style="currency" currency="EUR" value={price} />
}

export default AlsoPriceField
