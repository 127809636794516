import axios from 'axios'
import get from 'lodash/get'
import queryString from 'query-string'
import store from '../store'

const parsed = queryString.parse(window.location.search)

export const url =
  parsed.endpoint || process.env.REACT_APP_API_URL || window.API_URL
export const apikey =
  parsed.apikey || process.env.REACT_APP_API_KEY || window.API_KEY

window.cypress_url = url
window.cypress_apikey = apikey

const defaultHeaders = {
  Accept: 'application/ld+json',
  'Content-Type': 'application/json',
  'X-Api-Key': apikey,
}
// comment
const instance = axios.create({
  baseURL: `${url}/api/`,
  headers: defaultHeaders,
})

const authedInstance = axios.create({
  baseURL: `${url}/api/`,
  headers: defaultHeaders,
})

authedInstance.interceptors.request.use(config => {
  const token = get(store.getState(), 'auth.token')
  config.headers.Authorization = token ? `Bearer ${token}` : '' // eslint-disable-line
  return config
})

const mostSuitableInstance = () =>
  get(store.getState(), 'auth.token', false) ? authedInstance : instance

export const login = values => instance.post(`/login_check`, values)
export const signup = values => instance.post(`/customers/register`, values)

export const postOrder = ({ values }, lang) =>
  authedInstance.post(`/create_order`, values, {
    headers: { 'X-Locale': lang },
  })

export const postAlsoOrder = ({ values }, lang) =>
  authedInstance.post(`/also_orders`, values, {
    headers: { 'X-Locale': lang },
  })

export const postMissingTranslationKey = values => {
  instance.post('/log/missing_string_translation', values)
}

export const getRates = price => instance.get(`/get_rates/${price}`)

export const sendServiceRequest = (order, message) =>
  instance.put(`/orders/${order.id}/service_request`, { message })

export const sendPasswordReset = values =>
  instance.post('/users/reset-password', values)
export const sendPasswordResetRequest = values =>
  instance.post('/users/request-password', values)

export const fetchCategories = () => instance.get('/categories')
export const fetchServices = () =>
  mostSuitableInstance().get('/online_services?pagination=false')
export const fetchResellerInfo = () => instance.get('/me')
export const fetchStoreView = () => instance.get('/store_view')
export const fetchProtectionPlans = () =>
  mostSuitableInstance().get('/protection_plans?pagination=false')
export const fetchServiceExtensions = () =>
  mostSuitableInstance().get('/service_extensions?pagination=false')
export const fetchCategory = category =>
  instance.get(`/categories?slug=${category}`)
export const fetchDevices = () =>
  mostSuitableInstance().get(`/devices?pagination=false`)
export const fetchProducts = () =>
  mostSuitableInstance().get(`/products?pagination=false`)
export const fetchPages = () => instance.get(`/pages?pagination=false`)
export const fetchLinks = () => instance.get(`/links?pagination=false`)
export const fetchSlides = () => instance.get(`/slides?pagination=false`)
export const fetchBenefits = () => instance.get(`/benefits?pagination=false`)
export const fetchTestimonials = () =>
  instance.get('/testimonials?pagination=false')
export const fetchTeam = () => instance.get('/team_members?pagination=false')
export const fetchMarketingLogos = () =>
  instance.get('/marketing_logos?pagination=false')
export const fetchPromoBundles = () => instance.get('/public_promo_bundles')

export const sendAppointmentRequest = values =>
  instance.post('/appointment_request', values)

export const fetchCustomerData = () => authedInstance.get('/customer')
export const updateCustomerData = values =>
  authedInstance.put(`/customers/${values.id}`, values)

export const fetchAlsoResellerProducts = () =>
  instance.get(`/also_reseller_products?pagination=false`)

export const fetchAlsoResellerServices = () =>
  instance.get(`/also_reseller_services?pagination=false`)

export const fetchAlsoConfig = () => instance.get(`/also_config`)

export const fetchTranslations = () =>
  instance.get('/string_translations?type=frontend&pagination=false')
