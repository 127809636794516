import classnames from 'classnames'
import {
  Container,
  Description,
  DescriptionContainer,
  ImageContainer,
  Name,
  Price,
  PriceContainer,
} from 'Components/Common/CategoryPage/ItemStyledComponents'
import VariantName from 'Components/Common/CategoryPage/VariantName'
import { ProductImage } from 'Components/Common/Layout/Image'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import Media from 'react-media'
import { Link } from 'react-router-dom'

/* eslint-disable react/style-prop-object */

const Item = ({
  imageEntity,
  name,
  slug,
  description,
  variantName,
  manufacturer,
  link,
  rate,
}) => {
  const linkTo = { pathname: `/${slug}`, state: {} }

  return (
    <Container className={classnames('grid-x grid-padding-x')}>
      <ImageContainer className={classnames('small-12 large-3')}>
        <Link to={linkTo}>
          <ProductImage
            imageEntity={imageEntity}
            alt={name}
            height={180}
            width={290}
          />
        </Link>
      </ImageContainer>
      <DescriptionContainer className={classnames('small-12 large-6')}>
        <Name as={Link} to={linkTo}>
          {manufacturer && `${manufacturer} `}
          {name}
        </Name>
        {variantName && <VariantName variantName={variantName} />}
        <Media query="(min-width: 1024px)">
          {matches =>
            matches && (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            )
          }
        </Media>
      </DescriptionContainer>
      <PriceContainer className={classnames('small-12 large-3')}>
        <Price>
          ab <FormattedNumber style="currency" currency="EUR" value={rate} />
        </Price>
        {link}
      </PriceContainer>
    </Container>
  )
}

Item.propTypes = {
  imageEntity: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  manufacturer: PropTypes.string.isRequired,
  variantName: PropTypes.string,
  link: PropTypes.node.isRequired,
  rate: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
}

Item.defaultProps = {
  imageEntity: undefined,
  variantName: undefined,
  description: undefined,
}

export default Item
