import { toArray } from 'lodash'
import { mapLanguage } from 'Lib/i18nMiddleware'
import { SET_LANGUAGE } from './config'

export const LINKS_FETCH_LIST_REQUEST = 'LINKS::FETCH_LIST_REQUEST'
export const LINKS_FETCH_LIST_FAIL = 'LINKS::FETCH_LIST_FAIL'
export const LINKS_FETCH_LIST_SUCCESS = 'LINKS::FETCH_LIST_SUCCESS'

const initialState = { list: [], default: [], fetching: false }

export const fetchLinkList = () => ({
  type: LINKS_FETCH_LIST_REQUEST,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LINKS_FETCH_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case LINKS_FETCH_LIST_SUCCESS: {
      return {
        ...state,
        list: toArray(action.data).map(mapLanguage(action.lang)),
        default: toArray(action.data),
        fetching: false,
      }
    }
    case LINKS_FETCH_LIST_FAIL: {
      return {
        ...state,
        list: [],
        fetching: false,
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        list: state.default.map(mapLanguage(action.payload.lang)),
      }
    }

    default:
      return state
  }
}

export default reducer
