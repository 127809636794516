import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

const ScrollToTopRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    // if (
    //   this.props.path === this.props.location.pathname &&
    //   this.props.location.pathname !== prevProps.location.pathname
    // ) {
    // }
    window.scrollTo(0, 0)
  })

  return <Route {...rest} render={props => <Component {...props} />} />
}

export default ScrollToTopRoute
