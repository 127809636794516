import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCreateNewCustomer } from 'Ducks/common/auth'
import Button from '../Button/Button'
import CheckoutLoginForm from './CheckoutLoginForm'
import {
  CustomerContainer,
  CustomerHeader,
  NewCustomerContainer,
  NewCustomerText,
} from './StyledComponents'

const LoginOrNewCustomerForm = () => {
  const { t } = useTranslation('checkout')

  const { authenticated, newCustomer } = useSelector(
    ({ auth: { token, newCustomer } }) => ({
      authenticated: token !== null,
      newCustomer,
    }),
  )

  const dispatch = useDispatch()

  if (authenticated) {
    return null
  }

  return (
    <div className="grid-x grid-margin-x">
      <CheckoutLoginForm />

      {!newCustomer && (
        <CustomerContainer className={classnames('cell', 'medium-4')}>
          <CustomerHeader>{t('newCustomer')}</CustomerHeader>
          <NewCustomerContainer>
            <NewCustomerText>{t('registerAsNewCustomer')}</NewCustomerText>
            <Button
              onClick={() => dispatch(setCreateNewCustomer())}
              height={59}
            >
              {t('continue')}
            </Button>
          </NewCustomerContainer>
        </CustomerContainer>
      )}
    </div>
  )
}

export default LoginOrNewCustomerForm
