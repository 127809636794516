export default {
  name: 'Ihr Name',
  company: 'Ihre Firma',
  email: 'Ihre E-Mail-Adresse',
  phone: 'Telefon',
  message: 'Ihre Nachricht',
  submitted: 'Ihre Anfrage wurde versendet',
  submit: 'Absenden',
  submitting: 'Bitte warten',
  acceptDataPrivacy:
    'Durch Ihre Bestätigung übermitteln Sie die Daten an {{company}}. Die Informationspflichten zum Datenschutz, insbesondere zur Rechtsgrundlage zur Kundenkommunikation, finden Sie unter unserer <0></0>.',
  error: 'Es ist ein Fehler aufgetreten',
  validation: {
    name: 'Bitte geben Sie Ihren Namen ein',
    email: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    message: 'Bitte geben Sie eine Nachricht ein',
  },
}
