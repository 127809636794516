import PriceButton from 'Components/Common/Button/Button'
import InfoPanel from 'Components/Common/Device/AccessoryInfoPanel'
import { ProductImage } from 'Components/Common/Layout/Image'
import {
  AccessoryContainer,
  ProductName,
  ProductVariantName,
} from 'Components/Common/UI/DeviceAccessories'
import { ProductVariantType } from 'Lib/alsoPropTypes'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'

const DisabledNotice = styled.div`
  text-align: center;
  color: ${props => props.theme.errorColor};
  font-size: 15px;
  flex-grow: 1;
  padding: 8px 24px;
  border-radius: 0 3px 3px 0;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeviceAccessoryProduct = ({ variant, selected, onSelect, disabled }) => {
  const product = useSelector(
    state => state.alsoProducts.entities[variant.product],
  )
  const { t } = useTranslation('catalog')

  if (!product) {
    return null
  }

  return (
    <Flex justifyContent="center">
      <AccessoryContainer>
        <InfoPanel content={variant.shortDescription} />
        <ProductImage
          style={{ width: '100%' }}
          imageEntity={
            get(variant, 'images.0.@id') || get(product, 'images.0.@id')
          }
          alt={product.name}
          width={228}
          height={100}
        />

        <div style={{ flexGrow: 1, flexShrink: 0 }}>
          <ProductName>{product.name}</ProductName>
          <ProductVariantName>({variant.name})</ProductVariantName>
        </div>
        {disabled ? (
          <DisabledNotice>
            Zur gewählten Laufzeit nicht verfügbar
          </DisabledNotice>
        ) : (
          <PriceButton
            style={{ width: '100%', flexGrow: 0, flexShrink: 0 }}
            onClick={onSelect}
            height={48}
            exact
            hideAngle
            hollow={selected}
            disabled={disabled}
          >
            {selected ? t('remove') : t('add')}
          </PriceButton>
        )}
      </AccessoryContainer>
    </Flex>
  )
}

DeviceAccessoryProduct.propTypes = {
  variant: ProductVariantType.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default DeviceAccessoryProduct
