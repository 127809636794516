import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'
import { CustomProductType, VariantType } from 'Lib/propTypes'
import PriceButton from 'Components/Common/Button/Button'
import { ProductImage } from 'Components/Common/Layout/Image'
import { BundleBanner } from 'Components/Common/Accessory'
import InfoPanel from 'Components/Common/Device/AccessoryInfoPanel'

const SliderItemContainer = styled.div`
  display: flex;
  justify-content: center;
`
const AccessoryContainer = styled.div`
  display: flex;
  width: 270px;
  height: 300px;
  background-color: white;
  border: 1px solid;
  border-color: ${colors.greyish29};
  border-radius: 3px;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const ProductName = styled.div`
  font-size: 20px;
`

const Image = styled(ProductImage)`
  width: 100%;
`

const DeviceAccessoryProduct = ({
  product,
  variant,
  selected,
  bundled,
  toggleSelected,
}) => {
  const { t } = useTranslation('catalog')
  const imageEntity = variant.imageEntity || product.imageEntity
  return (
    <SliderItemContainer className={`product-variant-${variant.id}`}>
      <AccessoryContainer>
        <InfoPanel content={variant.shortDescription} />
        {bundled && <BundleBanner large />}
        <Image
          imageEntity={imageEntity}
          alt={product.name}
          width={228}
          height={100}
        />

        <div css="flex-grow: 1; flex-shrink: 0;">
          <ProductName>{product.name}</ProductName>
          <div>
            {variant.attributeValues.map(({ attribute, value }) => (
              <div>{`${attribute}: ${value}`}</div>
            ))}
          </div>
        </div>
        {!bundled && (
          <PriceButton
            style={{ width: '100%', flexGrow: 0, flexShrink: 0 }}
            onClick={toggleSelected}
            height={48}
            exact
            hideAngle
            hollow={selected}
          >
            {selected ? t('remove') : t('add')}
          </PriceButton>
        )}
      </AccessoryContainer>
    </SliderItemContainer>
  )
}

DeviceAccessoryProduct.propTypes = {
  product: CustomProductType.isRequired,
  variant: VariantType.isRequired,
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  bundled: PropTypes.bool.isRequired,
}

export default DeviceAccessoryProduct
