import classnames from 'classnames'
import useSectionVisible from 'Hooks/useSectionVisible'
import useStoreView from 'Hooks/useStoreView'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Section from '../Layout/Section'
import Title from '../Title'

const styles = theme => ({
  statsContainer: {},
  text: {
    color: '#636363',
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: 1.78,
    letterSpacing: 0.7,
    textAlign: 'center',
    marginTop: '26px',
    textTransform: 'uppercase',
  },
  stat: {
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: '2.43',
    letterSpacing: '0.8px',
    textAlign: 'center',
    color: '#575757',
    '&:not(:last-child)': {
      borderRight: '1px solid rgba(0, 0, 0, 0.15)',
    },
  },
  number: {
    fontSize: '74px',
    fontWeight: '600',
    lineHeight: '0.81',
    letterSpacing: '1.5px',
    color: theme.firstColor,
  },
  item: {
    margin: '16px 0',
  },
})

const useStyles = createUseStyles(styles)

const StatsSection = () => {
  const classes = useStyles()
  const stats = useStoreView('stats')
  const visible = useSectionVisible('stats')
  if (!visible) {
    return null
  }
  return (
    <Section title={<Title i18nKey="stats" />}>
      <div
        className={classnames(
          'grid-x',
          'grid-padding-x',
          'small-up-1',
          'medium-up-3',
          'large-up-3',
          'align-justify',
          classes.statsContainer,
        )}
      >
        {stats.map(stat => (
          <div
            key={stat.type}
            className={classnames('cell', classes.stat, classes.item)}
          >
            <div className={classes.number}>{stat.number}</div>
            <div className={classes.text}>{stat.type}</div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default StatsSection
