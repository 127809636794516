import update from 'immutability-helper'

export const PRODUCT_INITIALIZE_CONFIGURATION =
  'PRODUCT::INITIALIZE_CONFIGURATION'
export const PRODUCT_SET_PERIOD = 'PRODUCT::SET_PERIOD'
export const PRODUCT_SET_PRODUCT = 'PRODUCT::SET_PRODUCT'
export const PRODUCT_INCREASE_QUANTITY = 'PRODUCT::INCREASE_QUANTITY'
export const PRODUCT_DECREASE_QUANTITY = 'PRODUCT::DECREASE_QUANTITY'
export const PRODUCT_SET_ATTRIBUTESETS = 'PRODUCT::SET_ATTRIBUTESETS'
export const PRODUCT_UPDATE_ATTRIBUTESETS = 'PRODUCT::UPDATE_ATTRIBUTESETS'
export const PRODUCT_SET_ATTRIBUTE = 'PRODUCT::SET_ATTRIBUTE'
export const PRODUCT_SET_SELECTED_PRODUCT = 'PRODUCT::SET_SELECTED_PRODUCT'

const initialState = {
  product: null,
  productVariant: null,
  attributeSets: [],
  selectedAttributes: [],
  period: 24,
  quantity: 1,
}

export const initializeConfiguration = () => ({
  type: PRODUCT_INITIALIZE_CONFIGURATION,
})

export const setPeriod = period => ({
  type: PRODUCT_SET_PERIOD,
  period,
})

export const setProduct = product => ({
  type: PRODUCT_SET_PRODUCT,
  product,
})

export const increaseQuantity = () => ({
  type: PRODUCT_INCREASE_QUANTITY,
})

export const decreaseQuantity = () => ({
  type: PRODUCT_DECREASE_QUANTITY,
})

export const setAttributeSets = attributeSets => ({
  type: PRODUCT_SET_ATTRIBUTESETS,
  attributeSets,
})

export const updateAttributeSets = attributeSets => ({
  type: PRODUCT_UPDATE_ATTRIBUTESETS,
  attributeSets,
})

export const setAttribute = (attribute, value) => ({
  type: PRODUCT_SET_ATTRIBUTE,
  attribute,
  value,
})

export const setSelectedProduct = productVariant => ({
  type: PRODUCT_SET_SELECTED_PRODUCT,
  productVariant,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_INITIALIZE_CONFIGURATION: {
      return {
        ...initialState,
        product: state.product,
        attributeSets: state.attributeSets,
      }
    }
    case PRODUCT_SET_PERIOD: {
      return {
        ...state,
        period: action.period,
      }
    }
    case PRODUCT_SET_PRODUCT: {
      return {
        ...state,
        product: action.product,
        attributeSets: [],
        selectedAttributes: [],
      }
    }
    case PRODUCT_INCREASE_QUANTITY: {
      return {
        ...state,
        quantity: state.quantity + 1,
      }
    }
    case PRODUCT_DECREASE_QUANTITY: {
      if (state.quantity > 1) {
        return {
          ...state,
          quantity: state.quantity - 1,
        }
      }
      return state
    }
    case PRODUCT_SET_ATTRIBUTESETS: {
      return {
        ...state,
        attributeSets: action.attributeSets,
        selectedAttributes: action.attributeSets.map(set => ({
          attribute: set.attribute,
          value: null,
        })),
      }
    }
    case PRODUCT_UPDATE_ATTRIBUTESETS: {
      return {
        ...state,
        attributeSets: action.attributeSets,
      }
    }
    case PRODUCT_SET_ATTRIBUTE: {
      const index = state.selectedAttributes.findIndex(
        ({ attribute }) => attribute === action.attribute,
      )
      return update(state, {
        selectedAttributes: {
          [index]: { value: { $set: action.value } },
        },
      })
    }
    case PRODUCT_SET_SELECTED_PRODUCT: {
      return {
        ...state,
        productVariant: action.productVariant.id,
      }
    }
    default:
      return state
  }
}

export default reducer
