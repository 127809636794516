import Section from 'Components/Common/Layout/Section'
import NotificationContext from 'Context/NotificationContext'
import { addWorkplaceToCart } from 'Ducks/also/alsoCart'
import createDecorator from 'final-form-calculate'
import { ProductType } from 'Lib/alsoPropTypes'
import history from 'Lib/history'
import { head, max, min } from 'lodash'
import createCachedSelector from 're-reselect'
import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  accessoryProductGroupSelector,
  accessoryProductVariantSelector,
} from 'Selectors/also/productSelectors'
import styled from 'styled-components/macro'
import DeviceAccessories from './DeviceAccessories'
import DeviceDetails from './DeviceDetails'
import DeviceInformationTabs from './DeviceInformationTabs'

const StyledForm = styled.form`
  width: 100%;
`

const getAccessories = createCachedSelector(
  accessoryProductGroupSelector,
  accessoryProductVariantSelector,
  state => state.alsoConfig.fields,
  (_, { device }) => device,
  (groups, variants, alsoFields, device) =>
    groups
      .filter(g => g !== 'WorkplaceUserDevice')
      .map(group => ({
        fieldName: group,
        displayName: alsoFields[group].DisplayName,
        variants: variants
          .filter(variant => variant.enumMember.field === group)
          .filter(
            v =>
              v.genericAccessory ||
              device.accessorySKUs.some(sku => v.sku === sku),
          ),
      })),
)((state, { device }) => device.id)

const calculator = (accessories, addNotification) => {
  const updates = accessories.reduce(
    (acc, group) => ({
      ...acc,
      [group.fieldName]: (term, allValues) => {
        if (allValues[group.fieldName]) {
          const variantId = allValues[group.fieldName].variant
          const selectedVariant = group.variants.find(
            variant => variant.id === variantId,
          )

          const terms = selectedVariant
            ? Object.entries(selectedVariant.monthlyPrices).reduce(
                (acc, [term, price]) => {
                  if (price > 0) {
                    acc.push(Number(term))
                  }
                  return acc
                },
                [],
              )
            : []

          if (!terms.some(t => t === term)) {
            addNotification({
              message: (
                <Trans
                  i18nKey="catalog:removedAccessory"
                  fieldName={group.displayName}
                />
              ),
              type: 'info',
            })
            return undefined
          }
          return allValues[group.fieldName]
        }
        return undefined
      },
    }),
    {},
  )

  return createDecorator({
    field: 'term',
    updates,
  })
}

const DeviceForm = ({ device }) => {
  const location = useLocation()
  const variants = useSelector(state =>
    device.variants
      .map(variantId => state.alsoProductVariants.entities[variantId])
      .filter(p => p.active),
  )

  const variant =
    location.state &&
    location.state.variantId &&
    variants.some(v => v.id === location.state.variantId)
      ? variants.find(v => v.id === location.state.variantId)
      : head(variants)

  const accessories = useSelector(state => getAccessories(state, { device }))

  const dispatch = useDispatch()

  const notificationContext = useContext(NotificationContext)
  const calc = calculator(accessories, notificationContext.addNotification)

  const term = min([
    max(
      Object.entries(variant.monthlyPrices)
        // eslint-disable-next-line no-unused-vars
        .filter(([_, price]) => price > 0)
        .map(([term]) => term),
    ),
    36,
  ])

  return (
    <Form
      onSubmit={workplace => {
        dispatch(addWorkplaceToCart({ workplace }))
        history.push('/warenkorb')
      }}
      decorators={[calc]}
      keepDirtyOnReinitialize
      initialValues={{
        term: Number(term),
        quantity: 1,
        WorkplaceUserDevice: { variant: variant.id, quantity: 1 },
      }}
      render={({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Section padding="80">
            <DeviceDetails device={device} />
          </Section>

          <DeviceAccessories device={device} />
          <Section padding="80">
            <DeviceInformationTabs device={device} />
          </Section>
        </StyledForm>
      )}
    />
  )
}

DeviceForm.propTypes = {
  device: ProductType.isRequired,
}

export default DeviceForm
