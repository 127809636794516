import { uniq } from 'lodash'
import { createSelector } from 'reselect'
import { denormalizeVariants } from 'Lib/alsoNormalize'

export const fetchingAlsoProductsSelector = createSelector(
  state => state.alsoProducts.fetching,
  fetching => fetching,
)

export const mapIds = type => state =>
  state[type].ids ? state[type].ids.map(id => state[type].entities[id]) : []

const alsoProductByTypeSelector = type =>
  createSelector(mapIds('alsoProducts'), alsoProducts =>
    alsoProducts.filter(p => p.type === type && p.active),
  )

const alsoProductVariantByTypeSelector = type =>
  createSelector(mapIds('alsoProductVariants'), alsoProductVariants =>
    alsoProductVariants.filter(p => p.type === type && p.active),
  )

export const workplaceDevicesSelector = alsoProductByTypeSelector(
  'WorkplaceDevice',
)

export const categorySelector = createSelector(
  mapIds('alsoCategories'),
  alsoCategories => alsoCategories,
)

export const alsoProductSelector = createSelector(
  mapIds('alsoProducts'),
  (_, { slug }) => slug,
  (products, slug) => products.find(p => p.slug === slug),
)

export const accessoryProductSelector = alsoProductByTypeSelector('Accessory')

export const accessoryProductVariantSelector = alsoProductVariantByTypeSelector(
  'Accessory',
)

export const accessoryProductVariantForGroupSelector = createSelector(
  accessoryProductVariantSelector,
  (_, props) => props.group,
  (variants, group) =>
    variants.filter(variant => variant.enumMember.field === group),
)

export const accessoryProductGroupSelector = createSelector(
  accessoryProductVariantSelector,
  variants => uniq(variants.map(variant => variant.enumMember.field)),
)

export const variantsForProductSelector = createSelector(
  mapIds('alsoProductVariants'),
  (_, props) => props.product,
  (variants, product) =>
    variants.filter(v => v.product === product && v.active),
)

export const termsForVariantSelector = createSelector(
  mapIds('alsoProductVariants'),
  (_, props) => props.variant,
  (variants, variant) => {
    const variantObject = variants.find(p => p.id === variant)
    if (!variantObject) {
      return []
    }

    return Object.entries(variantObject.monthlyPrices)
      .filter(e => e[1] > 0)
      .map(e => Number(e[0]))
  },
)

const entitiesForNormalizerSelector = createSelector(
  state => state.alsoProductVariants.entities,
  state => state.alsoProducts.entities,
  state => state.alsoCategories.entities,
  (variant, product, category) => ({ variant, product, category }),
)

export const denormalizedVariantsSelector = createSelector(
  entitiesForNormalizerSelector,
  (_, props) => props.variants,
  (entities, variants) => denormalizeVariants(variants, entities),
)
