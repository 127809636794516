import { createSelector } from 'reselect'
import { mapIds } from './productSelectors'

export const workplaceDeviceVariantSelector = createSelector(
  mapIds('alsoProductVariants'),
  (_, { values }) => values.variant,
  (variants, id) => variants.find(v => v.id === id),
)

const sumPrices = prices => prices.reduce((prev, price) => prev + price, 0)

export const workplaceDeviceVariantPriceSelector = createSelector(
  workplaceDeviceVariantSelector,
  (_, { values }) => values.term,
  (variant, term) => variant && variant.monthlyPrices[term],
)

const workplaceVariantsSelector = createSelector(
  state => state.alsoProductVariants.entities,
  (_, { values: { term, quantity, Services, ...fields } }) => fields,
  (variants, fields) =>
    Object.values(fields)
      .filter(v => v)
      .map(({ variant, quantity }) => ({
        variant: variants[variant],
        quantity,
      })),
)

const workplaceServicesSelector = createSelector(
  state => state.alsoServices.entities,
  (_, { values: { Services: selectedServices } }) => selectedServices,
  (services, selectedServices) =>
    selectedServices
      ? Object.values(selectedServices)
          .filter(s => s)
          .map(id => services[id])
      : [],
)

export const workplacePricesSelector = createSelector(
  workplaceVariantsSelector,
  workplaceServicesSelector,
  (_, { values }) => values.term,
  (variants, services, term) => [
    ...variants.map(
      ({ variant, quantity }) => variant.monthlyPrices[term] * quantity,
    ),
    ...services.map(s => s.monthlyPrice),
  ],
)

export const workplacePriceSelector = createSelector(
  workplacePricesSelector,
  (_, { values }) => values.quantity,
  (prices, quantity) => sumPrices(prices) * quantity,
)

export const totalMonthlySelector = createSelector(
  state =>
    state.alsoCart.workplaces
      .filter(w => w)
      .map(w =>
        workplacePriceSelector(state, {
          values: w,
        }),
      ),
  sumPrices,
)
