import { put, takeLatest, call, all } from 'redux-saga/effects'
import config from 'Lib/config'
import {
  DEVICES_FETCH_LIST_REQUEST,
  DEVICES_FETCH_LIST_FAIL,
  DEVICES_FETCH_LIST_SUCCESS,
} from 'Ducks/common/devices'
import {
  CATEGORIES_FETCH_LIST_REQUEST,
  CATEGORIES_FETCH_LIST_FAIL,
  CATEGORIES_FETCH_LIST_SUCCESS,
} from 'Ducks/common/categories'

import {
  normalizeCategories,
  normalizeCustomProducts,
  normalizeResellerProducts,
} from 'Lib/normalize'
import { normalizeAlsoResellerProducts } from 'Lib/alsoNormalize'
import {
  PRODUCTS_FETCH_LIST_FAIL,
  PRODUCTS_FETCH_LIST_REQUEST,
  PRODUCTS_FETCH_LIST_SUCCESS,
} from 'Ducks/common/products'
import {
  SERVICES_FETCH_LIST_FAIL,
  SERVICES_FETCH_LIST_REQUEST,
  SERVICES_FETCH_LIST_SUCCESS,
} from 'Ducks/common/services'
import {
  SERVICE_EXTENSIONS_FETCH_LIST_FAIL,
  SERVICE_EXTENSIONS_FETCH_LIST_REQUEST,
  SERVICE_EXTENSIONS_FETCH_LIST_SUCCESS,
} from 'Ducks/common/serviceExtensions'
import {
  TESTIMONIALS_FETCH_LIST_REQUEST,
  TESTIMONIALS_FETCH_LIST_SUCCESS,
  TESTIMONIALS_FETCH_LIST_FAIL,
} from 'Ducks/common/testimonials'
import {
  TEAM_FETCH_LIST_FAIL,
  TEAM_FETCH_LIST_REQUEST,
  TEAM_FETCH_LIST_SUCCESS,
} from 'Ducks/common/team'
import {
  RESELLER_INFO_FETCH_FAIL,
  RESELLER_INFO_FETCH_REQUEST,
  RESELLER_INFO_FETCH_SUCCESS,
} from 'Ducks/common/resellerInfo'
import {
  STORE_VIEW_FETCH_FAIL,
  // STORE_VIEW_FETCH_REQUEST,
  STORE_VIEW_FETCH_SUCCESS,
} from 'Ducks/common/storeView'
import {
  PROTECTION_PLANS_FETCH_LIST_FAIL,
  PROTECTION_PLANS_FETCH_LIST_REQUEST,
  PROTECTION_PLANS_FETCH_LIST_SUCCESS,
} from 'Ducks/common/protectionPlans'
import {
  PAGES_FETCH_LIST_FAIL,
  PAGES_FETCH_LIST_REQUEST,
  PAGES_FETCH_LIST_SUCCESS,
} from 'Ducks/common/pages'
import {
  LINKS_FETCH_LIST_FAIL,
  LINKS_FETCH_LIST_REQUEST,
  LINKS_FETCH_LIST_SUCCESS,
} from 'Ducks/common/links'
import {
  SLIDES_FETCH_LIST_FAIL,
  SLIDES_FETCH_LIST_REQUEST,
  SLIDES_FETCH_LIST_SUCCESS,
} from 'Ducks/common/slides'
import {
  PROMOTION_LOGOS_FETCH_LIST_FAIL,
  PROMOTION_LOGOS_FETCH_LIST_REQUEST,
  PROMOTION_LOGOS_FETCH_LIST_SUCCESS,
} from 'Ducks/common/marketingLogos'

import {
  ALSO_PRODUCTS_FETCH_LIST_REQUEST,
  ALSO_PRODUCTS_FETCH_LIST_SUCCESS,
  ALSO_PRODUCTS_FETCH_LIST_FAIL,
} from 'Ducks/also/alsoProducts'

import {
  ALSO_SERVICES_FETCH_LIST_REQUEST,
  ALSO_SERVICES_FETCH_LIST_SUCCESS,
  ALSO_SERVICES_FETCH_LIST_FAIL,
} from 'Ducks/also/alsoServices'

import { ALSO_CONFIG_FETCH_FAIL, setConfig } from 'Ducks/also/alsoConfig'

import {
  BENEFITS_FETCH_LIST_FAIL,
  BENEFITS_FETCH_LIST_REQUEST,
  BENEFITS_FETCH_LIST_SUCCESS,
} from 'Ducks/common/benefits'

import {
  PROMO_BUNDLES_FETCH_LIST_FAIL,
  PROMO_BUNDLES_FETCH_LIST_REQUEST,
  PROMO_BUNDLES_FETCH_LIST_SUCCESS,
} from 'Ducks/common/promoBundles'

import {
  ALSO_CATEGORIES_FETCH_LIST_SUCCESS,
  ALSO_CATEGORIES_FETCH_LIST_FAIL,
} from 'Ducks/also/alsoCategories'
import {
  ALSO_PRODUCT_VARIANTS_FETCH_LIST_SUCCESS,
  ALSO_PRODUCT_VARIANTS_FETCH_LIST_FAIL,
} from 'Ducks/also/alsoProductVariants'
import {
  fetchCategories,
  fetchProducts,
  fetchAlsoResellerProducts,
  fetchAlsoResellerServices,
  fetchDevices,
  fetchServices,
  fetchTestimonials,
  fetchTeam,
  fetchResellerInfo,
  fetchStoreView,
  fetchProtectionPlans,
  fetchPages,
  fetchLinks,
  fetchSlides,
  fetchMarketingLogos,
  fetchBenefits,
  fetchAlsoConfig,
  fetchServiceExtensions,
  fetchPromoBundles,
} from 'Lib/fetch'

export const FETCH_ALL_REQUEST = 'FETCH_ALL'
export const FETCH_DISCOUNTABLES_REQUEST = 'FETCH_DISCOUNTABLES_REQUEST'

function* handleFetchDevices() {
  try {
    const devicesResponse = yield call(fetchDevices)

    const normalized = normalizeResellerProducts(
      devicesResponse.data['hydra:member'],
    )

    yield put({
      type: DEVICES_FETCH_LIST_SUCCESS,
      data: normalized.entities.resellerProduct,
    })
  } catch (e) {
    yield put({
      type: DEVICES_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchCategories() {
  try {
    const categoriesResponse = yield call(fetchCategories)

    const normalized = normalizeCategories(
      categoriesResponse.data['hydra:member'],
    )

    yield put({
      type: CATEGORIES_FETCH_LIST_SUCCESS,
      data: normalized.entities.category,
    })
  } catch (e) {
    yield put({
      type: CATEGORIES_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchAlsoResellerProducts() {
  try {
    const alsoResellerProductsResponse = yield call(fetchAlsoResellerProducts)

    const products = alsoResellerProductsResponse.data['hydra:member'].filter(
      resellerProduct => resellerProduct.product.variants.length > 0,
    )

    const normalized = normalizeAlsoResellerProducts(products)

    yield put({
      type: ALSO_PRODUCT_VARIANTS_FETCH_LIST_SUCCESS,
      data: normalized.entities.variant || {},
    })
    yield put({
      type: ALSO_PRODUCTS_FETCH_LIST_SUCCESS,
      data: normalized.entities.product || {},
    })
    yield put({
      type: ALSO_CATEGORIES_FETCH_LIST_SUCCESS,
      data: normalized.entities.category || {},
    })
  } catch (e) {
    yield put({
      type: ALSO_PRODUCT_VARIANTS_FETCH_LIST_FAIL,
      e,
    })
    yield put({
      type: ALSO_PRODUCTS_FETCH_LIST_FAIL,
      e,
    })
    yield put({
      type: ALSO_CATEGORIES_FETCH_LIST_FAIL,
      e,
    })
  }
}

function* handleFetchAlsoResellerServices() {
  try {
    const alsoResellerServicesResponse = yield call(fetchAlsoResellerServices)

    const services = alsoResellerServicesResponse.data['hydra:member'].filter(
      resellerService => resellerService.monthlyPrice > 0,
    )

    const data = {}

    services.forEach(s => {
      data[s.id] = s
    })

    yield put({
      type: ALSO_SERVICES_FETCH_LIST_SUCCESS,
      data,
    })
  } catch (e) {
    yield put({
      type: ALSO_SERVICES_FETCH_LIST_FAIL,
      e,
    })
  }
}

function* handleFetchAlsoConfig() {
  try {
    const alsoConfigResponse = yield call(fetchAlsoConfig)
    yield put(setConfig(alsoConfigResponse.data))
  } catch (e) {
    yield put({
      type: ALSO_CONFIG_FETCH_FAIL,
      e,
    })
  }
}

function* handleFetchProducts() {
  try {
    const productsResponse = yield call(fetchProducts)

    const normalized = normalizeCustomProducts(
      productsResponse.data['hydra:member'],
    )

    yield put({
      type: PRODUCTS_FETCH_LIST_SUCCESS,
      data: normalized.entities.customProduct,
    })
  } catch (e) {
    yield put({
      type: PRODUCTS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchServices() {
  try {
    const servicesResponse = yield call(fetchServices)

    yield put({
      type: SERVICES_FETCH_LIST_SUCCESS,
      data: servicesResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: SERVICES_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchServiceExtensions() {
  try {
    const serviceExtensionsResponse = yield call(fetchServiceExtensions)

    yield put({
      type: SERVICE_EXTENSIONS_FETCH_LIST_SUCCESS,
      data: serviceExtensionsResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: SERVICE_EXTENSIONS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchTestimonials() {
  try {
    const testimonialResponse = yield call(fetchTestimonials)

    yield put({
      type: TESTIMONIALS_FETCH_LIST_SUCCESS,
      data: testimonialResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: TESTIMONIALS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchTeam() {
  try {
    const teamResponse = yield call(fetchTeam)

    yield put({
      type: TEAM_FETCH_LIST_SUCCESS,
      data: teamResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: TEAM_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchStoreView() {
  try {
    const storeViewResponse = yield call(fetchStoreView)

    yield put({
      type: STORE_VIEW_FETCH_SUCCESS,
      data: storeViewResponse.data,
    })
  } catch (e) {
    yield put({
      type: STORE_VIEW_FETCH_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchResellerInfo() {
  try {
    const resellerInfoResponse = yield call(fetchResellerInfo)

    yield put({
      type: RESELLER_INFO_FETCH_SUCCESS,
      data: resellerInfoResponse.data,
    })
  } catch (e) {
    yield put({
      type: RESELLER_INFO_FETCH_FAIL,
      e,
    })
    // throw e
  }
}

function* handleFetchProtectionPlans() {
  try {
    const protectionPlanResponse = yield call(fetchProtectionPlans)

    yield put({
      type: PROTECTION_PLANS_FETCH_LIST_SUCCESS,
      data: protectionPlanResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: PROTECTION_PLANS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchPages() {
  try {
    const pagesResponse = yield call(fetchPages)
    yield put({
      type: PAGES_FETCH_LIST_SUCCESS,
      data: pagesResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: PAGES_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchLinks() {
  try {
    const linksResponse = yield call(fetchLinks)

    yield put({
      type: LINKS_FETCH_LIST_SUCCESS,
      data: linksResponse.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: LINKS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchSlides() {
  try {
    const res = yield call(fetchSlides)

    yield put({
      type: SLIDES_FETCH_LIST_SUCCESS,
      data: res.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: SLIDES_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchBenefits() {
  try {
    const res = yield call(fetchBenefits)

    yield put({
      type: BENEFITS_FETCH_LIST_SUCCESS,
      data: res.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: BENEFITS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchMarketingLogos() {
  try {
    const res = yield call(fetchMarketingLogos)

    yield put({
      type: PROMOTION_LOGOS_FETCH_LIST_SUCCESS,
      data: res.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: PROMOTION_LOGOS_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchPromoBundles() {
  try {
    const res = yield call(fetchPromoBundles)

    yield put({
      type: PROMO_BUNDLES_FETCH_LIST_SUCCESS,
      data: res.data['hydra:member'],
    })
  } catch (e) {
    yield put({
      type: PROMO_BUNDLES_FETCH_LIST_FAIL,
      e,
    })
    throw e
  }
}

function* handleFetchDiscountables() {
  yield all([
    call(handleFetchDevices),
    call(handleFetchProducts),
    call(handleFetchServiceExtensions),
    call(handleFetchProtectionPlans),
    call(handleFetchServices),
  ])
}

function* handleFetchAll() {
  if (config.reseller.also) {
    yield all([
      call(handleFetchStoreView),
      call(handleFetchResellerInfo),
      call(handleFetchSlides),
      call(handleFetchLinks),
      call(handleFetchAlsoResellerProducts),
      call(handleFetchAlsoResellerServices),
      call(handleFetchTestimonials),
      call(handleFetchTeam),
      call(handleFetchPages),
      call(handleFetchBenefits),
      call(handleFetchMarketingLogos),
      call(handleFetchAlsoConfig),
    ])
  } else {
    yield all([
      call(handleFetchStoreView),
      call(handleFetchResellerInfo),
      call(handleFetchSlides),
      call(handleFetchLinks),
      call(handleFetchDevices),
      call(handleFetchProducts),
      call(handleFetchCategories),
      call(handleFetchServices),
      call(handleFetchServiceExtensions),
      call(handleFetchTestimonials),
      call(handleFetchTeam),
      call(handleFetchProtectionPlans),
      call(handleFetchPages),
      call(handleFetchBenefits),
      call(handleFetchMarketingLogos),
      call(handleFetchPromoBundles),
    ])
  }
  window.prerenderReady = true
}

function* fetchSaga() {
  yield takeLatest(DEVICES_FETCH_LIST_REQUEST, handleFetchDevices)
  yield takeLatest(
    ALSO_PRODUCTS_FETCH_LIST_REQUEST,
    handleFetchAlsoResellerProducts,
  )
  yield takeLatest(
    ALSO_SERVICES_FETCH_LIST_REQUEST,
    handleFetchAlsoResellerServices,
  )
  yield takeLatest(PRODUCTS_FETCH_LIST_REQUEST, handleFetchProducts)
  yield takeLatest(CATEGORIES_FETCH_LIST_REQUEST, handleFetchCategories)
  yield takeLatest(SERVICES_FETCH_LIST_REQUEST, handleFetchServices)
  yield takeLatest(
    SERVICE_EXTENSIONS_FETCH_LIST_REQUEST,
    handleFetchServiceExtensions,
  )
  yield takeLatest(TESTIMONIALS_FETCH_LIST_REQUEST, handleFetchTestimonials)
  yield takeLatest(TEAM_FETCH_LIST_REQUEST, handleFetchTeam)
  yield takeLatest(
    PROTECTION_PLANS_FETCH_LIST_REQUEST,
    handleFetchProtectionPlans,
  )
  yield takeLatest(PAGES_FETCH_LIST_REQUEST, handleFetchPages)
  yield takeLatest(LINKS_FETCH_LIST_REQUEST, handleFetchLinks)
  yield takeLatest(SLIDES_FETCH_LIST_REQUEST, handleFetchSlides)
  yield takeLatest(BENEFITS_FETCH_LIST_REQUEST, handleFetchBenefits)
  yield takeLatest(
    PROMOTION_LOGOS_FETCH_LIST_REQUEST,
    handleFetchMarketingLogos,
  )
  yield takeLatest(PROMO_BUNDLES_FETCH_LIST_REQUEST, handleFetchPromoBundles)
  yield takeLatest(RESELLER_INFO_FETCH_REQUEST, handleFetchResellerInfo) // todo rather create an info route or something
  yield takeLatest(FETCH_DISCOUNTABLES_REQUEST, handleFetchDiscountables)
  yield takeLatest(FETCH_ALL_REQUEST, handleFetchAll)
}

export default fetchSaga
