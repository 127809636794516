import { findLeasingFactor } from 'Selectors/common/priceSelectors'

export const getServicesPrice = (quantity, services, allServices) =>
  services
    ? quantity *
      Object.values(services)
        .filter(id => id)
        .map(id => allServices.find(s => s.id === id).netPrice)
        .reduce((prev, sum) => prev + sum, 0)
    : 0

export const calculateRate = ({ totalPrice, period, leasingFactors }) => {
  const { leasingFactor } = findLeasingFactor(
    period,
    totalPrice,
  )(leasingFactors)
  const rate = (totalPrice * (leasingFactor + 0.02)) / 100

  return rate
}

export default calculateRate
