import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import classnames from 'classnames'
import colors from 'Assets/styles/colors'
import { specialLinkSelector } from 'Selectors/common/linkSelectors'
import SpecialLink from 'Components/Common/SpecialLink'

const PrivacyStatement = ({ style, className }) => {
  const companyName = useSelector(state => get(state, 'resellerInfo.data.name'))

  const link = useSelector(specialLinkSelector('privacyStatement'))

  if (!link || !link.active) {
    return null
  }
  return (
    <div className={classnames('cell medium-12', className)}>
      <div style={style}>
        <Trans
          i18nKey="contact:acceptDataPrivacy"
          values={{ company: companyName }}
          components={[<SpecialLink type="privacyStatement" />]}
        />
      </div>
    </div>
  )
}

PrivacyStatement.propTypes = {
  style: PropTypes.object, // eslint-disable-line
  className: PropTypes.string,
}

PrivacyStatement.defaultProps = {
  style: undefined,
  className: undefined,
}

export const TextPrivacyStatement = styled(PrivacyStatement)`
  & > div {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 300;
    color: ${colors.greyishBrownEight};

    & a {
      color: ${props => props.theme.firstColor};
      text-decoration: none;
    }
  }
`

export default PrivacyStatement
