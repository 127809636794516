import { isEmail } from 'Lib/validationHelper'

export default values => {
  const requiredMessage = 'required'
  const errors = {}
  if (!values.name) {
    errors.name = requiredMessage
  }
  if (!values.phone) {
    errors.phone = requiredMessage
  }
  if (!values.company) {
    errors.company = requiredMessage
  }
  if (!values.email) {
    errors.email = requiredMessage
  } else if (!isEmail(values.email)) {
    errors.email = 'invalidEmail'
  }
  if (!values.time) {
    errors.time = requiredMessage
  }
  if (!values.weekday) {
    errors.weekday = requiredMessage
  }

  return errors
}
