import { ProductImage } from 'Components/Common/Layout/Image'
import QuantitySpinner from 'Components/Common/QuantitySpinner'
import {
  decrementWorkplaceAccessoryQuantity,
  incrementWorkplaceAccessoryQuantity,
} from 'Ducks/also/alsoCart'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'

const Disabled = styled.div`
  width: 100px;
  font-size: 10px;
  color: ${props => props.theme.errorColor};
`

const WorkplaceAccessory = ({ variant, quantity, field, term, index }) => {
  const accessory = useSelector(
    state => state.alsoProductVariants.entities[variant],
  )
  const disabled = !(accessory.monthlyPrices[term] > 0)

  const dispatch = useDispatch()

  return (
    <Flex alignItems="center">
      <QuantitySpinner
        size={15}
        quantity={quantity}
        increment={() =>
          dispatch(incrementWorkplaceAccessoryQuantity(index, field))
        }
        decrement={() =>
          dispatch(decrementWorkplaceAccessoryQuantity(index, field))
        }
      />
      <div style={{ marginLeft: '8px' }}>
        <ProductImage
          imageEntity={accessory.product.imageEntity}
          alt={accessory.name}
          title={accessory.name}
          width={100}
          height={40}
        />
        {disabled && (
          <Disabled>Zur gewählten Laufzeit nicht verfügbar</Disabled>
        )}
      </div>
    </Flex>
  )
}

WorkplaceAccessory.propTypes = {
  term: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
}

WorkplaceAccessory.defaultProps = {}

export default WorkplaceAccessory
