import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import MetaData from '../Layout/MetaData'
import Section from '../Layout/Section'
import SignupForm from './SignupForm'
import styles from './styles'

const useStyles = createUseStyles(styles)

const Signup = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation('auth')
  return (
    <>
      <Section title={t('signup')}>
        <MetaData canonical="account/signup" title="Mein Konto" />
        <div className="grid-x grid-padding-x">
          <div className="cell small-12 medium-8 medium-offset-2">
            <SignupForm onSuccess={() => history.push('/account')} />
          </div>
          <div className="cell small-12 medium-8 medium-offset-2">
            <Link to="/account/login" className={classes.forgotPasswordLink}>
              {t('alreadySignedUp')}
            </Link>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Signup
