import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import React from 'react'
import { createUseStyles } from 'react-jss'

export const inputStyles = theme => ({
  container: {
    backgroundColor: '#ffffff',
    color: colors.warmGreyFive,
    fontSize: '18px',
    fontWeight: '300',

    border: {
      style: 'solid',
      width: '1px',
      color: colors.pinkishGreyTwo,
    },

    borderRadius: '3px',
    '&.error input': {
      boxShadow: `0 0 5px ${theme.errorColor}`,
      border: `1px solid ${theme.errorColor}`,
    },
  },
  input: {
    textAlign: 'left',
    letterSpacing: '0.4px',
    lineHeight: '57px',
    paddingLeft: '8px',
    borderRadius: '3px',
    color: colors.warmGreyFive,
    outline: 'none',
    '&::placeholder': {
      color: colors.pinkishGrey,
    },
    '&:focus': {
      boxShadow: `0 0 5px ${theme.firstColor}`,
      border: `1px solid ${theme.firstColor}`,
    },
  },
  inputIcon: {
    extend: 'input',
    paddingLeft: '48px',
  },
  error: {
    color: theme.errorColor,
  },
  darkError: {
    color: colors.white,
  },
})

const useStyles = createUseStyles(inputStyles)

const TextareaInput = ({
  input,
  placeholder,
  style,
  expand,
  icon,
  className,
  rows,
}) => {
  const classes = useStyles()

  return (
    <textarea
      style={{
        ...style,
        width: expand ? '100%' : 'auto',
        backgroundImage: `url(/Assets/icons/${icon}.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20px',
        backgroundPositionY: 'center',
        backgroundPositionX: '16px',
      }}
      className={classnames(
        classes.container,
        icon ? classes.inputIcon : classes.input,
        className,
      )}
      rows={rows}
      placeholder={placeholder}
      {...input}
    />
  )
}

TextareaInput.defaultProps = {
  rows: 3,
}

export default TextareaInput
