import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import useStoreView from 'Hooks/useStoreView'
import get from 'lodash/get'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import Image from '../../Layout/Image'
import styles from './styles'

const useStyles = createUseStyles(styles)

const Contact = () => {
  const classes = useStyles()
  const phone = useSelector(state =>
    get(state, 'resellerInfo.data.address.phone'),
  )
  const contactEmail = useStoreView('contactEmail')
  const companyLogoFooter = useStoreView('companyLogoFooter')
  const contactUrl = useStoreView('contactUrl')

  return (
    <div className={classnames('grid-y', 'grid-margin-y')}>
      <div className={classnames('cell', 'shrink', classes.logo)}>
        <Image
          src={companyLogoFooter}
          alt={contactUrl}
          width={250}
          height={62}
        />
      </div>
      <div className={classnames('cell', 'shrink', classes.phone)}>
        <FontAwesomeIcon icon="phone" />
        &nbsp;
        <a className={classes.phone} href={`tel:${phone}`}>
          {phone}
        </a>
      </div>
      <div className={classnames('cell', 'shrink', classes.mail)}>
        <FontAwesomeIcon icon="envelope" />
        &nbsp;
        <a className={classes.mail} href={`mailto:${contactEmail}`}>
          {contactEmail}
        </a>
      </div>
    </div>
  )
}

export default Contact
