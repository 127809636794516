import { get } from 'lodash'
import { useSelector } from 'react-redux'

const useStoreView = path => {
  const storeView = useSelector(state => state.storeView)

  return get(storeView, `data.${path}`)
}

export default useStoreView
