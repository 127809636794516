import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'
import QuantitySpinner from '../QuantitySpinner'
import { flexCentered } from '../Layout/helpers'

export const Box = styled.div`
  background-color: white;
  border: 1px solid;
  border-color: ${props => props.theme.firstColor};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  font-family: Titillium Web;
  height: ${props => props.height}px;
`

export const HalfContainer = styled.div`
  width: 50%;
  display: flex;

  & > * {
    width: 100%;
    height: 100%;
  }
`

export const Price = styled.div`
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  color: ${colors.greyishBrown};
  font-size: 17px;
  font-weight: bold;
`

export const PriceText = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  flex-grow: 1;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background-color: ${props =>
    props.hollow ? colors.white : props.theme.firstColor};
  color: ${props => (props.hollow ? props.theme.firstColor : colors.white)};
  padding: 0px;

  & a {
    text-decoration: none;
    color: white;
  }
`

export const Quantity = styled(QuantitySpinner)`
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${colors.greyishBrown};
  font-size: 22px;
  line-height: 16px;
  /* width: 50%; */

  borderRight {
    style: solid;
    width: 1px;
    color: ${props => props.theme.firstColor};
  }
`

export default theme => ({
  halfContainer: {},
  quantity: {},
  price: {},
  text: {
    textAlign: 'center',
    backgroundColor: props => (props.hollow ? colors.white : theme.firstColor),
    color: props => (props.hollow ? theme.firstColor : colors.white),
    fontSize: '15px',
    fontWeight: 'bold',
    flexGrow: '1',
    padding: '8px 24px',
    borderRadius: '0 3px 3px 0',
    cursor: 'pointer',
    textDecoration: 'none',

    textTransform: 'uppercase',
    ...flexCentered,
  },
  priceText: {
    extend: 'text',
  },
})
