import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  decrementProductQuantity,
  incrementProductQuantity,
  removeProductFromCart,
} from 'Ducks/common/cart'
import { productConfigurationMonthlyRateSelector } from 'Selectors/common/priceSelectors'
import Product from './Product'
import Line from './Line'

/* eslint-disable react/style-prop-object */

const mapStateToProps = (state, props) => {
  const {
    products: { list: products },
  } = state
  return {
    products,
    price: productConfigurationMonthlyRateSelector(state, props),
  }
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    incrementProductQuantity,
    decrementProductQuantity,
    removeProductFromCart,
  }),
  withProps(({ productConfiguration, products }) => ({
    product: products.find(p => p.id === productConfiguration.product),
  })),
)

const ProductLine = ({
  productConfiguration,
  index,
  incrementProductQuantity,
  decrementProductQuantity,
  removeProductFromCart,
  price,
}) => (
  <Line
    quantity={productConfiguration.quantity}
    increment={() => incrementProductQuantity(index)}
    decrement={() => decrementProductQuantity(index)}
    remove={() => removeProductFromCart(index)}
    price={price}
  >
    <Product productConfiguration={productConfiguration} />
  </Line>
)

ProductLine.propTypes = {
  productConfiguration: PropTypes.object.isRequired, //eslint-disable-line
  index: PropTypes.number.isRequired,
  incrementProductQuantity: PropTypes.func.isRequired,
  decrementProductQuantity: PropTypes.func.isRequired,
  removeProductFromCart: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
}

export default enhance(ProductLine)

/* eslint-ensable react/style-prop-object */
