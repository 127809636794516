import React from 'react'
import { Field } from 'react-final-form'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { branch, compose, renderNothing } from 'recompose'
import isEmpty from 'lodash/isEmpty'
import { specialLinkSelector } from 'Selectors/common/linkSelectors'
import SpecialLink from 'Components/Common/SpecialLink'
import { renderCheckbox } from '../Layout/Form/FormField'

const AcceptTermsCheckBox = () => (
  <div className="cell medium-12">
    <label htmlFor="terms" className="checkbox ">
      <Field
        validate={value => isEmpty(value) && 'termsNotAccepted'}
        className="input-checkbox"
        component={renderCheckbox}
        name="terms"
        id="terms"
        value="1"
        type="checkbox"
        label={
          <Trans
            i18nKey="checkout:form.accept"
            components={[<SpecialLink type="terms" />]}
          />
        }
      />
    </label>
  </div>
)

const mapStateToProps = state => ({
  link: specialLinkSelector('terms')(state),
})

const enhance = compose(
  connect(mapStateToProps),
  branch(({ link }) => !link || !link.active, renderNothing),
)

export default enhance(AcceptTermsCheckBox)
