import colors from 'Assets/styles/colors'
import NoResults from 'Components/Common/CategoryPage/NoResults'
import SearchSection from 'Components/Common/CategoryPage/Search'
import Heading from 'Components/Common/Layout/Heading'
import MetaData from 'Components/Common/Layout/MetaData'
import Section from 'Components/Common/Layout/Section'
import Title from 'Components/Common/Title'
import useProductSearch from 'Hooks/useProductSearch'
import useStoreView from 'Hooks/useStoreView'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  categorySelector,
  workplaceDevicesSelector,
} from 'Selectors/also/productSelectors'
import styled from 'styled-components/macro'
import CategoryLinks from './CategoryLinks'
import Device from './Device'

const Description = styled.div`
  color: ${colors.brownishGrey};
  font-weight: 300;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.7px;
  margin-top: 78px;
`

const CategoryPage = () => {
  const { slug } = useParams()

  const categories = useSelector(categorySelector)

  const category = categories.find(c => c.slug === slug)

  const { devices } = useSelector(state => {
    const devices = workplaceDevicesSelector(state)

    return {
      devices: category
        ? devices.filter(d => d.category === category.id)
        : devices,
    }
  })

  const { t } = useTranslation('catalog')

  const { results, filter, setFilter } = useProductSearch(devices)

  const productOverviewMetadata = useStoreView(
    'seoConfiguration.productOverviewMetadata',
  )
  const productOverviewSlug = useStoreView(
    'seoConfiguration.productOverviewSlug',
  )
  const productOverviewSeoText = useStoreView(
    'seoConfiguration.productOverviewSeoText',
  )

  return (
    <>
      <MetaData
        title={category ? category.name : t('products')}
        metaData={category ? category.metaData : productOverviewMetadata}
        canonical={category || productOverviewSlug}
      />
      <Heading>
        <Title i18nKey="category" />
      </Heading>
      <SearchSection filter={filter} setFilter={setFilter} />
      <CategoryLinks categories={categories} />
      <Section paddingTop="80" paddingBottom="40">
        {filter !== '' && results.length === 0 ? (
          <NoResults filter={filter} />
        ) : (
          results.map(device => <Device key={device.id} device={device} />)
        )}
      </Section>
      <Section paddingTop="40" paddingBottom="80">
        <Description
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: category ? category.description : productOverviewSeoText,
          }}
        />
      </Section>
    </>
  )
}

CategoryPage.propTypes = {}

CategoryPage.defaultProps = {}

export default CategoryPage
