import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { renderField } from '../Layout/Form/FormField'
import SalutationSelect from './SalutationSelect'
import styles from './styles'

const useStyles = createUseStyles(styles)

const CeoForm = ({ note }) => {
  const classes = useStyles()
  const { t } = useTranslation('checkout')

  return (
    <div className={classnames('grid-x', 'grid-padding-x', classes.fieldGroup)}>
      <div className="cell small-12">
        <div className={classes.fieldGroupHeader}>{t('ceo')}</div>
      </div>
      {note && (
        <div className="cell small-12">
          <div className={classes.fieldGroupComment}>{note}</div>
        </div>
      )}
      <div className="cell medium-4">
        <Field name="ceo[salutation]" component={SalutationSelect} />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.firstName')}
          name="ceo[firstName]"
          id="ceo[firstName]"
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.lastName')}
          name="ceo[lastName]"
          id="ceo[lastName]"
        />
      </div>
      <div className="cell medium-12">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.street')}
          name="ceo[street]"
          id="ceo[street]"
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.postCode')}
          name="ceo[postcode]"
          id="ceo[postcode]"
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.city')}
          name="ceo[city]"
          id="ceo[city]"
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.birthdate')}
          name="ceo[birthdate]"
          id="ceo[birthdate]"
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.phone')}
          name="ceo[phone]"
          id="ceo[phone]"
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.ceo.fax')}
          name="ceo[fax]"
          id="ceo[fax]"
        />
      </div>
    </div>
  )
}

CeoForm.propTypes = {
  note: PropTypes.string,
}

CeoForm.defaultProps = {
  note: null,
}

export default CeoForm
