import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const DetailsHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.39;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${colors.greyishBrownFour};
  margin-bottom: 8px;
`

export const AccessoryContainer = styled.div`
  display: flex;
  width: 270px;
  height: 300px;
  background-color: white;
  border: 1px solid;
  border-color: ${colors.greyish29};
  border-radius: 3px;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const ProductName = styled.div`
  text-align: center;
  font-size: 16px;
`

export const ProductVariantName = styled.div`
  text-align: center;
  font-size: 14px;
`
