import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const Container = styled.div`
  font-family: 'Titillium Web';
  border-radius: 3px;
  background-color: #ffffff;

  border: 1px solid ${colors.whiteFive};
  width: 100%;
  padding: 40px 0;
  margin: 16px 0;
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`
export const PriceContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  align-items: center;

  & div:not(:first-child) {
    margin-top: 24px;
  }
`
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 32px;
  }
  padding: 0 16px;

  @media (min-width: 1024px) {
    border-right: 1px solid ${colors.whiteFive};
  }
`
export const Name = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 0.83;
  letter-spacing: 0.6px;
  text-align: left;
  color: ${colors.greyishBrownThree};
  text-decoration: none;
`
export const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.39;
  letter-spacing: 0.5px;
  color: ${colors.brownishGrey};
`
export const Price = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 0.78;
  text-align: center;
  color: ${colors.greyishBrown};
`
