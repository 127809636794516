import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import split from 'lodash/split'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const styles = theme => ({
  box: {
    height: '112px',
    width: '100%',
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.greyish29,
    color: colors.greyishBrown,
    borderRadius: '3px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  boxInverted: {
    extend: 'box',
    backgroundColor: theme.firstColor,
    color: colors.white,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.firstColor,
  },
  invertedIconContainer: {
    extend: 'iconContainer',
    color: colors.white,
  },
  name: {
    display: 'block',
    fontSize: '21px',
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: '0.4px',
    lineHeight: '1.19',
  },
})

const useStyles = createUseStyles(styles)

const BenefitBox = ({ text, icon, inverted, onClick }) => {
  const classes = useStyles()

  return (
    <div
      className={classnames(
        'grid-x',
        inverted ? classes.boxInverted : classes.box,
      )}
      onClick={onClick}
      role="button"
    >
      <div
        className={classnames(
          'cell',
          'small-4',
          inverted ? classes.invertedIconContainer : classes.iconContainer,
        )}
      >
        <FontAwesomeIcon size="4x" icon={split(icon, ' ')} />
      </div>
      <span className={classnames('cell', 'auto', classes.name)}>{text}</span>
    </div>
  )
}

BenefitBox.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
}

BenefitBox.defaultProps = {
  inverted: false,
}

export default BenefitBox
