import color from 'color'
import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const CustomerContainer = styled.div`
  background-color: ${colors.whiteNine};
  padding: 40px;
  display: flex;
  flex-direction: column;
`

export const CustomerHeader = styled.div`
  margin-bottom: 24px;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0.4px;
  color: ${colors.greyishBrownEight};
  font-family: Titillium Web;
`

export const FieldGroupHeader = styled.div`
  margin-bottom: 16px;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 0.4px;
  color: ${colors.greyishBrownEight};
  font-family: Titillium Web;
`

export const FieldgroupComment = styled.div`
  margin-top: -16px;
  margin-bottom: 16px;
  color: ${colors.greyishBrownEight};
`

export const ExistingCustomerContainer = styled.div`
  margin: -8px 0;
  & > * {
    margin: 8px 0;
  }
`
export const CustomerLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -8px;
  & > * {
    margin: 0 8px;
  }
`
export const NewCustomerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NewCustomerText = styled.div`
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: 0.7px;
  margin-right: 8px;
`

export const Form = styled.form`
  & .is-focused:not(.is-open) > .Select-control {
    box-shadow: ${props => `0 0 5px ${props.theme.firstColor}`};
    border: ${props => `1px solid ${props.theme.firstColor}`};
  }

  & .Select-option {
    &.is-selected {
      background-color: ${props =>
        color(props.theme.firstColor)
          .fade(0.5)
          .string()};
      color: rgba(0, 0, 0, 0.87);
    }

    &.is-focused {
      background-color: ${props =>
        color(props.theme.firstColor)
          .fade(0.3)
          .string()};
      color: rgba(0, 0, 0, 0.87);
    }
  }
`
export const FieldGroup = styled.div`
  margin: 0.25rem 0;
  & > * {
    margin: 0.25rem 0;
  }
`
export const FieldSection = styled.div`
  margin: 24px 0;
`

export const CopyToCEOButton = styled.button`
  margin-top: 0.7em;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.firstColor};
`

export const Text = styled.div`
  margin-top: 16px;
  font-size: 18px;
  font-weight: 300;
  color: ${colors.greyishBrownEight};

  & a {
    color: ${props => props.theme.firstColor};
    text-decoration: none;
  }
`

export default theme => ({
  primaryColorLabel: {
    color: theme.firstColor,
  },
})
