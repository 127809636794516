import Section from 'Components/Common/Layout/Section'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { accessoryProductGroupSelector } from 'Selectors/also/productSelectors'
import AccessoryMainSlider from './AccessoryMainSlider'

const DeviceAccessories = ({ device }) => {
  const accessoryProductGroups = useSelector(accessoryProductGroupSelector)

  if (accessoryProductGroups.length === 0) {
    return null
  }
  return (
    <Fragment>
      <Section paddingTop="80" paddingBottom="0" title="Verfügbares Zubehör" />

      {accessoryProductGroups.map(group => (
        <AccessoryMainSlider key={group} device={device} group={group} />
      ))}
    </Fragment>
  )
}

DeviceAccessories.propTypes = {
  device: PropTypes.object.isRequired,
}

export default DeviceAccessories
