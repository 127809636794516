import concat from 'lodash/concat'

export const totalCartQuantity = ({ cart: { devices, products } }) =>
  concat(devices, products)
    .map(({ quantity }) => quantity)
    .reduce((prev, quantity) => prev + quantity, 0)

export const alsoTotalCartQuantity = ({ alsoCart: { workplaces } }) =>
  workplaces
    .filter(w => w)
    .map(w => w.quantity)
    .reduce((prev, quantity) => prev + quantity, 0)
