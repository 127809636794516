import Parser from 'html-react-parser'
import React from 'react'
import { Helmet } from 'react-helmet'
import useStoreView from 'Hooks/useStoreView'

const DocumentHeadTags = () => {
  const documentHead = useStoreView('documentHead')

  if (!documentHead) {
    return null
  }
  return <Helmet>{Parser(documentHead)}</Helmet>
}

export default DocumentHeadTags
