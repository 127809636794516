import classnames from 'classnames'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  withState,
} from 'recompose'
import colors from 'Assets/styles/colors'
import useSectionVisible from 'Hooks/useSectionVisible'
import Title from '../Title'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import Section from '../Layout/Section'
import BenefitBox from './BenefitBox'

const styles = {
  text: {
    // height: '450px',
    color: colors.brownishGrey,
    fontSize: '18px',
    lineHeight: 1.78,
    letterSpacing: 0.7,
    marginTop: '78px',
  },
  item: {
    margin: '16px 0',
  },
}

const useStyles = createUseStyles(styles)

const enhance = compose(
  withRouter,
  connect(({ benefits: { list: benefits, fetching } }) => ({
    benefits: sortBy(benefits, 'position'),
    fetching,
  })),
  branch(({ fetching }) => fetching, renderComponent(LoadingIndicator)),
  branch(({ benefits }) => benefits.length === 0, renderNothing),
  withState('selected', 'setSelected', ({ benefits }) =>
    get(benefits, '[0].id'),
  ),
)

const BenefitSection = ({ benefits, selected, setSelected }) => {
  const classes = useStyles()
  const visible = useSectionVisible('benefits')
  if (!visible) {
    return null
  }

  return (
    <Section title={<Title i18nKey="benefits" />}>
      <div className="grid-x grid-padding-x small-up-1 medium-up-3 large-up-3 align-justify">
        {benefits.map(({ id, title, icon }) => (
          <div
            key={id}
            className={classnames(
              'cell',
              'shrink',
              'grid-x',
              'align-center',
              classes.item,
            )}
          >
            <BenefitBox
              text={title}
              icon={icon}
              inverted={id === selected}
              onClick={() => setSelected(id)}
            />
          </div>
        ))}
      </div>
      <div className="grid-x grid-padding-x">
        <div
          className={classnames('cell', classes.text)}
          dangerouslySetInnerHTML={{
            __html: benefits.find(b => b.id === selected).text,
          }}
        />
      </div>
    </Section>
  )
}

BenefitSection.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}

export default enhance(BenefitSection)
