import sendIcon from 'Assets/images/send-icon.png'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { LoadingIndicator } from '../../Layout/LoadingIndicator'

const styles = theme => ({
  sendButtonContainer: {
    '@media (min-width: 1020px)': {
      margin: '4px 0',
      paddingLeft: '2px !important',
    },
    '@media (max-width: 1020px)': {
      margin: [24, 4, 0],
    },
  },
  sendButton: {
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    backgroundColor: theme.darkerFirstColor,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '16px',
    cursor: 'pointer',
  },
  status: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  iconContainer: {
    '@media (max-width: 1020px)': {
      display: 'none',
    },
  },
})

const useStyles = createUseStyles(styles)

const SendButton = ({ sending, disabled }) => {
  const classes = useStyles()
  const { t } = useTranslation('contact')

  return (
    <div className={classnames('cell', 'auto', classes.sendButtonContainer)}>
      <button
        type="submit"
        className={classes.sendButton}
        disabled={disabled || sending}
      >
        <div className={classes.iconContainer}>
          {sending ? (
            <LoadingIndicator />
          ) : (
            <img src={sendIcon} alt={t('submit')} />
          )}
        </div>

        <div className={classes.status}>
          {sending ? t('submitting') : t('submit')}
        </div>
      </button>
    </div>
  )
}

SendButton.propTypes = {
  sending: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default SendButton
