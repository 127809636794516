import React from 'react'
import { useSelector } from 'react-redux'
import Section from '../Layout/Section'
import CheckoutForm from './CheckoutForm/CheckoutForm'

const CheckoutFormSection = () => {
  const { token, newCustomer } = useSelector(state => state.auth)

  if (!(newCustomer || token)) {
    return null
  }

  return (
    <Section padding="40" paddingTop="20">
      <CheckoutForm />
    </Section>
  )
}

export default CheckoutFormSection
