/* eslint-disable react/style-prop-object */
import AlsoCartPrintAccessory from 'Components/Also/Cart/Print/Accessory'
import PropTypes from 'prop-types'
import React from 'react'

const AlsoCartPrintAccessories = ({ accessories, term, quantity }) =>
  accessories.map(([field, config]) => (
    <AlsoCartPrintAccessory
      field={field}
      config={config}
      term={term}
      workplaceQuantity={quantity}
    />
  ))

AlsoCartPrintAccessories.propTypes = {
  accessories: PropTypes.array.isRequired,
  term: PropTypes.number,
  quantity: PropTypes.number.isRequired,
}

export default AlsoCartPrintAccessories
