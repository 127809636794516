import * as Sentry from '@sentry/browser'
import { DateTime } from 'luxon'
import { applyMiddleware, compose, createStore } from 'redux'
import { createTransform, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import createSagaMiddleware from 'redux-saga'
import get from 'lodash/get'
import './Assets/css/foundation.css'
import './index.css'
import i18nMiddleWare from 'Lib/i18nMiddleware'
import './polyfill'
import fetchSaga from 'Sagas/fetchSaga'
import updateProductConfigurationPriceSaga from 'Sagas/productConfigurationSagas'
import reducers from './reducer'
import crashReporter from './crashReporter'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line no-underscore-dangle

const expireTransform = createTransform(
  inboundState => inboundState,
  outboundState => {
    if (
      outboundState.expiresAt &&
      DateTime.fromISO(outboundState.expiresAt) < DateTime.local()
    ) {
      return {}
    }

    return outboundState
  },
)

const version = process.env.REACT_APP_GIT_SHA

const config = {
  key: 'root',
  storage,
  version,
  blacklist: [
    'deviceConfiguration',
    'productConfiguration',
    'auth',
    'rates',
    // 'cart',
    'config',
  ],
  transforms: [expireTransform],
  migrate: state => {
    if (process.env.NODE_ENV === 'development') {
      return Promise.resolve(state)
    }
    if (get(state, '_persist.version', '') !== version) {
      return Promise.resolve({})
    }
    return Promise.resolve(state)
  },
}
const reducer = persistCombineReducers(config, reducers)

const store = createStore(
  reducer,
  undefined,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, i18nMiddleWare, crashReporter),
  ),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

if (window.Cypress) {
  window.store = store
}

const catchErr = err => {
  Sentry.captureException(err, {
    // send to crash reporting tool
    extra: {
      state: store.getState(), // dump application state
    },
  })
}

const updateProductConfigurationPriceTask = sagaMiddleware.run(
  updateProductConfigurationPriceSaga,
)
updateProductConfigurationPriceTask.done.catch(catchErr)

const fetchTask = sagaMiddleware.run(fetchSaga)
fetchTask.done.catch(catchErr)

export default store
