import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import Link from 'Components/Common/Link'
import { Counter, StyledFontAwesomeIcon } from 'Components/Common/UI/Header'
import useStoreView from 'Hooks/useStoreView'
import { alsoTotalCartQuantity, totalCartQuantity } from 'Lib/cartHelper'
import config from 'Lib/config'
import PropTypes from 'prop-types'
import React from 'react'
import {
  HideForScreenSize,
  HideOnlyForScreenSize,
} from 'react-foundation-components/lib/visibility'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Toggle } from 'react-powerplug'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { mainMenuLinkSelector } from 'Selectors/common/linkSelectors'
import CartLink from './CartLink'
import Image from './Image'
import Section from './Section'

export const styles = theme => ({
  topBar: {
    alignItems: 'center',
    padding: '20px 0',
    fontWeight: '600',
  },
  titleBar: {
    'background-color': '#fff',
  },
  titleBarTitle: {
    background: theme.firstColor,
    position: 'absolute',
  },
  menuIcon: {
    height: '20px',
    ':hover': {},
  },
  sectionFixed: {
    '@media (max-width: 640px)': {
      position: 'fixed',
      'z-index': '99',
      top: '40px',
    },
  },
  titleBarLogo: {
    margin: '0 auto',
    width: '200px',
  },
  navigation: {
    '@media (max-width: 640px)': {
      'background-color': 'rgb(244, 244, 244)',
      padding: '1em',
      'margin-top': '-20px',
    },
  },
  shoppingCartTitle: {
    'font-size': '26px',
    position: 'absolute',
    right: '20px',
  },
  text: {
    lineHeight: '1.67',
    letterSpacing: '0.3px',
    fontSize: '15px',
  },
  link: {
    extend: 'text',
    fontSize: '19px',
    lineHeight: '1.32',
    letterSpacing: '0.4px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.lightBlack,
  },
  linkActive: {
    extend: 'link',
    color: theme.firstColor,
  },
  input: {
    width: '200px',
  },
  hiddenForSmall: {
    '@media screen and (max-width: 39.9375em)': {
      display: 'none',
    },
  },
})

const useStyles = createUseStyles(styles)

const Header = () => {
  const links = useSelector(mainMenuLinkSelector)
  const quantity = useSelector(
    config.reseller.also ? alsoTotalCartQuantity : totalCartQuantity,
  )
  const classes = useStyles()
  const { t } = useTranslation()
  const logo = useStoreView('websiteLogo')
  const allProductsLink = useStoreView('seoConfiguration.productOverviewSlug')

  return (
    <Toggle initial={false}>
      {({ on, toggle }) => (
        <Section padding="0" className={classes.sectionFixed}>
          <HideForScreenSize screenSize="medium">
            <div className={classnames('title-bar', classes.titleBar)}>
              <div
                role="button"
                tabIndex={0}
                className={classnames('title-bar-title', classes.titleBarTitle)}
                onClick={toggle}
                onKeyPress={toggle}
              >
                <span className={classnames('menu-icon', classes.menuIcon)} />
              </div>
              <div className={classnames(classes.titleBarLogo)}>
                <Link
                  link={{
                    to: '/',
                    name: <Image src={logo} alt="" width={400} height={60} />,
                  }}
                />
              </div>
              <span
                className={classnames(
                  'fa-layers fa-fw',
                  classes.shoppingCartTitle,
                )}
              >
                <StyledFontAwesomeIcon icon="shopping-cart" />

                <Counter className={classnames('fa-layers-counter')}>
                  {quantity}
                </Counter>
              </span>
            </div>
          </HideForScreenSize>

          <div
            className={classnames(
              'grid-x',
              'grid-padding-x',
              'align-justify',
              classes.topBar,
              { [classes.hiddenForSmall]: !on },
            )}
            id="topmenu"
          >
            <HideOnlyForScreenSize screenSize="small">
              <div
                className={classnames(
                  'cell small-12 medium-4 hide-for-small-only',
                )}
              >
                <Link
                  link={{
                    to: '/',
                    name: <Image src={logo} alt="" width={400} height={60} />,
                  }}
                />
              </div>
            </HideOnlyForScreenSize>
            <div className="cell auto">
              <div
                className={classnames(
                  'grid-x grid-margin-x align-right',
                  classes.navigation,
                )}
              >
                <MenuLink to="/" activeOnlyWhenExact label="Home" />
                <MenuLink to={`/${allProductsLink}`} label="Produkte" />
                {links.map(({ id, to, name, target }) => (
                  <MenuLink
                    key={id}
                    to={to}
                    target={target}
                    className={classnames(
                      'cell text-center small-12 medium-shrink',
                      classes.link,
                    )}
                    label={name}
                  />
                ))}
                <MenuLink
                  to="/account"
                  label={t('common:links.account')}
                  className={classnames(
                    'cell text-center small-12 medium-shrink',
                    classes.link,
                  )}
                />
                <CartLink />
              </div>
            </div>
          </div>
        </Section>
      )}
    </Toggle>
  )
}

export const MenuLink = ({ to, activeOnlyWhenExact, label }) => {
  const classes = useStyles()

  const match = useRouteMatch(to)

  const matched = match && (!activeOnlyWhenExact || match.isExact)

  return (
    <Link
      link={{ to, name: label }}
      className={classnames(
        'cell small-12 medium-shrink',
        matched ? classes.linkActive : classes.link,
      )}
    />
  )
}

MenuLink.propTypes = {
  to: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

MenuLink.defaultProps = {
  to: '',
  activeOnlyWhenExact: false,
}

export default Header
