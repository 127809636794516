import toArray from 'lodash/toArray'

export const PROTECTION_PLANS_FETCH_LIST_REQUEST =
  'PROTECTION_PLANS::FETCH_LIST_REQUEST'
export const PROTECTION_PLANS_FETCH_LIST_FAIL =
  'PROTECTION_PLANS::FETCH_LIST_FAIL'
export const PROTECTION_PLANS_FETCH_LIST_SUCCESS =
  'PROTECTION_PLANS::FETCH_LIST_SUCCESS'

const initialState = { list: [], fetching: false }

export const fetchProtectionPlanList = () => ({
  type: PROTECTION_PLANS_FETCH_LIST_REQUEST,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROTECTION_PLANS_FETCH_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case PROTECTION_PLANS_FETCH_LIST_SUCCESS: {
      return {
        ...state,
        list: toArray(action.data),
        fetching: false,
      }
    }
    case PROTECTION_PLANS_FETCH_LIST_FAIL: {
      return {
        ...state,
        list: [],
        fetching: false,
      }
    }
    default:
      return state
  }
}

export default reducer
