import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from '../Layout/Form/SelectField'

export const options = ['Frau', 'Herr']

const SalutationSelect = props => {
  const { t } = useTranslation('checkout')

  return (
    <SelectField
      {...props}
      placeholder={t('form.salutation')}
      options={options.map(option => ({
        value: option,
        label: option,
      }))}
    />
  )
}

export default SalutationSelect
