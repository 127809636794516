import React, { useMemo } from 'react'
import head from 'lodash/head'
import Select from 'Components/Common/Layout/Select'

const options = (variants, term) =>
  variants.map(v => ({
    value: v.id,
    label: (
      <div css="margin-right: 10px">
        <div>{v.name}</div>
      </div>
    ),
    disabled: v.monthlyPrices && v.monthlyPrices[term] === 0,
  }))

const DeviceVariantSelect = ({ selected, variants, term, setVariant }) => {
  const variantsAsOptions = useMemo(() => options(variants, term))
  const selectedObject = variantsAsOptions.find(o => o.id === selected)
  return (
    <Select
      className="r-select"
      classNamePrefix="r-select"
      defaultValue={head(variantsAsOptions)}
      options={variantsAsOptions}
      onChange={({ value }) => setVariant(value)}
      isSearchable={false}
      value={selectedObject}
    />
  )
}

export default DeviceVariantSelect
