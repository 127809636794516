import produce from 'immer'
import update from 'immutability-helper'
import { DateTime } from 'luxon'

export const CART_ADD_DEVICE_REQUEST = 'CART::ADD_DEVICE_REQUEST'
export const CART_ADD_DEVICE = 'CART::ADD_DEVICE'
export const CART_REMOVE_DEVICE = 'CART::REMOVE_DEVICE'
export const CART_INCREMENT_DEVICE_QUANTITY = 'CART::INCREMENT_DEVICE_QUANTITY'
export const CART_DECREMENT_DEVICE_QUANTITY = 'CART::DECREMENT_DEVICE_QUANTITY'
export const CART_ADD_PRODUCT_REQUEST = 'CART::ADD_PRODUCT_REQUEST'
export const CART_ADD_PRODUCT = 'CART::ADD_PRODUCT'
export const CART_REMOVE_PRODUCT = 'CART::REMOVE_PRODUCT'
export const CART_INCREMENT_PRODUCT_QUANTITY =
  'CART::INCREMENT_PRODUCT_QUANTITY'
export const CART_DECREMENT_PRODUCT_QUANTITY =
  'CART::DECREMENT_PRODUCT_QUANTITY'
export const CART_SET_PERIOD = 'CART::SET_PERIOD'
export const CART_CLEAR = 'CART::CLEAR'
export const CART_UPDATE_DEVICE_ACCESSORY_QUANTITY =
  'CART::UPDATE_DEVICE_ACCESSORY_QUANTITY'

export const clearCart = () => ({
  type: CART_CLEAR,
})

export const requestAddDeviceToCart = () => ({
  type: CART_ADD_DEVICE_REQUEST,
})

export const requestAddProductToCart = () => ({
  type: CART_ADD_PRODUCT_REQUEST,
})

export const addDeviceToCart = device => ({
  type: CART_ADD_DEVICE,
  device,
})

export const incrementDeviceQuantity = index => ({
  type: CART_INCREMENT_DEVICE_QUANTITY,
  index,
})

export const decrementDeviceQuantity = index => ({
  type: CART_DECREMENT_DEVICE_QUANTITY,
  index,
})

export const removeDeviceFromCart = index => ({
  type: CART_REMOVE_DEVICE,
  index,
})

export const updateDeviceAccessoryQuantity = ({
  index,
  variant,
  quantity,
}) => ({
  type: CART_UPDATE_DEVICE_ACCESSORY_QUANTITY,
  payload: {
    index,
    variant,
    quantity,
  },
})

export const addProductToCart = product => ({
  type: CART_ADD_PRODUCT,
  product,
})

export const incrementProductQuantity = index => ({
  type: CART_INCREMENT_PRODUCT_QUANTITY,
  index,
})

export const decrementProductQuantity = index => ({
  type: CART_DECREMENT_PRODUCT_QUANTITY,
  index,
})

export const removeProductFromCart = index => ({
  type: CART_REMOVE_PRODUCT,
  index,
})

export const setPeriod = period => ({
  type: CART_SET_PERIOD,
  period,
})

// eslint-disable-next-line no-unused-vars
export const initialState = {
  period: 36,
  devices: [],
  products: [],
  expiresAt: DateTime.local()
    .endOf('day')
    .toISO(),
}

const getInitialState = () =>
  // localStorage.getItem('cart')
  //   ? JSON.parse(localStorage.getItem('cart'))
  //   :
  initialState

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case CART_CLEAR: {
      return getInitialState()
    }
    case CART_ADD_DEVICE: {
      return update(state, {
        devices: { $push: [action.device] },
      })
    }
    case CART_INCREMENT_DEVICE_QUANTITY: {
      const newQuantity = state.devices[action.index].quantity + 1
      return update(state, {
        devices: {
          [action.index]: {
            quantity: { $set: newQuantity },
          },
        },
      })
    }
    case CART_DECREMENT_DEVICE_QUANTITY: {
      const newQuantity = state.devices[action.index].quantity - 1
      if (newQuantity > 0) {
        return update(state, {
          devices: {
            [action.index]: {
              quantity: { $set: newQuantity },
            },
          },
        })
      }
      return state
    }
    case CART_REMOVE_DEVICE: {
      return update(state, {
        devices: {
          $splice: [[action.index, 1]],
        },
      })
    }
    case CART_UPDATE_DEVICE_ACCESSORY_QUANTITY: {
      return produce(state, draft => {
        const deviceConfiguration = draft.devices[action.payload.index]
        const accessory = deviceConfiguration.accessories.find(
          acc => acc.variant === action.payload.variant,
        )
        accessory.quantity = action.payload.quantity
      })
    }

    case CART_ADD_PRODUCT: {
      const idx = state.products.findIndex(
        ({ product, productVariant }) =>
          product === action.product.product &&
          productVariant === action.product.productVariant,
      )
      if (idx > -1) {
        const newQuantity =
          state.products[idx].quantity + action.product.quantity
        return update(state, {
          products: { [idx]: { quantity: { $set: newQuantity } } },
        })
      }
      return update(state, {
        products: { $push: [action.product] },
      })
    }
    case CART_INCREMENT_PRODUCT_QUANTITY: {
      const newQuantity = state.products[action.index].quantity + 1
      return update(state, {
        products: {
          [action.index]: {
            quantity: { $set: newQuantity },
          },
        },
      })
    }
    case CART_DECREMENT_PRODUCT_QUANTITY: {
      const newQuantity = state.products[action.index].quantity - 1
      if (newQuantity > 0) {
        return update(state, {
          products: {
            [action.index]: {
              quantity: { $set: newQuantity },
            },
          },
        })
      }
      return state
    }
    case CART_REMOVE_PRODUCT: {
      return update(state, {
        products: {
          $splice: [[action.index, 1]],
        },
      })
    }
    case CART_SET_PERIOD: {
      return {
        ...state,
        period: action.period,
      }
    }
    default:
      return state
  }
}

export default reducer
