import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const style = theme => ({
  highlighted: {
    color: theme.firstColor,
    marginRight: '12px',
    display: 'inline-block',
  },
})

const useStyles = createUseStyles(style)

const Title = ({ highlighted, normal }) => {
  const classes = useStyles()

  return (
    <div>
      <span className={classes.highlighted}>{highlighted}</span>
      {normal}
    </div>
  )
}

Title.propTypes = {
  highlighted: PropTypes.string,
  normal: PropTypes.string,
}

export default Title
