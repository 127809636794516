import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const PasswordResetSuccess = () => {
  const { t } = useTranslation('auth')
  return (
    <div>
      <p>{t('password_reset_success')}</p>
      <p>
        Sie können sich nun <Link to="/account/login">einloggen</Link>.
      </p>
    </div>
  )
}

export default PasswordResetSuccess
