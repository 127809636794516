import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { electronicFormatIBAN, friendlyFormatIBAN } from 'ibantools'
import { renderField } from '../Layout/Form/FormField'
import {
  FieldGroup,
  FieldGroupHeader,
  FieldgroupComment,
} from './StyledComponents'

const BankDetailsForm = ({ source, note }) => {
  const { t } = useTranslation('checkout')
  return (
    <FieldGroup className={classnames('grid-x', 'grid-padding-x')}>
      <div className="cell small-12">
        <FieldGroupHeader>{t('form.paymentData')}</FieldGroupHeader>
      </div>
      {note && (
        <div className="cell small-12">
          <FieldgroupComment>{note}</FieldgroupComment>
        </div>
      )}
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          format={value => (value ? friendlyFormatIBAN(value) : '')}
          parse={value => (value ? electronicFormatIBAN(value) : null)}
          component={renderField}
          label={t('form.IBAN')}
          name={source ? `${source}.IBAN` : 'IBAN'}
          id={source ? `${source}.IBAN` : 'IBAN'}
        />
      </div>
    </FieldGroup>
  )
}

BankDetailsForm.propTypes = {
  source: PropTypes.string,
  note: PropTypes.string,
}

BankDetailsForm.defaultProps = {
  source: null,
  note: null,
}

export default BankDetailsForm
