import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useStoreView from 'Hooks/useStoreView'

const AllProductsLink = ({ text }) => {
  const { t } = useTranslation()
  const slug = useStoreView('seoConfiguration.productOverviewSlug')
  return <Link to={`/${slug}`}>{t(text)}</Link>
}

AllProductsLink.propTypes = {
  text: PropTypes.string.isRequired,
}

export default AllProductsLink
