import colors from 'Assets/styles/colors'

export default theme => ({
  forgotPasswordLink: {
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '1.39',
    letterSpacing: '0.4px',
    color: colors.brownisGreyThree,
  },
  authError: {
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '1.39',
    letterSpacing: '0.4px',
    color: theme.errorColor,
  },
})
