import React from 'react'
import { animated, useTransition } from 'react-spring'
import placeholder from 'Assets/images/placeholders/600x600.png'
import Image from 'Components/Common/Layout/Image'

const AnimatedImg = animated(Image)

const AnimatedImage = ({ image, alt }) => {
  const transitions = useTransition(image, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(({ item, key, props }) => (
    <AnimatedImg
      key={key}
      style={{
        position: 'absolute',
        ...props,
      }}
      src={item || placeholder}
      alt={alt}
      width={600}
      height={600}
    />
  ))
}

export default AnimatedImage
