import {
  changeTerm,
  decrementWorkplaceQuantity,
  incrementWorkplaceQuantity,
  removeWorkplaceFromCart,
} from 'Ducks/also/alsoCart'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { workplacePriceSelector } from 'Selectors/also/priceSelectors'
import Line from './Line'
import Workplace from './Workplace'

const DeviceLine = ({ workplace, index }) => {
  const price = useSelector(state =>
    workplacePriceSelector(state, {
      values: workplace,
    }),
  )

  const dispatch = useDispatch()

  const workplaceUserDevice = get(workplace, 'WorkplaceUserDevice.variant')

  return (
    <Line
      quantity={workplace.quantity}
      increment={() => dispatch(incrementWorkplaceQuantity(index))}
      decrement={() => dispatch(decrementWorkplaceQuantity(index))}
      remove={() => dispatch(removeWorkplaceFromCart(index))}
      price={price}
      changeTerm={term => dispatch(changeTerm(index, term))}
      term={workplace.term}
      workplaceUserDevice={workplaceUserDevice}
    >
      <Workplace workplace={workplace} index={index} />
    </Line>
  )
}

DeviceLine.propTypes = {
  workplace: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    variant: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

export default DeviceLine
