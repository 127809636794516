import classnames from 'classnames'
import deepFilter from 'deep-filter'
import { getIn } from 'final-form'
import createDecorator from 'final-form-focus'
import { unflatten } from 'flat'
import forEach from 'lodash/map'
import get from 'lodash/get'
import set from 'lodash/set'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import useStoreViewNotes from 'Hooks/useStoreViewNotes'
import createCalculateDecorator from 'final-form-calculate'
import { clearCart } from 'Ducks/common/cart'
import { fetchCustomerData, postOrder } from 'Lib/fetch'
import history from 'Lib/history'
import SubmitButton from 'Components/Common/Button/SubmitButton'
import { totalCreditableSelector } from 'Selectors/common/priceSelectors'
import {
  renderCheckbox,
  renderField,
  renderTextArea,
} from 'Components/Common/Layout/Form/FormField'
import AcceptTermsCheckBox from 'Components/Common/Checkout/AcceptTermsCheckBox'
import AddressForm from 'Components/Common/Checkout/AddressForm'
import BankDetailsForm from 'Components/Common/Checkout/BankDetailsForm'
import CeoForm from 'Components/Common/Checkout/CeoForm'
import validate from 'Components/Common/Checkout/CheckoutValidation'
import PrivacyStatement from 'Components/Common/Checkout/PrivacyStatement'
import styles from 'Components/Common/Checkout/styles'
import Condition from 'Components/Common/Checkout/CheckoutForm/Condition'
import { DateTime } from 'luxon'

const decorator = createCalculateDecorator({
  field: 'billingAddress.email',
  updates: {
    'customer.email': email => email,
  },
})

const useStyles = createUseStyles(styles)

const createCartForForm = state => {
  const { devices, period } = state.cart

  const deviceLines = []
  const productLines = []

  // const productLines = products.map(({ productVariant, quantity }) => ({
  //   product: productVariant,
  //   quantity,
  // }))

  devices.forEach(deviceLine => {
    const {
      services,
      accessories,
      variant,
      protectionPlanType,
      quantity,
      serviceExtension,
      bundle,
    } = deviceLine

    deviceLines.push({
      device: variant,
      quantity,
      protectionPlan: protectionPlanType,
      services: Object.values(services || {}),
      accessories: accessories.map(
        ({ variant, quantity: accessoryQuantity }) => ({
          product: variant,
          quantity: quantity * accessoryQuantity,
        }),
      ),
      ...(serviceExtension !== null && { serviceExtension }),
      ...(bundle && { bundle: bundle.bundle.id }),
    })
  })

  return {
    period,
    deviceLines,
    productLines,
  }
}

const getFormInputs = name => () => {
  if (typeof document === 'undefined') {
    return []
  }
  const form = document.forms[name]
  const inputs = form && form.length ? Array.prototype.slice.call(form) : [] // cast cheat to get from HTMLFormElement children to FocusableInput
  const selectFields = Array.prototype.slice.call(
    document.querySelectorAll('[data-select]'),
  )

  const allInputs = inputs.concat(selectFields)

  return allInputs
}

const findInput = (inputs, errors) =>
  inputs.find(input => {
    const name = input.name || input.dataset.name
    return name && getIn(errors, name)
  })

const focusOnErrors = createDecorator(getFormInputs('checkout'), findInput)

const copyToCeo = (form, values) => {
  const ba = values.billingAddress
  if (ba == null) return
  const toCopy = {
    salutation: ba.salutation,
    firstName: ba.firstName,
    lastName: ba.lastName,
    street: ba.street,
    postcode: ba.postcode,
    city: ba.city,
    phone: ba.phone,
  }
  forEach(toCopy, (value, key) => {
    form.change(`ceo[${key}]`, value)
  })
}

// eslint-disable-next-line
const initialValuesDev = {
  billingAddress: {
    company: 'Test Firma',
    salutation: 'Herr',
    firstName: 'Nik',
    lastName: 'Lampster',
    street: 'Teststraße',
    streetNumber: '123',
    postcode: '69115',
    city: 'Heidelberg',
    phone: '+49151 1234567',
    email: 'nl@aluxo.de',
  },
  ceo: {
    salutation: 'Herr',
    firstName: 'Nik',
    lastName: 'Lampster',
    street: 'Teststraße',
    streetNumber: '123',
    postcode: '69115',
    city: 'Heidelberg',
    birthdate: '15.03.1997',
    phone: '+49151 1234567',
    fax: '+49151 1234567',
  },
  IBAN: 'DE77268500014988461663',
  shipToBilling: true,
  password: '123',
  terms: true,
  privacy: true,
  // customer: {
  //   email: 'nl@aluxo.de',
  //   password: '123',
  // },
}

const CheckoutForm = () => {
  const classes = useStyles()
  const { initialValues, authenticated, token, lang } = useSelector(state => {
    const {
      auth: { token, newCustomer },
      config: { lang },
    } = state
    return {
      initialValues: {
        shipToBilling: true,
        createAccount: newCustomer,
        ...createCartForForm(state),
        cartValue: totalCreditableSelector(state),
      },
      authenticated: token !== null,
      token,
      lang,
    }
  })

  const dispatch = useDispatch()

  const { t } = useTranslation('checkout')

  const [billingAddress, setBillingAddress] = useState(null)
  const [shippingAddress, setShippingAddress] = useState(null)
  const [bankDetails, setBankDetails] = useState(null)
  const [ceo, setCeo] = useState(null)

  const onSubmit = async values => {
    const filteredValues = deepFilter(values, (value, prop, subject) => {
      if (['@id', 'id', '@type'].includes(prop)) {
        return false
      }
      if (subject.shipToBilling && prop === 'shippingAddress') {
        return false
      }
      return true
    })

    try {
      const {
        data: { orderNumber },
      } = await postOrder({ values: filteredValues, token }, lang)
      dispatch(clearCart())
      history.push('/bestellbestaetigung', { orderNumber })
    } catch (e) {
      if (e.response.status === 400) {
        // TODO: this might fail
        const flattened = e.response.data.violations.reduce(
          (prev, v) => ({
            ...prev,
            [v.propertyPath]: v.message,
          }),
          {},
        )
        const vioerrors = unflatten(flattened)
        return vioerrors
      }
    }
    return false
  }

  const fetchData = async () => {
    try {
      const {
        data: { addresses, bankDetails, ceo },
      } = await fetchCustomerData()
      const billingAddress = addresses.find(({ type }) => type === 'billing')
      const shippingAddress = addresses.find(({ type }) => type === 'shipping')

      setBillingAddress(billingAddress)
      setShippingAddress(shippingAddress)
      setBankDetails(bankDetails)

      if (get(ceo, 'birthdate')) {
        const ceoBirthdate = DateTime.fromISO(ceo.birthdate)
        if (ceoBirthdate && ceoBirthdate.isValid) {
          set(ceo, 'birthdate', ceoBirthdate.toFormat('dd.MM.yyyy'))
        }
      }
      setCeo(ceo)
    } catch (error) {
      console.log(error) // eslint-disable-line
      // if (error.response.status === 401 || error.response.status === 403) {
      //   this.props.history.push('/account/login')
      // }
    }
  }

  useEffect(() => {
    if (authenticated) {
      fetchData()
    }
  }, [authenticated])

  const notes = useStoreViewNotes()

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors, decorator]}
      initialValues={{
        ...initialValues,
        billingAddress,
        shippingAddress,
        ...bankDetails,
        ceo,
      }}
      validate={validate}
      render={({ handleSubmit, submitting, values, form }) => (
        <form
          name="checkout"
          onSubmit={handleSubmit}
          className={classnames('checkout', 'small-guter-form')}
        >
          <div className={classes.fieldSection}>
            <AddressForm
              source="billingAddress"
              note={notes['checkout.billingAddress']}
            />
            <div
              className={classnames(
                'grid-x',
                'grid-padding-x',
                classes.fieldGroup,
              )}
            >
              <div className="cell medium-12">
                <Field
                  className="input-checkbox"
                  component={renderCheckbox}
                  labelClassName={classes.primaryColorLabel}
                  name="shipToBilling"
                  id="shipToBilling"
                  type="checkbox"
                  label={t('form.shipToBilling')}
                />
                <Field name="cartValue" subscription={{ value: true }}>
                  {({ input: { value } }) =>
                    value <= 2900 ? (
                      <button
                        className={classnames(
                          classes.primaryColorLabel,
                          classes.copyToCeoButton,
                        )}
                        id="copyToCeo"
                        type="button"
                        label={t('form.copyToCeo')}
                        onClick={() => copyToCeo(form, values)}
                      >
                        {t('form.copyToCeo')}
                      </button>
                    ) : null
                  }
                </Field>
              </div>
            </div>
          </div>

          <Condition when="shipToBilling" is={false}>
            <div className={classes.fieldSection}>
              <AddressForm
                source="shippingAddress"
                note={notes['checkout.shippingAddress']}
              />
            </div>
          </Condition>
          <Field name="cartValue" subscription={{ value: true }}>
            {({ input: { value } }) =>
              value <= 2900 ? (
                <div className={classes.fieldSection}>
                  <CeoForm note={notes['checkout.ceo']} />
                </div>
              ) : null
            }
          </Field>

          <div className={classes.fieldSection}>
            <BankDetailsForm note={notes['checkout.bankDetails']} />
          </div>
          <div className={classes.fieldSection}>
            <div
              className={classnames(
                'grid-x',
                'grid-padding-x',
                classes.fieldGroup,
              )}
            >
              <div className="cell small-12">
                <div className={classes.fieldGroupHeader}>
                  {t('form.comment')}
                </div>
              </div>
              {notes['checkout.comment'] && (
                <div className="cell small-12">
                  <div className={classes.fieldGroupComment}>
                    {notes['checkout.comment']}
                  </div>
                </div>
              )}
              <div className="cell small-12">
                <Field
                  type="text"
                  expand
                  component={renderTextArea}
                  label={t('form.comment')}
                  name="comment"
                  id="comment"
                />
              </div>
            </div>
          </div>
          <Field name="createAccount" subscription={{ value: true }}>
            {({ input: { value } }) =>
              value ? (
                <div className={classes.fieldSection}>
                  <div
                    className={classnames(
                      'grid-x',
                      'grid-padding-x',
                      classes.fieldGroup,
                    )}
                  >
                    <div className="cell small-12">
                      <div className={classes.fieldGroupHeader}>
                        {t('form.account')}
                      </div>
                    </div>
                    {notes['checkout.account'] && (
                      <div className="cell small-12">
                        <div className={classes.fieldGroupComment}>
                          {notes['checkout.account']}
                        </div>
                      </div>
                    )}
                    <div className="cell medium-4">
                      <Field
                        type="text"
                        expand
                        component={renderField}
                        label={t('form.email')}
                        disabled
                        name="customer.email"
                      />
                    </div>
                    <div className="cell medium-4">
                      <Field
                        type="password"
                        expand
                        component={renderField}
                        label={t('form.password')}
                        name="customer.password"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            }
          </Field>
          <div className={classes.fieldSection}>
            <div
              className={classnames(
                'grid-x',
                'grid-padding-x',
                classes.fieldGroup,
              )}
            >
              <AcceptTermsCheckBox />

              <PrivacyStatement className={classes.text} />
            </div>
          </div>

          <SubmitButton
            customButtonClasses="btn-submit-create-order"
            height={64}
            style={{ marginTop: '24px' }}
            fetching={submitting}
            disabled={submitting}
          >
            {t('form.submit')}
          </SubmitButton>
        </form>
      )}
    />
  )
}

export default CheckoutForm
