import React, { useState, useEffect } from 'react'
import { FORM_ERROR } from 'final-form'
import { Form } from 'react-final-form'
import ReactTimeout from 'react-timeout'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { fetchCustomerData, updateCustomerData } from 'Lib/fetch'
import history from 'Lib/history'
import AddressForm from '../Checkout/AddressForm'
import Section from '../Layout/Section'
import SubmitButton from '../Button/SubmitButton'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import { isAddressValid } from '../Checkout/CheckoutValidation'

const validate = values => {
  const errors = { addresses: [] }

  values.addresses.forEach(address => {
    const addressErrors = isAddressValid(address)
    if (!isEmpty(addressErrors)) {
      errors.addresses.push(addressErrors)
    } else {
      errors.addresses.push(undefined)
    }
  })

  if (
    !errors.addresses.reduce(
      (prev, address) => prev || address !== undefined,
      false,
    )
  ) {
    delete errors.addresses
  }

  return errors
}

const Addresses = ({ setTimeout }) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [customerData, setCustomerData] = useState({
    addresses: [],
  })

  const fetchData = async () => {
    try {
      const res = await fetchCustomerData()
      setCustomerData(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.push('/account/login')
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const toggleSuccessMessage = () => {
    setSuccess(!success)
  }

  const onSubmit = async values => {
    try {
      await updateCustomerData(values)
      toggleSuccessMessage()
      setTimeout(toggleSuccessMessage, 1000)
    } catch (error) {
      return { [FORM_ERROR]: t('feedback.genericError') }
    }
  }

  if (customerData.addresses.length < 1) {
    return (
      <Section>
        <LoadingIndicator />
      </Section>
    )
  }

  const billingIdx = customerData.addresses.findIndex(
    ({ type }) => type === 'billing',
  )
  const shippingIdx = customerData.addresses.findIndex(
    ({ type }) => type === 'shipping',
  )

  return (
    <div className="grid-x grid-padding-x">
      <div className="cell small-12">
        <Form
          onSubmit={onSubmit}
          initialValues={customerData}
          validate={validate}
          render={({ handleSubmit, submitError, submitting }) => (
            <form
              onSubmit={handleSubmit}
              className={classnames('checkout', 'small-guter-form')}
              name="checkout"
            >
              <AddressForm
                source={`addresses[${billingIdx}]`}
                label="billingAddress"
              />
              <AddressForm
                source={`addresses[${shippingIdx}]`}
                label="shippingAddress"
              />
              <SubmitButton
                style={{ marginTop: '1em' }}
                height={59}
                fetching={submitting}
                disabled={submitting || success}
              >
                {success
                  ? t('common:feedback.saved')
                  : t('common:actions.save')}
              </SubmitButton>
              {submitError && (
                <div>
                  <span className="authError">{submitError}</span>
                </div>
              )}
            </form>
          )}
        />
      </div>
    </div>
  )
}

const enhance = compose(withRouter, ReactTimeout)

export default enhance(Addresses)
