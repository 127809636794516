import { useFavicon } from 'react-use'
import { url, apikey } from 'Lib/fetch'

const Favicon = () => {
  useFavicon(`${url}/api/favicon/${apikey}.ico`)

  return null
}

export default Favicon
