import colors from 'Assets/styles/colors'
import { requestAddProductToCart } from 'Ducks/common/cart'
import {
  decreaseQuantity,
  increaseQuantity,
  setAttribute,
  setPeriod,
} from 'Ducks/common/productConfiguration'
import history from 'Lib/history'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { compose } from 'recompose'
import { productConfigurationMonthlyRateSelector } from 'Selectors/common/priceSelectors'
import PriceButton from '../Button/PriceButton'
import { ProductImage } from '../Layout/Image'
import typography from '../Layout/typography'
import PeriodSelect from '../PeriodSelect'
import ListItem from './ListItem'
import './style.css'

const styles = {
  container: {
    ...typography.text,
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    border: {
      width: 1,
      style: 'solid',
      color: colors.whiteFive,
    },
    width: '100%',
    padding: [40, 64],
    margin: [16, 0],
  },
  image: {
    width: '100%',
  },
  detailsHeader: {
    font: {
      size: '18px',
      weight: 'bold',
    },
    lineHeight: 1.39,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    color: colors.greyishBrownFour,
  },
  variantsContainer: {
    height: 64,
  },
  accessoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const mapStateToProps = state => {
  const { productConfiguration, cart } = state
  return {
    productConfiguration,
    cart,
    price: productConfigurationMonthlyRateSelector(state, {
      productConfiguration,
    }),
  }
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    setPeriod,
    increaseQuantity,
    decreaseQuantity,
    setAttribute,
    requestAddProductToCart,
  }),
)

const useStyles = createUseStyles(styles)

const ProductDetails = ({
  product,
  product: { imageEntity, name, shortDescription },
  productConfiguration: {
    attributeSets,
    selectedAttributes,
    quantity,
    period,
    productVariant,
  },
  setPeriod,
  setAttribute,
  increaseQuantity,
  decreaseQuantity,
  requestAddProductToCart,
  price,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('catalog')

  return (
    <div className="grid-x grid-padding-x">
      <div className="cell large-8">
        <ProductImage
          className={classes.image}
          iamgeEntity={imageEntity}
          alt={name}
          width={600}
          height={600}
        />
      </div>
      <div className="cell large-4">
        <ListItem header={t('description')}>
          <div>{shortDescription}</div>
        </ListItem>
        {attributeSets.map(set => (
          <ListItem key={set.attribute} header={set.attribute}>
            <Select
              placeholder={t('selectPlaceholder')}
              value={
                selectedAttributes.find(
                  ({ attribute }) => attribute === set.attribute,
                ).value
              }
              options={set.values.map(({ value, disabled }) => ({
                label: value,
                value,
                disabled,
              }))}
              onChange={value =>
                setAttribute(set.attribute, value ? value.value : null)
              }
            />
          </ListItem>
        ))}
        <ListItem header={t('rentalPeriod')}>
          <PeriodSelect period={period} onChange={setPeriod} />
        </ListItem>
        <ListItem>
          {productVariant ? (
            <PriceButton
              onClick={() => {
                requestAddProductToCart(
                  product.id,
                  productVariant,
                  quantity,
                  period,
                )
                history.push('/warenkorb')
              }}
              onIncreaseClick={increaseQuantity}
              onDecreaseClick={decreaseQuantity}
              height={64}
              quantity={quantity}
              price={price}
              exact
            >
              {t('addNow')}
            </PriceButton>
          ) : (
            <div>Bitte wählen Sie zunächst eine Ausführung</div>
          )}
        </ListItem>
      </div>
    </div>
  )
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
  }).isRequired,
  productConfiguration: PropTypes.shape({
    attributeSets: PropTypes.array.isRequired,
    selectedAttributes: PropTypes.array.isRequired,
    quantity: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    productVariant: PropTypes.string.isRequired,
  }).isRequired,
  setPeriod: PropTypes.func.isRequired,
  setAttribute: PropTypes.func.isRequired,
  increaseQuantity: PropTypes.func.isRequired,
  decreaseQuantity: PropTypes.func.isRequired,
  requestAddProductToCart: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
}

export default enhance(ProductDetails)
