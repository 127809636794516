import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'

export const linkSelector = area =>
  createSelector(
    ({ links: { list } }) =>
      list.filter(link => link.area === area && link.active),
    links => sortBy(links, 'position'),
  )

export const headerLinkSelector = linkSelector('header')
export const mainMenuLinkSelector = linkSelector('mainmenu')
export const footerLinkSelector = linkSelector('footer')
export const footerBarLinkSelector = linkSelector('footerBar')

export const specialLinkSelector = type =>
  createSelector(
    ({ links: { list } }) => list.find(link => link.specialType === type),
    link => link,
  )
