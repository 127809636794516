import PropTypes from 'prop-types'

const defaultProps = {
  '@id': PropTypes.string.isRequired,
  '@type': PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export const ProductImageShape = {
  ...defaultProps,
  image: PropTypes.string,
  thumbnail: PropTypes.string,
  cartThumbnail: PropTypes.string,
}

export const ProductImageType = PropTypes.shape(ProductImageShape)

export const ProductShape = {
  ...defaultProps,
  images: PropTypes.arrayOf(ProductImageType).isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export const ProductType = PropTypes.shape(ProductShape)

export const ProductVariantShape = {
  ...defaultProps,
  name: PropTypes.string.isRequired,
  monthlyPrices: PropTypes.shape({
    18: PropTypes.number.isRequired,
    24: PropTypes.number.isRequired,
    36: PropTypes.number.isRequired,
    48: PropTypes.number.isRequired,
  }).isRequired,
}

export const ProductVariantType = PropTypes.shape(ProductVariantShape)

export const FieldShape = {
  DisplayName: PropTypes.string.isRequired,
  DisplayTemplate: PropTypes.string.isRequired,
  DisplayType: PropTypes.string.isRequired,
  Hint: PropTypes.string.isRequired,
  IsList: PropTypes.bool.isRequired,
  IsUnique: PropTypes.bool.isRequired,
  Name: PropTypes.string.isRequired,
  Type: PropTypes.string.isRequired,
  IsPartialValidation: PropTypes.bool.isRequired,
  DefaultValue: PropTypes.string,
}

export const FieldType = PropTypes.shape(FieldShape)
