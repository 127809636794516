import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import 'flag-icon-css/css/flag-icon.css'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { useSpring, animated, config } from 'react-spring'
import { setLanguage } from 'Ducks/common/config'

const Container = styled.div`
  background: rgb(244, 244, 244);
`

const Button = styled.button`
  margin: 0 8px;
  height: 32px;
  width: 40px;
  padding: 8px;
  border: ${props => props.selected && '1px solid black'};
`

const ButtonContainer = styled.div`
  margin-top: 4px;
  position: fixed;
  z-index: 999;
  background-color: rgb(244, 244, 244);

  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > * {
    margin: 8px 8px;
  }
`

const AnimatedButtonContainer = animated(ButtonContainer)

const LanguageSwitcher = ({ language, setLanguage, locales }) => {
  const { i18n } = useTranslation()

  const [toggle, setToggle] = useState(false)

  const props = useSpring({
    height: toggle ? `${locales.length * (32 + 16)}px` : '0px',
    config: config.gentle,
  })

  if (!locales || locales.length === 0) {
    return null
  }

  const currentCountryCode = locales.find(l => l.code === language).countryCode

  return (
    <Container>
      <Button onClick={() => setToggle(!toggle)}>
        <span className={`flag-icon flag-icon-${currentCountryCode}`} />
      </Button>

      <AnimatedButtonContainer style={props}>
        {locales.map(({ code, countryCode }) => (
          <Button
            key={code}
            onClick={() => {
              setToggle(!toggle)
              i18n.changeLanguage(code)
              setLanguage(code)
            }}
            selected={language === code}
          >
            <span className={`flag-icon flag-icon-${countryCode}`} />
          </Button>
        ))}
      </AnimatedButtonContainer>
    </Container>
  )
}

LanguageSwitcher.propTypes = {
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
  locales: PropTypes.array.isRequired, // eslint-disable-line
}

export default connect(
  ({ config, resellerInfo }) => ({
    language: config.lang,
    locales: get(resellerInfo, 'data.locales'),
  }),
  {
    setLanguage,
  },
)(LanguageSwitcher)
