import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'Assets/styles/colors'
import { FORM_ERROR } from 'final-form'
import useSectionVisible from 'Hooks/useSectionVisible'
// Be sure to include styles at some point, probably during your bootstrapping
import { sendAppointmentRequest } from 'Lib/fetch'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { compose, withHandlers, withState } from 'recompose'
import Section from '../../Layout/Section'
import ContactForm from './ContactForm'

const styles = {
  text: {
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '1.78',
    letterSpacing: '0.7px',
    textAlign: 'center',
    color: colors.white,
  },
  input: {
    height: 57,
    textAlign: 'left',
    border: 0,
    paddingLeft: '4px',
    letterSpacing: '0.4px',
    lineHeight: '57px',
    color: colors.warmGreyFive,
    '&::placeholder': {
      color: colors.pinkishGrey,
    },
  },
  fieldGroup: {
    '& > *': {
      margin: [8, 0],
      '@media (min-width: 640px)': {
        padding: [0, 8],
      },
    },
  },
  sendButton: {
    '@media (max-width: 640px)': {
      marginTop: 24,
    },
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    fontSize: '36px',
  },
}

const useStyles = createUseStyles(styles)

const enhance = compose(
  withState('sent', 'setSent', false),
  withHandlers({
    handleSubmit: ({ setSent }) => async values => {
      try {
        await sendAppointmentRequest(values)
        setSent(true)
        return {}
      } catch (e) {
        if (get(e, 'response.data.message')) {
          return { [FORM_ERROR]: e.response.data.message }
        }
        return { [FORM_ERROR]: 'genericError' }
      }
    },
  }),
)

const ContactSection = ({ handleSubmit, sent }) => {
  const classes = useStyles()
  const visible = useSectionVisible('contact')
  if (!visible) {
    return null
  }

  return (
    <Section
      green
      title={
        <div>
          <span style={{ color: colors.white }}>
            Fragen? Wir sind für sie da
          </span>
        </div>
      }
      subtitle={<div className={classes.text} />}
    >
      {!sent ? (
        <ContactForm onSubmit={handleSubmit} />
      ) : (
        <div className={classes.status}>
          <FontAwesomeIcon icon="check" size="2x" />
          &nbsp;Ihre Anfrage wurde erfolgreich übermittelt
        </div>
      )}
    </Section>
  )
}

ContactSection.propTypes = {
  sent: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default enhance(ContactSection)
