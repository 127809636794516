import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router-dom'
import useStoreView from 'Hooks/useStoreView'
import history from 'Lib/history'
import {
  Arrow,
  Container,
  Icon,
  InnerContainer,
  Title,
} from 'Components/Aluxo/CategoryPage/CategoryLinkStyledComponents'

const CategoryLink = ({ name, iconString, slug }) => {
  const allProductsSlug = useStoreView('seoConfiguration.productOverviewSlug')

  return (
    <Route path="/:slug">
      {({ match }) => {
        const active = match && match.params.slug === slug
        return (
          <Container
            className={classnames('cell', 'large-auto')}
            active={active}
            role="button"
            tabIndex={0}
            onClick={() => history.push(`/${active ? allProductsSlug : slug}`)}
            onKeyPress={() =>
              history.push(`/${active ? allProductsSlug : slug}`)
            }
          >
            <InnerContainer active={active}>
              <Icon active={active}>
                {iconString && (
                  <FontAwesomeIcon icon={iconString.split(' ')} size="3x" />
                )}
              </Icon>
              <Title active={active}>{name}</Title>
            </InnerContainer>
            {active && <Arrow />}
          </Container>
        )
      }}
    </Route>
  )
}

CategoryLink.propTypes = {
  name: PropTypes.string.isRequired,
  iconString: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default CategoryLink
