/* eslint-disable react/style-prop-object */
import clsx from 'clsx'
import AlsoCartPrintAccessories from 'Components/Also/Cart/Print/Accessories'
import { useStyles } from 'Components/Also/Cart/Print/Cart'
import AlsoCartPrintServices from 'Components/Also/Cart/Print/Service'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'

const AlsoCartPrintLine = ({ workplace, workplaceNumber }) => {
  const { term, quantity, WorkplaceUserDevice, Services, ...fields } = workplace
  const classes = useStyles()
  const { variant, product } = useSelector(
    ({ alsoProductVariants, alsoProducts }) => {
      const variant = alsoProductVariants.entities[WorkplaceUserDevice.variant]

      const product = alsoProducts.entities[variant.product]

      return { variant, product }
    },
  )

  const { name } = product
  const monthlyPrice = variant.monthlyPrices[term]

  const { t } = useTranslation('cart')

  const accessories = Object.entries(fields)
  const services = Object.values(Services || {})

  return (
    <tbody>
      <tr className={classes.workplaceRow}>
        <th colSpan="5">
          {workplace.quantity === 1
            ? t('workplace', { number: workplaceNumber })
            : t('workplaces', {
                from: workplaceNumber,
                to: workplaceNumber + quantity - 1,
              })}
        </th>
      </tr>
      <tr>
        <td className={clsx(classes.textLine, classes.colorShaded)}>
          {t('catalog:alsoFieldNames.Workplace User Device')}
        </td>
        <td className={clsx(classes.text, classes.expand)}>{name}</td>
        <td className={classes.numeral}>
          <FormattedNumber
            value={monthlyPrice}
            style="currency"
            currency="EUR"
          />
        </td>
        <td className={classes.numeral}>{quantity}</td>
        <td className={clsx(classes.numeral, classes.totalsColumn)}>
          <FormattedNumber
            value={monthlyPrice * quantity}
            style="currency"
            currency="EUR"
          />
        </td>
      </tr>

      <AlsoCartPrintAccessories
        accessories={accessories}
        term={term}
        quantity={quantity}
      />

      <AlsoCartPrintServices services={services} quantity={quantity} />
    </tbody>
  )
}

AlsoCartPrintLine.propTypes = {
  workplace: PropTypes.object.isRequired,
  workplaceNumber: PropTypes.number.isRequired,
}

export default AlsoCartPrintLine
