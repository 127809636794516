import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import parseDomain from 'parse-domain'
import VatValidator from 'eu-vat-validation'
import { renderField } from 'Components/Common/Layout/Form/FormField'
import {
  FieldGroup,
  FieldGroupHeader,
  FieldgroupComment,
} from 'Components/Common/Checkout/StyledComponents'

const vatValidator = new VatValidator()

const BankDetailsForm = ({ source, note }) => {
  const { t } = useTranslation('checkout')
  return (
    <FieldGroup className={classnames('grid-x', 'grid-padding-x')}>
      <div className="cell small-12">
        <FieldGroupHeader>{t('form.additionalData')}</FieldGroupHeader>
      </div>
      {note && (
        <div className="cell small-12">
          <FieldgroupComment>{note}</FieldgroupComment>
        </div>
      )}
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          validate={value =>
            !isEmpty(value) && !parseDomain(value) && 'notValidDomain'
          }
          component={renderField}
          label={t('form.website')}
          name={source ? `${source}.domain` : 'domain'}
          id={source ? `${source}.domain` : 'domain'}
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          validate={value =>
            !isEmpty(value) && !vatValidator.validate(value) && 'notValidVat'
          }
          component={renderField}
          label={t('form.VATID')}
          name={source ? `${source}.vatId` : 'vatId'}
          id={source ? `${source}.vatId` : 'vatId'}
        />
      </div>
    </FieldGroup>
  )
}

BankDetailsForm.propTypes = {
  source: PropTypes.string,
  note: PropTypes.string,
}

BankDetailsForm.defaultProps = {
  source: null,
  note: null,
}

export default BankDetailsForm
