import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { compose, withProps } from 'recompose'

const getBaseURL = () =>
  `${window.location.protocol}//${window.location.hostname}`

const getCanonical = canonical =>
  canonical ? `${getBaseURL()}/${canonical}` : getBaseURL()

const MetaData = ({ title, description, keywords, canonical, robots }) => (
  <Helmet defaultTitle="Workplace as a Service">
    <title>{title}</title>
    {description && <meta name="description" content={description} />}
    {keywords && <meta name="keywords" content={keywords} />}
    <link rel="canonical" href={getCanonical(canonical)} />
    {robots && <meta name="robots" content={robots} />}
  </Helmet>
)

MetaData.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  canonical: PropTypes.string,
  robots: PropTypes.string,
}

MetaData.defaultProps = {
  canonical: '',
  robots: null,
  description: null,
  keywords: null,
}

const enhance = compose(
  withProps(({ metaData, title }) => ({
    ...metaData,
    title: get(metaData, 'title') || title,
  })),
)

export default enhance(MetaData)
