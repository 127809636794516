export default {
  category: '<0>Unsere</0>Produkte',
  cart: '{{text}}<0>Warenkorb</0>',
  checkout: '<0>Kasse</0>',
  account: '<0>Kundenkonto</0>',
  benefits: '<0>Die Vorteile</0>liegen auf der Hand',
  team: '<0>Unser</0>Team',
  stats: '<0>Bisher</0>haben wir schon',
  featuredProducts: '<0>Unsere</0>Angebote',
  testimonials: 'Testimonials',
  cookies: 'Cookie-Erklärung',
}
