import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { createUseStyles } from 'react-jss'
import Media from 'react-media'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { createSelector } from 'reselect'
import Specs from 'Components/Common/CategoryPage/Specs'
import { ProductImage } from 'Components/Common/Layout/Image'
import {
  findLeasingFactor,
  leasingFactorsSelector,
} from 'Selectors/common/priceSelectors'
import { BundleBanner } from 'Components/Common/Accessory'
import styles from './styles'

const useStyles = createUseStyles(styles)

const rateSelector = createSelector(
  leasingFactorsSelector,
  (_, { price }) => price,
  (leasingFactors, price) => {
    const { leasingFactor } = findLeasingFactor(36, price)(leasingFactors)

    return (price * (leasingFactor + 0.02)) / 100
  },
)

/* eslint-disable react/style-prop-object */

const Item = ({
  imageEntity,
  name,
  description,
  manufacturer,
  link,
  slug,
  variantId,
  specs,
  price,
}) => {
  const classes = useStyles()
  const rate = useSelector(state => rateSelector(state, { price }))

  const bundles = useSelector(({ promoBundles: { list } }) =>
    list.filter(b => b.device.id === variantId),
  )

  const linkTo = { pathname: `/${slug}`, state: { variantId } }

  return (
    <div className={classnames('grid-x grid-padding-x', classes.container)}>
      <div className={classnames('small-12 large-3', classes.imageContainer)}>
        {bundles.length > 0 && <BundleBanner />}
        <Link to={linkTo}>
          <ProductImage
            imageEntity={imageEntity}
            discounted={bundles.length > 0}
            alt={name}
            height={180}
            width={290}
          />
        </Link>
      </div>
      <div
        className={classnames('small-12 large-6', classes.descriptionContainer)}
      >
        <Link className={classes.name} to={linkTo}>
          {manufacturer && `${manufacturer} `}
          {name}
        </Link>
        {specs && <Specs specs={specs} />}
        <Media query="(min-width: 1024px)">
          {matches =>
            matches && (
              <div
                className={classes.description}
                dangerouslySetInnerHTML={{ __html: description }} // eslint-disable-line
              />
            )
          }
        </Media>
      </div>
      <div className={classnames('small-12 large-3', classes.priceContainer)}>
        <div className={classes.price}>
          ab <FormattedNumber style="currency" currency="EUR" value={rate} />
        </div>
        {link}
      </div>
    </div>
  )
}

Item.propTypes = {
  imageEntity: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      spec: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  price: PropTypes.number.isRequired,
  variantId: PropTypes.string.isRequired,
}

export default Item
