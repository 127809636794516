import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import flags from 'Lib/config'
import MetaData from '../Layout/MetaData'
import Section from '../Layout/Section'
import LoginForm from './LoginForm'
import styles from './styles'

const useStyles = createUseStyles(styles)

const Login = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation('auth')
  return (
    <Section title={t('login')}>
      <MetaData canonical="account/login" title="Mein Konto" />
      <div className="grid-x grid-padding-x">
        <div className="cell small-12 medium-8 medium-offset-2">
          <LoginForm onSuccess={() => history.push('/account')} />
        </div>
        <div className="cell small-12 medium-8 medium-offset-2">
          <Link
            to="/account/password/reset/request"
            className={classes.forgotPasswordLink}
          >
            {t('forgotPassword')}
          </Link>
        </div>
        {flags.reseller.also && (
          <div className="cell small-12 medium-8 medium-offset-2">
            <Link to="/account/signup" className={classes.forgotPasswordLink}>
              {t('notSignedUpYet')}
            </Link>
          </div>
        )}
      </div>
    </Section>
  )
}

export default Login
