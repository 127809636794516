import React from 'react'
import { compose, branch, renderNothing, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import {
  initializeConfiguration,
  setProduct,
} from 'Ducks/common/productConfiguration'
import Section from '../Layout/Section'
import { withSpinnerWhileLoading } from '../Layout/LoadingIndicator'
import ProductDetails from './ProductDetails'
import InformationTabs from '../InformationTabs'
import Heading from '../Layout/Heading'
import MetaData from '../Layout/MetaData'
import DynamicTitle from '../DynamicTitle'

const enhance = compose(
  withRouter,
  connect(
    (
      { products: { list: products, fetching }, resellerInfo },
      {
        match: {
          params: { slug },
        },
      },
    ) => ({
      product: products.find(p => p.slug === slug),
      fetching,
      isAffiliateReseller: get(resellerInfo, 'data.affiliateReseller'),
    }),
    { setProduct, initializeConfiguration },
  ),
  withSpinnerWhileLoading(({ product }) => !product),
  lifecycle({
    componentWillMount() {
      this.props.initializeConfiguration()
      this.props.setProduct(this.props.product.id)
    },
  }),
  branch(({ product }) => !product, renderNothing),
)

export default enhance(({ product, isAffiliateReseller }) => [
  <MetaData
    key="metadata"
    title={product.name}
    metaData={product.metaData}
    canonical={product.slug}
    robots={isAffiliateReseller && 'noindex'}
  />,
  <Heading key="heading">
    <DynamicTitle
      highlighted={product.manufacturer.name}
      normal={product.name}
    />
  </Heading>,
  <Section key="details" padding="80">
    <ProductDetails product={product} />
  </Section>,
  <Section key="info" padding="80">
    <InformationTabs informationTabs={product.informationTabs} />
  </Section>,
])
