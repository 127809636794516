import LineItem from 'Components/Common/Cart/LineItem'
import Specs from 'Components/Common/CategoryPage/Specs'
import { AccessoryContainer } from 'Components/Common/UI/CartLine'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import WorkplaceServices from 'Components/Also/Cart/WorkplaceServices'
import WorkplaceAccessory from './WorkplaceAccessory'

const Workplace = ({
  index,
  workplace: { term, WorkplaceUserDevice, Services, quantity, ...fields },
}) => {
  const { variant, product } = useSelector(
    ({ alsoProductVariants, alsoProducts }) => {
      const variant = alsoProductVariants.entities[WorkplaceUserDevice.variant]

      const product = alsoProducts.entities[variant.product]

      return { variant, product }
    },
  )

  const accessories = Object.entries(fields)

  const { id: variantId, specs } = variant
  const { slug, images, name } = product

  const accessoryRows = Math.floor(accessories.length / 4) + 1
  return (
    <LineItem
      imageEntity={get(images, '0.@id')}
      name={name}
      link={{ pathname: `/${slug}`, state: { variantId } }}
    >
      <Specs specs={specs} />
      <AccessoryContainer style={{ height: `${accessoryRows * 78}px` }}>
        {accessories.map(([field, config]) => (
          <WorkplaceAccessory
            key={field}
            field={field}
            variant={config.variant}
            quantity={config.quantity}
            term={term}
            index={index}
          />
        ))}
      </AccessoryContainer>
      <WorkplaceServices services={Services} />
    </LineItem>
  )
}

Workplace.propTypes = {
  workplace: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    term: PropTypes.number.isRequired,
    WorkplaceUserDevice: PropTypes.string.isRequired,
    Services: PropTypes.object.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

Workplace.defaultProps = {}

export default Workplace
