import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import useStoreView from 'Hooks/useStoreView'
import history from 'Lib/history'

const GoogleAnalyticsIntegration = () => {
  // const { consent } = useSelector(state => state.config)
  const googleAnalyticsCode = useStoreView('googleAnalyticsCode')
  const cookieBotId = useStoreView('cookieBotId')

  useEffect(() => {
    if (!googleAnalyticsCode) {
      return
    }
    history.listen(location => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }

      gtag('config', googleAnalyticsCode, {
        page_path: location.pathname,
      })
    })
  }, [googleAnalyticsCode])
  if (!googleAnalyticsCode) {
    return null
  }
  return (
    <Helmet>
      <script
        data-cookieconsent={cookieBotId ? 'statistics' : undefined}
        async
        type={cookieBotId ? 'text/plain' : undefined}
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsCode}`}
      />
      <script data-cookieconsent="statistics" type="text/plain">
        {`
      window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${googleAnalyticsCode}', { 'anonymize_ip': true });`}
      </script>
    </Helmet>
  )
}

export default GoogleAnalyticsIntegration
