import React from 'react'
import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

const Container = styled.div`
  display: flex;
  font-size: 17px;
  color: ${colors.warmGrey};
  text-align: center;
`

const VariantName = ({ variantName }) => <Container>{variantName}</Container>

export default VariantName
