export default {
  warmGrey: '#7a7a7a',
  greyishBrown: '#424242',
  greyishBrownTwo: '#555454',
  greyishBrownThree: '#575757',
  greyishBrownFour: '#535353',
  greyishBrownFive: '#3d3d3d',
  greyishBrownSix: '#494949',
  greyishBrownSeven: '#4f4f4f',
  greyishBrownEight: '#565656',
  greyish29: 'rgba(174, 173, 173, 0.29)',
  brownishGrey: '#636363',
  brownisGreyTwo: '#656565',
  brownisGreyThree: '#676767',
  pinkishGrey: '#cccccc',
  pinkishGreyTwo: '#d0d0d0',
  warmGreyFour: '#747474',
  warmGreyFive: '#828282',
  white: 'white',
  whiteThree: '#eaeaea',
  whiteFour: '#f4f4f4',
  whiteFive: '#e3e3e3',
  whiteSix: '#d9d9d9',
  whiteSeven: '#d6d6d6',
  whiteEight: '#d3d3d3',
  whiteNine: '#fafafa',
  black: 'black',
  somecolor: 'rgba(83, 82, 82, 0.8)',
  lightBlack: 'rgba(0, 0, 0, 0.57)',
}
