import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import { logout } from 'Ducks/common/auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { FETCH_DISCOUNTABLES_REQUEST } from 'Sagas/fetchSaga'
import Signup from 'Components/Common/Auth/Signup'
import Login from '../Auth/Login'
import PasswordReset from '../Auth/PasswordReset'
import PasswordResetRequest from '../Auth/PasswordResetRequest'
import PasswordResetSuccess from '../Auth/PasswordResetSuccess'
import Heading from '../Layout/Heading'
import MetaData from '../Layout/MetaData'
import Section from '../Layout/Section'
import EnhancedTitle from '../Title'
import Addresses from './Addresses'
import BankDetails from './BankDetails'
import Ceo from './Ceo'
// import OrderContainer from '../Order/OrderContainer'

const styles = theme => ({
  text: {
    lineHeight: '1.67',
    letterSpacing: '0.3px',
    fontSize: '15px',
  },
  link: {
    extend: 'text',
    fontSize: '19px',
    lineHeight: '1.32',
    letterSpacing: '0.4px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'pointer',
    color: colors.lightBlack,
  },
  linkActive: {
    extend: 'link',
    color: theme.firstColor,
  },
})

const useStyles = createUseStyles(styles)

const Account = () => {
  const classes = useStyles()
  const { t } = useTranslation('account')

  const dispatch = useDispatch()

  const authenticated = useSelector(state => state.auth.token !== null)
  const history = useHistory()

  const handleLogout = () => {
    dispatch(logout())
    history.push('/')
    dispatch({ type: FETCH_DISCOUNTABLES_REQUEST })
  }

  return (
    <>
      <MetaData title="Account" robots="noindex,nofollow" canonical="account" />

      <Heading paddingBottom="0">
        <EnhancedTitle i18nKey="account" />
      </Heading>

      <Section paddingTop="40">
        <div className="grid-x grid-padding-x">
          {authenticated && (
            <div className="cell medium-2">
              <div>
                <NavLink
                  to="/account/addresses"
                  className={classes.link}
                  activeClassName={classes.linkActive}
                >
                  {t('links.addresses')}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/account/bank"
                  className={classes.link}
                  activeClassName={classes.linkActive}
                >
                  {t('links.bankDetails')}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/account/ceo"
                  className={classes.link}
                  activeClassName={classes.linkActive}
                >
                  {t('links.ceo')}
                </NavLink>
              </div>
              <div>
                <button
                  type="button"
                  className={classes.link}
                  onClick={handleLogout}
                >
                  {t('links.logout')}
                </button>
              </div>
            </div>
          )}
          <div
            className={classnames('cell', {
              'medium-10': authenticated,
            })}
          >
            <Switch>
              <Route path="/account/addresses" component={Addresses} />
              <Route path="/account/bank" component={BankDetails} />
              <Route path="/account/ceo" component={Ceo} />

              <Route path="/account/signup" component={Signup} />
              <Route path="/account/login" component={Login} />
              <Route
                exact
                path="/account/password/reset/request"
                component={PasswordResetRequest}
              />
              <Route
                exact
                path="/account/password/reset/success"
                component={PasswordResetSuccess}
              />
              <Route
                exact
                path="/account/password/reset/:token"
                component={PasswordReset}
              />
              <Route
                render={() =>
                  !authenticated ? (
                    <Redirect to="/account/login" />
                  ) : (
                    <Redirect to="/account/addresses" />
                  )
                }
              />
            </Switch>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Account
