import React from 'react'
import PasswordResetRequestForm from './PasswordResetRequestForm'

import Section from '../Layout/Section'

const PasswordResetRequest = () => (
  <Section>
    <div className="grid-x grid-padding-x">
      <div className="cell small-12 medium-8 medium-offset-2">
        <PasswordResetRequestForm />
      </div>
    </div>
  </Section>
)

export default PasswordResetRequest
