import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.firstColor};
`

export const Counter = styled.span`
  font-size: 40px;
  color: white;
  top: -10px;
  right: -10px;
  background-color: ${props => props.theme.firstColor};
  font-weight: bold;
  height: 2em;
  min-width: 2em;
  border: 10px solid white;
`
