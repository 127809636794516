import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LoginForm from '../Auth/LoginForm'
import {
  CustomerContainer,
  CustomerHeader,
  ExistingCustomerContainer,
} from './StyledComponents'

const CheckoutLoginForm = () => {
  const { t } = useTranslation('auth')
  return (
    <CustomerContainer className={classnames('cell', 'small-12', 'medium-8')}>
      <CustomerHeader>{t('existingCustomer')}</CustomerHeader>
      <ExistingCustomerContainer>
        <LoginForm />
      </ExistingCustomerContainer>
    </CustomerContainer>
  )
}

export default CheckoutLoginForm
