import classnames from 'classnames'
import useStoreView from 'Hooks/useStoreView'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import ButtonLink from '../../Button/ButtonLink'
import Section from '../../Layout/Section'
import Contact from './Contact'
import FooterLinkBar from './FooterLinkBar'
import Navigation from './Navigation'
import Social from './Social'
import styles from './styles'

const useStyles = createUseStyles(styles)

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation('footer')
  const productOverviewSlug = useStoreView(
    'seoConfiguration.productOverviewSlug',
  )

  return (
    <Section paddingBottom="0px">
      <div className="grid-x small-up-1 large-up-3 footer-cells">
        <div className={classnames('cell', classes.section)}>
          <Contact />
        </div>
        <div className={classnames('cell', classes.section)}>
          <Navigation />
        </div>
        <div className={classnames('cell', classes.socialSection)}>
          <Social />
          <ButtonLink
            to={`/${productOverviewSlug}`}
            style={{ marginTop: '35px' }}
          >
            {t('rentNow')}
          </ButtonLink>
        </div>
      </div>

      <FooterLinkBar />
    </Section>
  )
}

export default Footer
