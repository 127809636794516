import { merge, get } from 'lodash'
import { STORE_VIEW_FETCH_SUCCESS } from 'Ducks/common/storeView'

const i18nMiddleWare = store => next => action => {
  if (
    /.*::FETCH_LIST_SUCCESS/.test(action.type) ||
    action.type === STORE_VIEW_FETCH_SUCCESS
  ) {
    const {
      config: { lang },
    } = store.getState()

    next({ ...action, lang })
  } else {
    next(action)
  }
}

export const mapLanguage = lang => entity =>
  merge({}, entity, get(entity, `translations.${lang}`))

export default i18nMiddleWare
