import classnames from 'classnames'
import { flatMap, min } from 'lodash'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Media from 'react-media'
import { useSelector } from 'react-redux'
import { workplaceDevicesSelector } from 'Selectors/also/productSelectors'
import Title from 'Components/Common/Title'
import Section from 'Components/Common/Layout/Section'
import Slider from 'Components/Common/Layout/Slider'
import DeviceBox from './DeviceBox'

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const AlsoDeviceBoxSection = () => {
  const classes = useStyles()
  const devices = useSelector(state => {
    const devices = workplaceDevicesSelector(state)

    return devices.map(d => {
      const monthlyPrices = flatMap(d.variants, id =>
        Object.values(state.alsoProductVariants.entities[id].monthlyPrices),
      )

      const minRate = min(monthlyPrices.filter(p => p > 0))

      return { ...d, minRate }
    })
  })

  if (devices.length === 0) {
    return null
  }

  return (
    <Media query="(max-width: 640px)">
      {matches => (
        <Section
          paddingTop={matches ? '0' : undefined}
          title={<Title i18nKey="featuredProducts" />}
        >
          <Slider perRow={4}>
            {devices
              .sort((a, b) => a.minRate - b.minRate)
              .map(device => (
                <div key={device.id}>
                  <div className={classnames(classes.item)}>
                    <DeviceBox device={device} rate={device.minRate} />
                  </div>
                </div>
              ))}
          </Slider>
        </Section>
      )}
    </Media>
  )
}

export default AlsoDeviceBoxSection
