import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'Assets/styles/colors'
import clamp from 'lodash/clamp'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import sliderStyles from './sliderStyles'

const styles = {
  arrow: {
    lineHeight: '0',
    position: 'absolute',
    top: '50%',
    padding: '0',
    transform: 'useTranslation(0, -50%)',
    cursor: 'pointer',
    height: '40px',
    width: '40px',
    backgroundColor: 'white',
    border: {
      width: '1px',
      style: 'solid',
      color: colors.whiteSix,
    },
    borderRadius: '20px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border-color 0.5s ease, color 0.5s ease',
    color: colors.whiteSix,
    '&:hover': {
      color: colors.warmGrey,
      borderColor: colors.warmGrey,
    },
  },
  nextArrow: {
    extend: 'arrow',
    right: '-40px',
  },
  prevArrow: {
    extend: 'arrow',
    left: '-40px',
  },
}

const useArrowStyles = createUseStyles(styles)

export const NextArrow = ({ onClick }) => {
  const classes = useArrowStyles()

  return (
    <div
      role="button"
      onKeyPress={onClick}
      tabIndex={0}
      className={classes.nextArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="angle-right" />
    </div>
  )
}

export const PrevArrow = ({ onClick }) => {
  const classes = useArrowStyles()

  return (
    <div
      role="button"
      onKeyPress={onClick}
      tabIndex={0}
      className={classes.prevArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="angle-left" />
    </div>
  )
}

const defaultSettings = ({ count, perRow, hideArrows }) => ({
  infinite: true,
  speed: 500,
  slidesToShow: clamp(count, 1, perRow),
  slidesToScroll: 1,
  dots: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  arrows: hideArrows ? false : count > perRow,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: clamp(count, 1, 2),
        slidesToScroll: 1,
        dots: count > 2,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: clamp(count, 1, 1),
        slidesToScroll: 1,
        dots: count > 1,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: count > 1,
        arrows: false,
      },
    },
  ],
})

const useStyles = createUseStyles(sliderStyles)

const Slider = ({ settings, hideArrows, children, perRow }) => {
  const classes = useStyles()

  return (
    <SlickSlider
      {...defaultSettings({
        count: children.length,
        perRow,
        hideArrows,
      })}
      {...settings}
      dotsClass={classes.dots}
    >
      {children}
    </SlickSlider>
  )
}

Slider.propTypes = {
  settings: PropTypes.object, // eslint-disable-line
  children: PropTypes.node, // eslint-disable-line
  hideArrows: PropTypes.bool,
  perRow: PropTypes.number,
}

Slider.defaultProps = {
  children: [],
  perRow: 3,
  hideArrows: false,
}

export default Slider
