import React from 'react'
import SelectInput from 'react-select'
// Be sure to include styles at some point, probably during your bootstrapping

export const inputStyles = {
  input: {
    height: 57,
    borderRadius: '3px',
    borderWidth: '0',
    backgroundColor: '#ffffff',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '57px',
    letterSpacing: '0.4px',
    textAlign: 'left',
    width: '100%',
    color: '#808080',
  },
}

const selectStyles = {
  menu: provided => ({
    ...provided,
    marginTop: 0,
  }),
}

const Select = props => <SelectInput styles={selectStyles} {...props} />

export default Select
