import FuzzySearch from 'fuzzy-search'
import { useState } from 'react'

const useProductSearch = products => {
  const [filter, setFilter] = useState('')

  const filterValue = filter
    .trim()
    .replace(' ', '')
    .toLowerCase()

  const searcher = new FuzzySearch(
    products.map(d => ({
      ...d,
      searchValue: `${d.manufacturer} ${d.name}`,
    })),
    ['searchValue'],
    {
      sort: true,
    },
  )

  const results = searcher.search(filterValue)

  return {
    results,
    filter,
    setFilter,
  }
}

export default useProductSearch
