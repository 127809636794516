export const ALSO_CONFIG_FETCH_REQUEST = 'ALSO_CONFIG::FETCH_REQUEST'
export const ALSO_CONFIG_FETCH_FAIL = 'ALSO_CONFIG::FETCH_FAIL'
export const ALSO_CONFIG_FETCH_SUCCESS = 'ALSO_CONFIG::FETCH_SUCCESS'

const initialState = {}

export const setConfig = data => ({
  type: ALSO_CONFIG_FETCH_SUCCESS,
  payload: { data },
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALSO_CONFIG_FETCH_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case ALSO_CONFIG_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        fetching: false,
      }
    }
    case ALSO_CONFIG_FETCH_FAIL: {
      return {
        fetching: false,
      }
    }
    default:
      return state
  }
}

export default reducer
