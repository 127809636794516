import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const WorkplaceServices = ({ services }) => {
  const serviceDetails = useSelector(state =>
    Object.values(services).map(id => state.alsoServices.entities[id]),
  )

  return serviceDetails.map(s => <div>{s.service.name}</div>)
}

WorkplaceServices.propTypes = {
  services: PropTypes.object,
}

WorkplaceServices.defaultProps = {
  services: {},
}

export default WorkplaceServices
