import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { renderField } from '../Layout/Form/FormField'
import LegalFormSelect, { legalForms } from './LegalFormSelect'
import SalutationSelect from './SalutationSelect'
import styles from './styles'

const useStyles = createUseStyles(styles)

const AddressForm = ({ source, label, note }) => {
  const { t } = useTranslation('checkout')
  const classes = useStyles()

  const ref = useRef(false)

  const form = useForm()

  const field = useField(`${source}[company]`, {
    subscription: { value: true, active: true },
  })

  const {
    input: { value: legalFormValue },
  } = useField(`${source}[legalForm]`, {
    subscription: { value: true, active: true },
  })

  useEffect(() => {
    if (ref.current === true && field.meta.active === false) {
      if (legalFormValue === '') {
        const legalForm = [...legalForms]
          .sort((a, b) => b.length - a.length)
          .find(f => field.input.value.includes(f))

        form.change(`${source}.legalForm`, legalForm || 'Gewerbebetrieb')
      }
    }

    ref.current = field.meta.active
  }, [field.meta.active])

  return (
    <div className={classnames('grid-x', 'grid-padding-x', classes.fieldGroup)}>
      <div className="cell small-12">
        <div className={classes.fieldGroupHeader}>{t(label || source)}</div>
      </div>

      {note && (
        <div className="cell small-12">
          <div className={classes.fieldGroupComment}>{note}</div>
        </div>
      )}
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.company')}
          name={`${source}[company]`}
          id={`${source}[company]`}
        />
      </div>
      <div className="cell medium-6">
        <Field name={`${source}[legalForm]`} component={LegalFormSelect} />
      </div>
      <div className="cell medium-4">
        <Field name={`${source}[salutation]`} component={SalutationSelect} />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.firstName')}
          name={`${source}[firstName]`}
          id={`${source}[firstName]`}
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.lastName')}
          name={`${source}[lastName]`}
          id={`${source}[lastName]`}
        />
      </div>
      <div className="cell medium-12">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.street')}
          name={`${source}[street]`}
          id={`${source}[street]`}
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.postCode')}
          name={`${source}[postcode]`}
          id={`${source}[postcode]`}
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.city')}
          name={`${source}[city]`}
          id={`${source}[city]`}
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.phone')}
          name={`${source}[phone]`}
          id={`${source}[phone]`}
        />
      </div>
      <div className="cell medium-6">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.email')}
          name={`${source}[email]`}
          id={`${source}[email]`}
        />
      </div>
    </div>
  )
}

AddressForm.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  note: PropTypes.string,
}

AddressForm.defaultProps = {
  label: null,
  note: null,
}

export default AddressForm
