import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import styles from './styles'

const useStyles = createUseStyles(styles)

const SocialLink = ({ url, icon }) => {
  const classes = useStyles()

  if (!url) {
    return null
  }

  return (
    <div className="cell shrink">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <div className={classes.socialIcon}>
          <FontAwesomeIcon icon={['fab', icon]} aria-hidden="true" size="2x" />
        </div>
      </a>
    </div>
  )
}

SocialLink.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.string.isRequired,
}

SocialLink.defaultProps = {
  url: null,
}

export default SocialLink
