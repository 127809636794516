import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  inner: {
    maxWidth: 1300,
    width: '100%',
  },
  dark: {
    backgroundColor: colors.whiteFour,
  },
  green: {
    backgroundColor: theme.firstColor,
  },
  titleContainer: {
    marginBottom: '48px',
    textAlign: 'center',
  },
  title: {
    fontSize: '37px',
    textTransform: 'uppercase',
    fontWeight: '900',
    letterSpacing: '0.7px',
    color: colors.greyishBrown,
  },
})

const useStyles = createUseStyles(styles)

const Section = ({
  padding,
  paddingTop,
  paddingBottom,
  title,
  subtitle,
  children,
  dark,
  green,
  backgroundColor,
  className,
  titleAsH1,
}) => {
  const classes = useStyles()

  return (
    <section
      style={{
        paddingTop: `${paddingTop || padding}px`,
        paddingBottom: `${paddingBottom || padding}px`,
        backgroundColor,
      }}
      className={classnames('section', className, classes.container, {
        [classes.dark]: dark,
        [classes.green]: green,
      })}
    >
      <div className={classes.inner}>
        {title && (
          <div className="grid-x align-center">
            <div className={classnames(classes.titleContainer, 'cell', 'auto')}>
              {titleAsH1 ? (
                <h1 className={classes.title}>{title}</h1>
              ) : (
                <div className={classes.title}>{title}</div>
              )}
              {subtitle}
            </div>
          </div>
        )}
        {children}
      </div>
    </section>
  )
}

Section.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node.isRequired, // eslint-disable-line
  dark: PropTypes.bool,
  green: PropTypes.bool,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  titleAsH1: PropTypes.bool,
}

Section.defaultProps = {
  padding: 130,
  paddingTop: undefined,
  paddingBottom: undefined,
  dark: false,
  green: false,
  backgroundColor: undefined,
  className: undefined,
  titleAsH1: false,
  title: undefined,
  subtitle: undefined,
}

export default Section
