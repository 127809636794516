import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import colors from 'Assets/styles/colors'
import typography from './typography'

const styles = {
  titleContainer: {
    ...typography.text,
    backgroundColor: colors.whiteFour,
    width: '100%',
    textAlign: 'center',
    fontSize: '37px',
    fontWeight: '600',
    letterSpacing: '0.7px',
    textTransform: 'uppercase',
    padding: '40px 0',
  },
}

const useStyles = createUseStyles(styles)

const Heading = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.titleContainer}>{children}</div>
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Heading
