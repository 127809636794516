import AlsoCart from 'Components/Also/Cart/Cart'
import AluxoCart from 'Components/Aluxo/Cart/Cart'
import React from 'react'
import flags from 'Lib/config'

const Cart = () => {
  if (flags.reseller.also) {
    return <AlsoCart />
  }

  return <AluxoCart />
}

export default Cart
