import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import LoadingIndicator from '../Layout/LoadingIndicator'
import QuantitySpinner from '../QuantitySpinner'
import styles from './styles'

/* eslint-disable react/style-prop-object */

const useStyles = createUseStyles(styles)

const PriceButtonLink = ({
  quantity,
  price,
  fetching,
  style,
  height,
  children,
  onClick,
  onDecreaseClick,
  onIncreaseClick,
  exact,
  hollow,
  label,
  to,
  className,
}) => {
  const { t } = useTranslation('catalog')
  const classes = useStyles({ hollow })

  return (
    <div
      className={classnames(classes.box, className)}
      style={{ ...style, height: `${height}px` }}
    >
      <div className={classes.halfContainer}>
        {quantity && (
          <QuantitySpinner
            increment={onIncreaseClick}
            decrement={onDecreaseClick}
            quantity={quantity}
            className={classes.quantity}
          />
        )}
        {fetching ? (
          <LoadingIndicator small />
        ) : (
          <div className={classes.price}>
            {!exact && <span>{t('priceFrom')}&nbsp;</span>}
            <FormattedNumber style="currency" currency="EUR" value={price} />
          </div>
        )}
      </div>
      <div className={classes.halfContainer}>
        <Link className={classes.priceText} to={to}>
          {label}&nbsp;
          <FontAwesomeIcon icon="angle-right" />
        </Link>
      </div>
    </div>
  )
}

PriceButtonLink.defaultProps = {
  height: 40,
  exact: false,
}

export default PriceButtonLink
