import colors from 'Assets/styles/colors'

export default {
  text: {
    fontFamily: 'Titillium Web',
  },
  h2: {
    extend: 'text',
    fontSize: '21px',
    fontWeight: '600',
    lineHeight: '1.19',
    letterSpacing: '0.4px',
    color: colors.greyishBrownEight,
  },
}
