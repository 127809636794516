import { createSelector } from 'reselect'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import {
  complement,
  descend,
  equals,
  find,
  lt,
  pipe,
  prop,
  sort,
  sum,
  where,
  reverse,
} from 'ramda'

const sumPrices = (...prices) => sum(prices)
const sumArrayPrices = prices => sum(prices)

export const devicePriceSelector = ({ devices: { list: devices } }, props) => {
  const device = devices.find(({ id }) => id === props.device)
  if (!device) {
    return 0
  }

  const variant = device.variants.find(({ id }) => id === props.variant)

  if (!variant) {
    return 0
  }

  return variant.netPrice
}

const calculateAbsoluteDeviceVariantDiscount = (
  variant,
  quantity,
  isBundle = false,
) => {
  const {
    discounts: { bidGrid, dealReg, acceleratePricing, ncab },
  } = variant

  let sumDiscount = 0

  let bidGridDiscount = 0
  let dealRegDiscount = 0
  let acceleratePricingDiscount = 0

  if (bidGrid) {
    const { discount } = reverse(bidGrid).find(v => v.quantity <= quantity)
    bidGridDiscount = discount
  }

  if (dealReg) {
    dealRegDiscount = dealReg.discount
  }

  if (!isBundle && acceleratePricing) {
    acceleratePricingDiscount = acceleratePricing.discount
    acceleratePricingDiscount += get(ncab, 'discount', 0)
  }

  if (
    bidGridDiscount + dealRegDiscount >=
    dealRegDiscount + acceleratePricingDiscount
  ) {
    sumDiscount = bidGridDiscount + dealRegDiscount
  } else {
    sumDiscount = dealRegDiscount + acceleratePricingDiscount
  }

  return sumDiscount
}

export const bundledDevicePriceSelector = (
  { devices: { list: devices } },
  { device: deviceId, variant: variantId, bundle, quantity },
) => {
  const device = devices.find(({ id }) => id === deviceId)
  if (!device) {
    return 0
  }

  const variant = device.variants.find(({ id }) => id === variantId)

  if (!variant) {
    return 0
  }

  if (bundle) {
    return (
      bundle.netPrice -
      calculateAbsoluteDeviceVariantDiscount(variant, quantity, true)
    )
  }

  return (
    variant.netPrice - calculateAbsoluteDeviceVariantDiscount(variant, quantity)
  )
}

export const productPriceSelector = (
  { products: { list: products } },
  { productConfiguration },
) => {
  const product = products.find(({ id }) => id === productConfiguration.product)
  if (!product) {
    return 0
  }

  const variant = product.productVariants.find(
    ({ id }) => id === productConfiguration.productVariant,
  )

  if (!variant) {
    return 0
  }

  return variant.netPrice
}

export const deviceAccessoryPriceSelector = (
  { products: { list: products } },
  props,
) => {
  const variants = flatMap(products, p => p.productVariants)

  const { accessories } = props

  return accessories
    ? accessories
        .map(acc => ({
          ...acc,
          netPrice: variants.find(({ id }) => id === acc.variant).netPrice,
        }))
        .map(acc => acc.quantity * acc.netPrice)
        .reduce((sum, value) => sum + value, 0)
    : 0
}

export const bundledDeviceAccessoryPriceSelector = (
  { products: { list: products } },
  props,
) => {
  const variants = flatMap(products, p => p.productVariants)

  const { accessories, bundle } = props

  const reduceToPrice = accessories =>
    accessories
      .map(acc => ({
        ...acc,
        netPrice: variants.find(({ id }) => id === acc.variant).netPrice,
      }))
      .map(acc => acc.quantity * acc.netPrice)
      .reduce((sum, value) => sum + value, 0)

  if (bundle) {
    const unbundledAccessories = accessories.filter(
      a => !bundle.accessories.some(p => p.id === a.variant),
    )
    return reduceToPrice(unbundledAccessories)
  }
  return reduceToPrice(accessories)
}

const protectionPlanPriceSelector = (
  { protectionPlans: { list: protectionPlans } },
  { protectionPlan },
) =>
  protectionPlan
    ? protectionPlans.find(({ id }) => id === protectionPlan).netPrice
    : 0

const serviceExtensionPriceSelector = (
  { serviceExtensions: { list: serviceExtensions } },
  { serviceExtension },
) =>
  serviceExtension
    ? serviceExtensions.find(e => e.id === serviceExtension).netPrice
    : 0

export const deviceAndAccessoryPriceSelector = createSelector(
  devicePriceSelector,
  deviceAccessoryPriceSelector,
  sumPrices,
)

export const deviceConfigurationPriceSelector = createSelector(
  [
    devicePriceSelector,
    deviceAccessoryPriceSelector,
    protectionPlanPriceSelector,
    serviceExtensionPriceSelector,
  ],
  sumPrices,
)

export const discountedDeviceConfigurationPriceSelector = createSelector(
  [
    bundledDevicePriceSelector,
    bundledDeviceAccessoryPriceSelector,
    protectionPlanPriceSelector,
    serviceExtensionPriceSelector,
  ],
  sumPrices,
)

const productConfigurationPriceSelector = createSelector(
  productPriceSelector,
  price => price,
)

const servicePriceSelector = (
  { services: { list: allServices } },
  { services },
) =>
  services
    ? Object.values(services)
        .filter(id => id)
        .map(id => allServices.find(s => s.id === id).netPrice)
        .reduce((prev, sum) => prev + sum, 0)
    : 0

export const devicePeriodSelector = (state, props) =>
  get(props, 'period') || state.cart.period

export const productPeriodSelector = (state, props) =>
  get(props, 'productConfiguration.period') || state.cart.period

export const leasingFactorsSelector = state => state.config.leasingFactors

export const findLeasingFactor = (period, price) =>
  pipe(
    sort(descend(prop('min'))),
    find(
      where({
        period: equals(period),
        min: complement(lt(price)),
      }),
    ),
  )

export const deviceConfigurationRateSelector = createSelector(
  deviceConfigurationPriceSelector,
  leasingFactorsSelector,
  devicePeriodSelector,
  (price, leasingFactors, period) => {
    const { leasingFactor } = findLeasingFactor(period, price)(leasingFactors)

    return (price * (leasingFactor + 0.02)) / 100
  },
)

export const productConfigurationRateSelector = createSelector(
  productConfigurationPriceSelector,
  leasingFactorsSelector,
  productPeriodSelector,
  (price, leasingFactors, period) => {
    const { leasingFactor } = findLeasingFactor(period, price)(leasingFactors)

    return (price * (leasingFactor + 0.02)) / 100
  },
)

export const productConfigurationMonthlyRateSelector = createSelector(
  productConfigurationRateSelector,
  (_, props) => props.productConfiguration.quantity,
  (rate, quantity) => rate * quantity,
)

export const deviceConfigurationMonthlyRateSelector = createSelector(
  deviceConfigurationRateSelector,
  servicePriceSelector,
  (_, props) => props.quantity,
  (rate, servicePrice, quantity) => (rate + servicePrice) * quantity,
)

export const totalDevicesMonthlySelector = createSelector(
  state =>
    state.cart.devices.map(deviceConfiguration =>
      deviceConfigurationMonthlyRateSelector(state, deviceConfiguration),
    ),
  sumArrayPrices,
)

export const totalProductsMonthlySelector = createSelector(
  state =>
    state.cart.products.map(productConfiguration =>
      productConfigurationMonthlyRateSelector(state, { productConfiguration }),
    ),
  sumArrayPrices,
)

export const totalMonthlySelector = createSelector(
  totalDevicesMonthlySelector,
  totalProductsMonthlySelector,
  sumPrices,
)

export const totalDevicesSelector = createSelector(
  state =>
    state.cart.devices.map(
      deviceConfiguration =>
        deviceConfigurationPriceSelector(state, deviceConfiguration) *
        deviceConfiguration.quantity,
    ),
  sumArrayPrices,
)

export const totalDiscountedDevicesSelector = createSelector(
  state =>
    state.cart.devices.map(
      deviceConfiguration =>
        discountedDeviceConfigurationPriceSelector(state, deviceConfiguration) *
        deviceConfiguration.quantity,
    ),
  sumArrayPrices,
)

export const totalServicesSelector = createSelector(
  state =>
    state.cart.devices.map(
      deviceConfiguration =>
        servicePriceSelector(state, deviceConfiguration) *
        deviceConfiguration.quantity,
    ),
  sumArrayPrices,
)

export const totalProductsSelector = createSelector(
  state =>
    state.cart.products.map(
      productConfiguration =>
        productPriceSelector(state, { productConfiguration }) *
        productConfiguration.quantity,
    ),
  sumArrayPrices,
)

export const totalCreditableSelector = createSelector(
  totalDevicesSelector,
  totalProductsSelector,
  sumPrices,
)

export const totalDiscountedCreditableSelector = createSelector(
  totalDiscountedDevicesSelector,
  totalProductsSelector,
  sumPrices,
)

export const totalUnCreditableSelector = createSelector(
  totalServicesSelector,
  sumPrices,
)

export const totalDiscountedCreditableMonthlySelector = createSelector(
  totalDiscountedCreditableSelector,
  leasingFactorsSelector,
  devicePeriodSelector,
  (price, leasingFactors, period) => {
    const { leasingFactor } = findLeasingFactor(period, price)(leasingFactors)

    return (price * (leasingFactor + 0.02)) / 100
  },
)

export const discountedTotalMonthlySelector = createSelector(
  totalDiscountedCreditableMonthlySelector,
  totalUnCreditableSelector,
  sumPrices,
)
