import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { branch, compose, renderNothing, setDisplayName } from 'recompose'
import { ProductType } from 'Lib/alsoPropTypes'
import { alsoProductSelector } from 'Selectors/also/productSelectors'
import DynamicTitle from 'Components/Common/DynamicTitle'
import Heading from 'Components/Common/Layout/Heading'
import { withSpinnerWhileLoading } from 'Components/Common/Layout/LoadingIndicator'
import MetaData from 'Components/Common/Layout/MetaData'
import {
  NotificationProvider,
  NotificationConsumer,
} from 'Context/NotificationContext'
import DeviceForm from './DeviceForm'
import Notifications from './Notifications'

const mapStateToProps = (
  state,
  {
    match: {
      params: { slug },
    },
  },
) => ({
  device: alsoProductSelector(state, { slug }),
})

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withSpinnerWhileLoading(({ device }) => !device),
  branch(({ device }) => !device, renderNothing),
  setDisplayName('Device'),
)

const Device = ({ device }) => (
  <NotificationProvider>
    <MetaData
      title={device.name}
      metaData={device.metaData}
      canonical={device.slug}
    />
    <Heading>
      <DynamicTitle highlighted={device.manufacturer} normal={device.name} />
    </Heading>

    <Notifications />
    <NotificationConsumer>
      {({ addNotification }) => (
        <DeviceForm device={device} addNotification={addNotification} />
      )}
    </NotificationConsumer>
  </NotificationProvider>
)

Device.propTypes = {
  device: ProductType.isRequired,
}

export default enhance(Device)
