import jwtDecode from 'jwt-decode'
import { AUTH_TOKEN } from 'Lib/constants'

export const SET_TOKEN = 'SET_TOKEN'
export const ERROR = 'ERROR'
export const LOGOUT = 'LOGOUT'
export const AUTH_CREATE_NEW_CUSTOMER = 'AUTH::CREATE_NEW_CUSTOMER'

export const setToken = token => ({
  type: SET_TOKEN,
  token,
})

export const logout = () => {
  sessionStorage.removeItem(AUTH_TOKEN) // TODO move in saga
  return {
    type: LOGOUT,
  }
}

export const setError = () => ({
  type: ERROR,
})

export const setCreateNewCustomer = () => ({
  type: AUTH_CREATE_NEW_CUSTOMER,
})

const initialState = {
  token: null,
  roles: [],
  newCustomer: false,
  error: false,
}

const getInitialState = () => {
  const token = sessionStorage.getItem(AUTH_TOKEN)
  if (token) {
    return {
      ...initialState,
      token,
      roles: jwtDecode(token).roles,
    }
  }
  return initialState
}

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        roles: jwtDecode(action.token).roles,
        newCustomer: false,
      }
    case LOGOUT:
      return {
        ...state,
        token: null,
        roles: [],
        newCustomer: false,
      }
    case AUTH_CREATE_NEW_CUSTOMER:
      return {
        ...state,
        newCustomer: true,
      }
    case ERROR: {
      return {
        ...getInitialState(),
        error: true,
      }
    }
    default:
      return state
  }
}

export default reducer
