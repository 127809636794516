import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'Assets/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

const styles = {
  listItem: {
    '&:not(:first-child)': {
      marginTop: 16,
    },
  },
  detailsHeader: {
    font: {
      size: '18px',
      weight: 'bold',
    },
    lineHeight: 1.39,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    color: colors.greyishBrownFour,
    marginBottom: 8,
  },
  infoLink: {
    fontSize: 17,
    color: colors.brownishGrey,
    textDecoration: 'none',
    display: 'inline-block',
    marginTop: 8,
  },
}

const useStyles = createUseStyles(styles)

const ListItem = ({ children, header, info }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.listItem}>
      <div className={classes.detailsHeader}>{header}</div>
      {children}
      {info && (
        <Link to={info} target="_blank" className={classes.infoLink}>
          <FontAwesomeIcon icon="info-circle" aria-hidden="true" />
          &nbsp;
          {t('moreInformation')}
        </Link>
      )}
    </div>
  )
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  info: PropTypes.string,
}

ListItem.defaultProps = {
  info: null,
  header: null,
}

export default ListItem
