import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from 'Components/Common/Layout/Input'
import Section from 'Components/Common/Layout/Section'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const SearchDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  ${props => `color: ${props.theme.firstColor}`}
`

const SearchSection = ({ filter, setFilter }) => (
  <Section paddingTop="20" paddingBottom="20">
    <SearchDiv>
      <StyledIcon icon="search" />
      <Input
        expand
        css="width: 100%;"
        type="text"
        input={{
          value: filter,
          onChange: ({ target: { value } }) => setFilter(value),
        }}
      />
    </SearchDiv>
  </Section>
)

SearchSection.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default SearchSection
