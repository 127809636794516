import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'
import { createUseStyles } from 'react-jss'

const style = theme => ({
  highlighted: {
    color: theme.firstColor,
    marginRight: '12px',
    display: 'inline-block',
  },
})

const useStyles = createUseStyles(style)

const Title = ({ i18nKey }) => {
  const classes = useStyles()

  return (
    <div>
      <Trans i18nKey={`headings:${i18nKey}`}>
        <span className={classes.highlighted}>highlighted</span>normal
      </Trans>
    </div>
  )
}

Title.propTypes = {
  i18nKey: PropTypes.string.isRequired,
}

Title.propTypes = {
  i18nKey: PropTypes.string.isRequired,
}

export default Title
