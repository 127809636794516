import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import colors from 'Assets/styles/colors'
import { marketingLogosSelector } from 'Selectors/common/listSelectors'
import useSectionVisible from 'Hooks/useSectionVisible'
import Image from '../Layout/Image'

const styles = {
  container: {
    marginTop: '110px',
    padding: '40px 0',
    backgroundColor: colors.whiteThree,
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    maxWidth: '1300px',
    width: '100%',
  },
  text: {
    // height: '450px',
    backgroundColor: 'white',
    color: '#636363',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: 1.78,
    letterSpacing: 0.7,
    textAlign: 'center',
    marginTop: '78px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
  },
}

const mapStateToProps = state => ({
  logos: marketingLogosSelector(state),
})

const useStyles = createUseStyles(styles)

const enhance = compose(connect(mapStateToProps))

const Logos = ({ logos }) => {
  const classes = useStyles()
  const visible = useSectionVisible('marketingLogos')
  if (!visible) {
    return null
  }

  return (
    <div className={classes.container} style={{ marginTop: 0 }}>
      <div
        className={classnames(
          'grid-x',
          'grid-padding-x',
          'align-spaced',
          'small-up-4',
          'medium-up-4',
          classes.innerContainer,
        )}
      >
        {logos.map(({ name, image }) => (
          <div key={name} className={classnames('cell', classes.logo)}>
            <div>
              <Image src={image} width={280} height={70} alt={name} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Logos.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default enhance(Logos)
