export default {
  error: {
    required: 'Pflichtfeld',
    dataPrivacyNotAccepted:
      'Die Zustimmung zu unseren Hinweisen zum Datenschutz ist erforderlich',
    termsNotAccepted: 'Die Zustimmung zu unseren AGB ist erforderlich',
    invalidBIC: 'Die eingegebene BIC ist ungültig',
    invalidIBAN: 'Die eingegebene IBAN ist ungültig',
    passwordMissing: 'Bitte geben Sie ein Passwort ein',
    invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    wrongDateFormat: 'Bitte geben Sie ein Datum im Format DD.MM.YYYY ein',
    minAge: 'Der Geschäftsführer muss mindestens 21 Jahre alt sein',
    invalidPostcode: 'Bitte geben sie eine gültige Postleitzahl ein',
    alreadyExists: 'Es existiert bereits ein Konto mit dieser E-Mail Adresse',
    insecurePassword:
      'Das Passwort muss entweder aus mindestens 8 Zeichen mit Groß- und Kleinbuchstaben sowie einer Zahl bestehen oder aus drei mit Leerzeichen getrennten Wörtern',
    passwordMismatch: 'Die Passwörter stimmen nicht überein',
    notValidVat: 'Keine gültige Umsatzsteuer-ID',
    notValidDomain: 'Keine gültige URL',
  },
}
