import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { isValidIBAN } from 'ibantools'
import { DateTime } from 'luxon'
import config from 'Lib/config'
import { isEmail } from 'Lib/validationHelper'

const requiredMessage = 'required'
export const passwordRegex = /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$)|(\w{3,}( |$)){3,}/

export const isAddressValid = address => {
  const errors = {}

  if (!get(address, 'company')) {
    errors.company = requiredMessage
  }
  if (!get(address, 'legalForm')) {
    errors.legalForm = requiredMessage
  }
  if (!get(address, 'salutation')) {
    errors.salutation = requiredMessage
  }
  if (!get(address, 'firstName')) {
    errors.firstName = requiredMessage
  }
  if (!get(address, 'lastName')) {
    errors.lastName = requiredMessage
  }
  if (!get(address, 'street')) {
    errors.street = requiredMessage
  }
  // if (!get(address, 'streetNumber')) {
  //   errors.streetNumber = requiredMessage
  // }
  if (!get(address, 'postcode')) {
    errors.postcode = requiredMessage
  } else if (size(address.postcode) !== 5) {
    errors.postcode = 'invalidPostcode'
  }
  if (!get(address, 'city')) {
    errors.city = requiredMessage
  }
  if (!get(address, 'email')) {
    errors.email = requiredMessage
  } else if (!isEmail(address.email)) {
    errors.email = 'invalidEmail'
  }
  if (!get(address, 'phone')) {
    errors.phone = requiredMessage
  }

  return errors
}

const isCeoDataValid = data => {
  const errors = {}

  if (!get(data, 'salutation')) {
    errors.salutation = requiredMessage
  }
  if (!get(data, 'firstName')) {
    errors.firstName = requiredMessage
  }
  if (!get(data, 'lastName')) {
    errors.lastName = requiredMessage
  }
  if (!get(data, 'street')) {
    errors.street = requiredMessage
  }
  if (!get(data, 'postcode')) {
    errors.postcode = requiredMessage
  } else if (size(data.postcode) !== 5) {
    errors.postcode = 'invalidPostcode'
  }
  if (!get(data, 'city')) {
    errors.city = requiredMessage
  }
  if (!get(data, 'birthdate')) {
    errors.birthdate = requiredMessage
  } else {
    const datetime = DateTime.fromFormat(data.birthdate, 'dd.MM.yyyy')
    if (!datetime.isValid) {
      errors.birthdate = 'wrongDateFormat'
    } else if (datetime.plus({ years: 21 }) > DateTime.local()) {
      errors.birthdate = 'minAge'
    }
  }

  return errors
}

export default values => {
  const errors = {}

  const billingAddressErrors = isAddressValid(values.billingAddress)

  if (!isEmpty(billingAddressErrors)) {
    errors.billingAddress = billingAddressErrors
  }

  if (!values.shipToBilling) {
    const shippingAddressErrors = isAddressValid(values.shippingAddress)

    if (!isEmpty(shippingAddressErrors)) {
      errors.shippingAddress = shippingAddressErrors
    }
  }

  if (values.cartValue <= 2900) {
    const ceoErrors = isCeoDataValid(values.ceo)

    if (!isEmpty(ceoErrors)) {
      errors.ceo = ceoErrors
    }
  }

  if (!config.reseller.also) {
    if (!values.IBAN) {
      errors.IBAN = requiredMessage
    } else if (!isValidIBAN(values.IBAN)) {
      errors.IBAN = 'invalidIBAN'
    }
  }

  if (values.createAccount) {
    const customerErrors = {}
    if (!get(values, 'customer.password')) {
      customerErrors.password = requiredMessage
    } else if (!passwordRegex.test(values.customer.password)) {
      customerErrors.password = 'insecurePassword'
    }
    if (!get(values, 'customer.email')) {
      customerErrors.email = requiredMessage
    }
    if (!isEmpty(customerErrors)) {
      errors.customer = customerErrors
    }
  }

  return errors
}
