import concat from 'lodash/concat'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { setPeriod } from 'Ducks/common/cart'
import useStoreView from 'Hooks/useStoreView'
import Title from 'Components/Common/Title'
import Heading from 'Components/Common/Layout/Heading'
import MetaData from 'Components/Common/Layout/MetaData'
import Section from 'Components/Common/Layout/Section'
import PeriodSelect from 'Components/Common/PeriodSelect'
import AllProductsLink from 'Components/Common/Layout/AllProductsLink'
import ButtonLink from 'Components/Common/Button/ButtonLink'
import CartContent from './CartContent'
import Totals from './Totals'

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'flex-end',
    width: 600,
    '& > * ': {
      flex: '1',
    },
  },
  periodSelect: {
    borderRadius: [3, 0, 0, 3],
    '& > *:first-child': {
      borderRadius: [3, 0, 0, 3],
    },
  },
  checkoutButton: {
    borderRadius: [0, 3, 3, 0],
  },
  periodLabel: {
    padding: [8, 0],
    fontSize: 22,
    letterSpacing: '0.4px',
    fontWeight: '600',
    color: '#4f4f4f',
  },
}

const useStyles = createUseStyles(styles)

const Cart = () => {
  const classes = useStyles()
  const { t } = useTranslation('cart')
  const platformName = useStoreView('name')
  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const empty = concat(cart.devices, cart.products).length === 0
  const hasDevice = cart.devices.length > 0

  return (
    <>
      <MetaData
        key="metadata"
        title={`${t('title')} - ${platformName}`}
        robots="noindex,nofollow"
        canonical="warenkorb"
      />

      <Heading key="heading">
        <Title i18nKey="cart" />
      </Heading>

      <Section key="content" padding="80">
        <CartContent />
      </Section>
      {!empty && (
        <Section key="totals" padding="0">
          <Totals />
        </Section>
      )}
      <Section key="button" paddingTop="80" paddingBottom="80">
        {hasDevice ? (
          <div className={classes.buttonContainer}>
            <div className={classes.buttonGroup}>
              <div>
                <div className={classes.periodLabel}>{t('periodLabel')}</div>
                <PeriodSelect
                  period={cart.period}
                  onChange={p => dispatch(setPeriod(p))}
                  className={classes.periodSelect}
                />
              </div>
              <ButtonLink
                to="/kasse"
                height={64}
                className={classes.checkoutButton}
              >
                {t('button.proceedToCheckout')}
              </ButtonLink>
            </div>
          </div>
        ) : (
          <div className="grid-x grid-padding-x align-center">
            <div className="cell shrink">
              <AllProductsLink text="cart:selectAtLeastOneDevice" />
            </div>
          </div>
        )}
      </Section>
    </>
  )
}

export default Cart
