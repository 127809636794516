import get from 'lodash/get'
import React from 'react'
import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  font-size: 17px;
  color: ${colors.warmGrey};
  text-align: center;
`

const SpecsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }

  &:not(:last-child) {
    border-right: 1px solid;
    border-color: ${colors.whiteFive};
  }
`

const SpecsContent = styled.span`
  font-size: 30px;
  line-height: 0.83;
  letter-spacing: 0.4px;
  font-weight: bold;
`

const SpecsTitle = styled.span`
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.2px;
`

const Specs = ({ specs }) => (
  <Container>
    {specs
      .filter(s => get(s, 'title') && get(s, 'spec'))
      .map(({ spec, title }) => (
        <SpecsContainer key={spec}>
          <SpecsContent>{spec}</SpecsContent>
          <SpecsTitle>{title}</SpecsTitle>
        </SpecsContainer>
      ))}
  </Container>
)

Specs.propTypes = {
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      spec: PropTypes.string.isRequired,
    }),
  ),
}

Specs.defaultProps = {
  specs: [],
}

export default Specs
