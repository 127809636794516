import React from 'react'
import { createUseStyles } from 'react-jss'
import LoadingIndicator from '../Layout/LoadingIndicator'
import styles from './styles'

const useStyles = createUseStyles(styles)

const SubmitButton = ({
  customButtonClasses,
  style,
  height,
  children,
  fetching,
  disabled,
}) => {
  const classes = useStyles({})
  return (
    <div className={classes.box} style={{ ...style, height: `${height}px` }}>
      <button
        type="submit"
        disabled={disabled || fetching}
        className={`${classes.text} ${customButtonClasses}`}
      >
        {fetching ? <LoadingIndicator /> : children}
      </button>
    </div>
  )
}

SubmitButton.defaultProps = {
  height: 40,
}

export default SubmitButton
