import { createSelector } from 'reselect'
import get from 'lodash/get'
import { workplaceDevicesSelector, mapIds } from './productSelectors'

export default slug =>
  createSelector(
    workplaceDevicesSelector,
    mapIds('alsoCategories'),
    ({ pages: { list } }) => list,
    ({ storeView }) => [
      {
        slug: get(storeView, 'data.seoConfiguration.productOverviewSlug'),
        '@type': 'also_category',
      },
    ],
    (devices, categories, pages, allProducts) =>
      [...devices, ...categories, ...pages, ...allProducts].find(
        i => i.slug === slug,
      ),
  )
