import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { compose, branch, renderComponent, renderNothing } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { TeamMemberType } from 'Lib/propTypes'
import useSectionVisible from 'Hooks/useSectionVisible'
import Section from '../Layout/Section'
import MemberBox from './MemberBox'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import Slider from '../Layout/Slider'
import Title from '../Title'
import { styles } from './TestimonialSection'

const useStyles = createUseStyles(styles)

const enhance = compose(
  withRouter,
  connect(({ team: { list: members, fetching } }) => ({ members, fetching })),
  branch(({ fetching }) => fetching, renderComponent(LoadingIndicator)),
  branch(({ members }) => members.length === 0, renderNothing),
)

const TeamSection = ({ members }) => {
  const classes = useStyles()
  const visible = useSectionVisible('team')
  if (!visible) {
    return null
  }

  return (
    <Section title={<Title i18nKey="team" />}>
      <Slider>
        {members.map(member => (
          <div key={member.id}>
            <div className={classnames(classes.item)}>
              <MemberBox {...member} />{' '}
            </div>
          </div>
        ))}
      </Slider>
    </Section>
  )
}

TeamSection.propTypes = {
  members: PropTypes.arrayOf(TeamMemberType).isRequired,
}

export default enhance(TeamSection)
