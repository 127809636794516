import React, { useState } from 'react'
import { compose, branch, renderNothing } from 'recompose'
import classnames from 'classnames'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components/macro'

import colors from 'Assets/styles/colors'

const Container = styled.div`
  font-family: 'Titillium Web';
`

const HeaderContainer = styled.div`
  display: flex;
`

const HeaderItem = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  ${props =>
    props.active &&
    `
   color: ${colors.white};
  border: 0px;

  &:after {
    content: '';
    position: absolute;
    top: -4px;
    width: calc(100% + 8px);
    background-color: ${props.theme.firstColor};
    height: calc(100% + 8px);
    z-index: 2;
    border-radius: 3px;
  }
  `}
`

const HeaderItemInner = styled.a`
  padding: 0 40px;
  z-index: 4;
`

const Arrow = styled.div`
  position: absolute;
  bottom: -18px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid;
  border-top-color: ${props => props.theme.firstColor};
`

const Text = styled.div`
  padding: 40px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.78;
  letter-spacing: 0.7px;
  color: ${colors.greyishBrownThree};

  border: 2px solid ${colors.whiteSix};
`

const InformationTabs = ({ informationTabs }) => {
  const [activeTab, setActiveTab] = useState(
    get(sortBy(informationTabs, 'position'), '[0].id'),
  )

  return (
    <Container className={classnames('grid-x', 'grid-padding-x')}>
      <HeaderContainer className="cell">
        {sortBy(informationTabs, 'position').map(({ id, title }) => (
          <HeaderItem
            key={id}
            active={activeTab === id}
            onClick={() => setActiveTab(id)}
            onKeyPress={() => setActiveTab(id)}
            role="button"
          >
            <HeaderItemInner>{title}</HeaderItemInner>
            {activeTab === id && <Arrow />}
          </HeaderItem>
        ))}
      </HeaderContainer>
      <div className={classnames('cell')}>
        <Text
          dangerouslySetInnerHTML={{
            __html: get(
              informationTabs.find(({ id }) => id === activeTab),
              'content',
            ),
          }}
        />
      </div>
    </Container>
  )
}

const enhance = compose(
  branch(({ informationTabs }) => informationTabs.length === 0, renderNothing),
)

export default enhance(InformationTabs)
