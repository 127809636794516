import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'Assets/styles/colors'
import { ProductImage } from './Layout/Image'

const AccessoryContainer = styled.div`
  font-size: 14px;
  line-height: 1.47;
  letter-spacing: 0.3px;
  text-align: center;
  height: 100px;
  width: 120px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.brownishGrey};
  background-color: ${colors.white};
  padding: 4px;
  border: 2px solid ${colors.whiteSix};
  ${props =>
    props.selectable &&
    `
   cursor: pointer;
  border-color: rgba(0, 0, 0, 0);
  margin: 6px;
  `}
  ${props =>
    props.selected &&
    `
 border-color: ${props.theme.firstColor};
`}
`

const BundleBannerDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: ${props => (props.large ? '32px' : '20px')};
  padding: ${props => (props.large ? '8px' : '4px')};

  background-color: ${props => props.theme.firstColor};
  color: white;
  width: ${props => (props.large ? '65px' : '40px')};
  height: ${props => (props.large ? '60px' : '30px')};
  top: 10px;
  left: -1px;
  z-index: 2;
`

export const BundleBanner = ({ large }) => (
  <BundleBannerDiv large={large}>
    <FontAwesomeIcon icon="percent" />
  </BundleBannerDiv>
)

const Accessory = ({
  className,
  name,
  imageEntity,
  hideText,
  selected,
  selectable,
  style,
  bundled,
  hideColor,
  color,
  interactiveProps,
}) => {
  const title = `${name}${color ? ` (${color})` : ''}`
  const displayName =
    name && `${name}${color && !hideColor ? ` (${color})` : ''}`
  return (
    <AccessoryContainer
      className={className}
      selectable={selectable}
      selected={selected}
      style={style}
      {...interactiveProps}
    >
      {bundled && <BundleBanner />}
      <ProductImage
        imageEntity={imageEntity}
        title={title}
        alt={name}
        width={108}
        height={40}
      />
      {!hideText && displayName && (
        <div style={{ marginTop: '8px' }}>{displayName}</div>
      )}
    </AccessoryContainer>
  )
}

Accessory.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  hideText: PropTypes.bool,
  hideColor: PropTypes.bool,
  bundled: PropTypes.bool,
  selectable: PropTypes.bool,
  style: PropTypes.object, //eslint-disable-line
  color: PropTypes.string,
  interactiveProps: PropTypes.object, //eslint-disable-line
  imageEntity: PropTypes.string,
}

Accessory.defaultProps = {
  onClick: () => {},
  hideText: false,
  selected: false,
  imageEntity: undefined,
  selectable: false,
  style: null,
  color: null,
  hideColor: false,
  bundled: false,
  interactiveProps: {},
}

export default Accessory
