export const defaultLang = localStorage.getItem('language') || 'de'

export const initialState = {
  name: 'Surface as a Service',
  deviceAccessoryProducts: [
    {
      slug: 'surface-pen',
      icon: 'icon-stift.png',
    },
    {
      slug: 'surface-go-type-cover',
      icon: 'icon-type-cover.png',
    },
    {
      slug: 'surface-type-cover',
      icon: 'icon-type-cover.png',
    },
    {
      slug: 'surface-dock',
      icon: 'icon-surface-dock.png',
    },
    {
      slug: 'surface-dial',
      icon: 'icon-surface-dial.png',
    },
  ],
  leasingFactors: [
    {
      min: 0,
      period: 24,
      leasingFactor: 4.59,
    },
    {
      min: 0,
      period: 36,
      leasingFactor: 3.23,
    },
    {
      min: 2500,
      period: 24,
      leasingFactor: 4.49,
    },
    {
      min: 2500,
      period: 36,
      leasingFactor: 3.07,
    },
    {
      min: 5000,
      period: 24,
      leasingFactor: 4.46,
    },
    {
      min: 5000,
      period: 36,
      leasingFactor: 3.04,
    },
    {
      min: 10000,
      period: 24,
      leasingFactor: 4.44,
    },
    {
      min: 10000,
      period: 36,
      leasingFactor: 3.03,
    },
    {
      min: 25000,
      period: 24,
      leasingFactor: 4.4,
    },
    {
      min: 25000,
      period: 36,
      leasingFactor: 3.01,
    },
  ],
  consent: {},
  lang: defaultLang,
  vat: (process.env.REACT_APP_VAT || 19) / 100,
}

const SET_COOKIE_CONSENT = 'CONFIG::SET_COOKIE_CONSENT'
export const SET_LANGUAGE = 'CONFIG::SET_LANGUAGE'

export const setCookieConsent = consent => ({
  type: SET_COOKIE_CONSENT,
  payload: { consent },
})

export const setLanguage = lang => ({
  type: SET_LANGUAGE,
  payload: { lang },
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIE_CONSENT:
      return { ...state, consent: { ...action.payload.consent } }
    case SET_LANGUAGE:
      localStorage.setItem('language', action.payload.lang)
      return { ...state, lang: action.payload.lang }
    default:
      return state
  }
}

export default reducer
