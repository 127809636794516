import { ProductType } from 'Lib/alsoPropTypes'
import React from 'react'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import { variantsForProductSelector } from 'Selectors/also/productSelectors'
import InformationTabs from 'Components/Common/InformationTabs'

const DeviceDetails = ({ device }) => {
  const variants = useSelector(state =>
    variantsForProductSelector(state, { product: device.id }),
  )
  return (
    <Field
      name="WorkplaceUserDevice.variant"
      subscription={{ value: true }}
      render={({ input }) => {
        const variant = variants.find(v => v.id === input.value)

        const variantInformationTabs =
          variant && variant.informationTabs.length > 0
            ? variant.informationTabs
            : []

        const informationTabs = [
          ...sortBy(device.informationTabs, 'position'),
          ...sortBy(variantInformationTabs, 'position'),
        ]

        return (
          <InformationTabs key={variant.id} informationTabs={informationTabs} />
        )
      }}
    />
  )
}

DeviceDetails.propTypes = {
  device: ProductType.isRequired,
}

export default DeviceDetails
