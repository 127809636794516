import { normalize, schema } from 'normalizr'

const options = {
  idAttribute: 'id',
}

const category = new schema.Entity('category', {}, options)

const resellerProduct = new schema.Entity(
  'resellerProduct',
  {
    category,
  },
  options,
)
const customProduct = new schema.Entity(
  'customProduct',
  {
    category,
  },
  options,
)

category.define({
  resellerProducts: [resellerProduct],
  customProducts: [customProduct],
})

export const arrayOfResellerProducts = new schema.Array(resellerProduct)
export const arrayOfCustomProducts = new schema.Array(customProduct)
export const arrayOfCategories = new schema.Array(category)

export const normalizeResellerProducts = data =>
  normalize(data, arrayOfResellerProducts)
export const normalizeCategories = data => normalize(data, arrayOfCategories)
export const normalizeCustomProducts = data =>
  normalize(data, arrayOfCustomProducts)

// export const denormalizeWeekPlan = (data, entities) => denormalize(data, weekplan, entities)
//
// export const normalizeDayPlan = data => normalize(data, dayplan)
//
// export const denormalize = (data, entities) => denormalize(data, dayplan, entities)

// export const denormalizeModule = (data, entities) => denormalize(data, module, entities)
