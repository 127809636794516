export default {
  NO_PROTECTION_PLAN: 'Ohne Schutzbrief',
  PROTECTION_PLAN: 'Mit Schutzbrief',
  PROTECTION_PLAN_WITH_THEFT_PROTECTION:
    'Mit Schutzbrief inkl. Diebstahlschutz',
  accessories: 'Zubehör',
  addNow: 'Jetzt hinzufügen',
  add: 'Hinzufügen',
  remove: 'Entfernen',
  addToCart: 'In den Warenkorb',
  additionalOfficeCosts:
    'Die Kosten für Office 365 werden im Warenkorb hinzugefügt.',
  description: 'Beschreibung',
  monthlyPricing: 'ANGEBOT MONATLICH',
  notFoundType: 'Produkt',
  office: 'Office',
  onlineService: 'Online Service',
  withoutOnlineService: 'Ohne <0>Online Service</0>',
  period: 'Laufzeit',
  bundles: 'Promo Aktionen',
  products: 'Produkte',
  price: 'Preis',
  priceFrom: 'ab',
  protectionPlan: 'Schutzbrief',
  serviceExtension: 'Service-Erweiterung',
  serviceExtensionLabel: '{{term}} Jahre {{name}}',
  dummyExtension: '{{term}} Jahre {{includedServiceExtension}} (inklusive)',
  goToProduct: 'Zum Produkt',
  rentNow: 'Jetzt mieten',
  moreAccessories: 'Weiteres Zubehör',
  addMoreAccessories: 'Weiteres Zubehör hinzufügen',
  rentalPeriod: 'Mietlaufzeit',
  selectPlaceholder: 'Bitte wählen',
  removedAccessory:
    'Das gewählte Zubehör {{fieldName}} ist für diese Laufzeit nicht verfügbar und wurde entfernt.',
  button: {
    moreAccessories: 'Mehr Zubehör anzeigen',
    hide: 'Ausblenden',
  },
  validation: {
    selectAttributes: 'Bitte wählen Sie Attribute',
    selectPeriod: 'Bitte wählen Sie eine Laufzeit',
    selectVariant: 'Bitte wählen Sie eine Variante',
  },
  variants: 'Produktvarianten',
  alsoFieldNames: {
    'Workplace User Device': 'Arbeitsplatzgerät',
    'Docking Station': 'Docking Station',
    Display: 'Monitore',
    Printer: 'Drucker',
    'Keyboard and Mouse': 'Tastatur und Maus',
    'Additional Accessories': 'Weiteres Zubehör',
    Tablet: 'Tablet',
    Scanner: 'Scanner',
    Headset: 'Headset',
    'Add-On Keyboard': 'Add-On Keyboard',
  },
}
