import classnames from 'classnames'
import { FORM_ERROR } from 'final-form'
import { isValidBIC, isValidIBAN } from 'ibantools'
import { fetchCustomerData, updateCustomerData } from 'Lib/fetch'
import history from 'Lib/history'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import ReactTimeout from 'react-timeout'
import { compose } from 'recompose'
import SubmitButton from '../Button/SubmitButton'
import BankDetailsForm from '../Checkout/BankDetailsForm'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import Section from '../Layout/Section'

const validate = values => {
  const errors = { bankDetails: {} }

  if (values.bankDetails.IBAN && !isValidIBAN(values.bankDetails.IBAN)) {
    errors.bankDetails.IBAN = 'invalidIBAN'
  }
  if (values.bankDetails.BIC && !isValidBIC(values.bankDetails.BIC)) {
    errors.bankDetails.BIC = 'invalidBIC'
  }

  return errors
}

const BankDetails = ({ setTimeout }) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [customerData, setCustomerData] = useState({
    addresses: [],
  })

  const fetchData = async () => {
    try {
      const res = await fetchCustomerData()
      setCustomerData(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.push('/account/login')
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const toggleSuccessMessage = () => {
    setSuccess(!success)
  }

  const onSubmit = async values => {
    try {
      await updateCustomerData(values)
      toggleSuccessMessage()
      setTimeout(toggleSuccessMessage, 1000)
    } catch (error) {
      return { [FORM_ERROR]: t('feedback.genericError') }
    }
  }

  if (!customerData.bankDetails) {
    return (
      <Section>
        <LoadingIndicator />
      </Section>
    )
  }

  return (
    <div className="grid-x grid-padding-x">
      <div className="cell small-12">
        <Form
          onSubmit={onSubmit}
          initialValues={customerData}
          validate={validate}
          render={({ handleSubmit, submitError, submitting }) => (
            <form
              onSubmit={handleSubmit}
              className={classnames('checkout', 'small-guter-form')}
              name="checkout"
            >
              <BankDetailsForm source="bankDetails" />

              <SubmitButton
                style={{ marginTop: '1em' }}
                height={59}
                fetching={submitting}
                disabled={submitting || success}
              >
                {success
                  ? t('common:feedback.saved')
                  : t('common:actions.save')}
              </SubmitButton>
              {submitError && (
                <div>
                  <span className="authError">{submitError}</span>
                </div>
              )}
            </form>
          )}
        />
      </div>
    </div>
  )
}

const enhance = compose(withRouter, ReactTimeout)

export default enhance(BankDetails)
