import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import {
  Box,
  HalfContainer,
  Price,
  PriceText,
  Quantity,
} from './StyledComponents'

const PriceButton = ({
  fetching,
  style,
  priceField,
  children,
  type,
  exact,
  className,
  hideAngle,
  loading,
}) => {
  const { t } = useTranslation('catalog')
  return (
    <Box className={className} style={{ ...style }}>
      <HalfContainer>
        <Field
          name="quantity"
          render={({ input }) => {
            const increaseQuantity = () => input.onChange(input.value + 1)
            const decreaseQuantity = () =>
              input.onChange(Math.max(input.value - 1, 1))
            return (
              <Quantity
                increment={increaseQuantity}
                decrement={decreaseQuantity}
                quantity={input.value}
              />
            )
          }}
        />
        {fetching ? (
          <LoadingIndicator small />
        ) : (
          <Price>
            {!exact && (
              <span>
                {t('priceFrom')}
                &nbsp;
              </span>
            )}

            {priceField}
          </Price>
        )}
      </HalfContainer>
      <HalfContainer>
        <PriceText as="button" type={type}>
          {children}
          {!hideAngle &&
            (loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '4px' }}
                icon="circle-notch"
                spin
              />
            ) : (
              <FontAwesomeIcon
                style={{ marginLeft: '4px' }}
                icon="angle-right"
              />
            ))}
        </PriceText>
      </HalfContainer>
    </Box>
  )
}

PriceButton.propTypes = {
  fetching: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line
  height: PropTypes.number,
  children: PropTypes.node.isRequired, // eslint-disable-line
  type: PropTypes.string,
  exact: PropTypes.bool,
  className: PropTypes.string,
  hideAngle: PropTypes.bool,
  loading: PropTypes.bool,
}

PriceButton.defaultProps = {
  height: 40,
  exact: false,
  loading: false,
  fetching: false,
  hideAngle: false,
  type: 'button',
  className: undefined,
  style: {},
}

PriceButton.defaultProps = {
  hollow: false,
}

export default PriceButton
