import React from 'react'
import { useTranslation } from 'react-i18next'
import MetaData from '../Layout/MetaData'

const NotFound = () => {
  const { t } = useTranslation('common')
  return (
    <div>
      <MetaData title="NotFound" robots="noindex,nofollow" />
      {t('pageNotFound')}
    </div>
  )
}

export default NotFound
