import React from 'react'
import PropTypes from 'prop-types'
import Input from './Input'

const PasswordForm = ({ password, setPassword, passwordProtected }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <Input
      placeholder="Passwort"
      type="text"
      input={{
        value: password,
        onChange: ({ target: { value } }) => {
          if (value === passwordProtected) {
            localStorage.setItem('password', value)
          }
          setPassword(value)
        },
      }}
    />
  </div>
)

PasswordForm.propTypes = {
  password: PropTypes.string.isRequired,
  passwordProtected: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
}

export default PasswordForm
