import color from 'color'
import colors from 'Assets/styles/colors'
import typography from '../Layout/typography'

export default theme => ({
  fieldGroupHeader: {
    ...typography.h2,
    marginBottom: 16,
  },
  fieldGroupComment: {
    marginTop: -16,
    marginBottom: 16,
    color: colors.greyishBrownEight,
  },
  form: {
    // margin: [-24, 0],
    // '& > *': {
    //   margin: [24, 0],
    //   padding: [24, 0],
    // },
    '& .is-focused:not(.is-open) > .Select-control': {
      boxShadow: `0 0 5px ${theme.firstColor}`,
      border: `1px solid ${theme.firstColor}`,
    },
    '& .Select-option': {
      '&.is-selected': {
        backgroundColor: color(theme.firstColor)
          .fade(0.5)
          .string(),
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '&.is-focused': {
        backgroundColor: color(theme.firstColor)
          .fade(0.3)
          .string(),
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
  fieldGroup: {
    margin: ['.25rem', 0],
    '& > *': {
      margin: ['.25rem', 0],
    },
  },
  fieldSection: {
    padding: [24, 0],
    // margin: [0, '.25rem'],
    // '& > *': {
    //   margin: [0, '-.25rem'],
    // },
  },
  primaryColorLabel: {
    color: theme.firstColor,
  },
  copyToCeoButton: {
    marginTop: '.7em',
    fontSize: '18px',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.firstColor,
  },
  text: {
    marginTop: '16px',
    fontSize: '18px',
    fontWeight: '300',
    color: colors.greyishBrownEight,
    '& a': {
      color: theme.firstColor,
      textDecoration: 'none',
    },
  },
})
