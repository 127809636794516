import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import color from 'color'
import React from 'react'
import { createUseStyles } from 'react-jss'

export const inputStyles = theme => ({
  container: {
    backgroundColor: '#ffffff',
    color: colors.warmGreyFive,
    fontSize: '18px',
    fontWeight: '300',

    border: {
      style: 'solid',
      width: '1px',
      color: colors.pinkishGreyTwo,
    },

    borderRadius: '3px',
    '&.error input': {
      boxShadow: `0 0 5px ${theme.errorColor}`,
      border: `1px solid ${theme.errorColor}`,
    },
  },
  disabledContainer: {
    extends: 'container',
    borderColor: colors.whiteFour,
  },
  input: {
    height: 57,
    textAlign: 'left',
    border: 0,
    letterSpacing: '0.4px',
    lineHeight: '57px',
    paddingLeft: '8px',

    borderRadius: '3px',
    color: colors.warmGreyFive,
    outline: 'none',
    '&::placeholder': {
      color: colors.pinkishGrey,
    },
    '&:focus': {
      boxShadow: `0 0 5px ${theme.firstColor}`,
      border: `1px solid ${theme.firstColor}`,
    },
  },
  textInput: {
    extend: 'input',
  },
  textInputIcon: {
    extend: 'input',
    paddingLeft: '48px',
  },
  disabled: {
    extend: 'input',
    color: colors.pinkishGreyTwo,
  },
  checkboxLabel: {
    marginLeft: 8,
    fontSize: 18,
    color: colors.greyishBrownEight,
    '& a': {
      color: theme.firstColor,
      textDecoration: 'none',
    },
  },
  selectInput: {
    '& .r-select__single-value': {
      color: colors.warmGreyFive,
      fontSize: '18px',
    },
    '& .r-select__option--is-selected': {
      backgroundColor: color(theme.firstColor)
        .fade(0.5)
        .string(),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .r-select__option--is-focused': {
      backgroundColor: color(theme.firstColor)
        .fade(0.3)
        .string(),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .r-select__control--is-focused, & .r-select__control--is-focused:hover': {
      border: `1px solid ${theme.firstColor}`,
      boxShadow: `0 0 5px ${theme.firstColor}`,
    },
    '& .r-select__menu': {
      marginTop: 0,
      borderRadius: '0 0 3px 3px',
    },
  },
  error: {
    color: theme.errorColor,
    '& .r-select__control': {
      boxShadow: `0 0 5px ${theme.errorColor}`,
      border: `1px solid ${theme.errorColor}`,
    },
  },
  darkError: {
    color: colors.white,
  },
  primaryColorLabel: {
    color: theme.firstColor,
  },
})

const useStyles = createUseStyles(inputStyles)

const Input = ({
  input,
  placeholder,
  style,
  expand,
  icon,
  className,
  disabled,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classnames(
        classes.container,
        { [classes.disabledContainer]: disabled },
        className,
      )}
    >
      <input
        style={{
          ...style,
          width: expand ? '100%' : 'auto',
          backgroundImage: icon ? `url(/assets/icons/${icon}.svg)` : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '20px',
          backgroundPositionY: 'center',
          backgroundPositionX: '16px',
        }}
        className={classnames({
          [classes.disabled]: disabled,
          [classes.textInputIcon]: icon,
          [classes.textInput]: !icon,
        })}
        placeholder={placeholder}
        disabled={disabled}
        {...input}
      />
    </div>
  )
}

export default Input
