import React from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import classnames from 'classnames'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import { footerBarLinkSelector } from 'Selectors/common/linkSelectors'
import styles from './styles'
import Link from '../../Link'

const mapStateToProps = state => ({
  links: footerBarLinkSelector(state),
  resellerName: get(state.resellerInfo, 'data.name'),
})

const useStyles = createUseStyles(styles)

const enhance = compose(connect(mapStateToProps))

const FooterLinkBar = ({ resellerName, links }) => {
  const classes = useStyles()

  return (
    <div>
      <Media query="(min-width: 1024px)">
        <div className={classes.footerText}>
          <span>
            &copy; {new Date().getFullYear()} {resellerName}. All rights
            reserved.
          </span>
          {links.map(({ id, ...link }) => (
            <Link key={id} link={link} />
          ))}
        </div>
      </Media>

      <Media query="(max-width: 1023px)">
        <div
          className={classnames(
            'grid-x',
            'small-up-1',
            'large-up-3',
            classes.footerText,
          )}
        >
          <div className="cell">
            <span>
              &copy; {new Date().getFullYear()} {resellerName}. All rights
              reserved.
            </span>
          </div>
          {links.map(({ id, ...link }) => (
            <div key={id} className="cell">
              <Link link={link} />
            </div>
          ))}
        </div>
      </Media>
    </div>
  )
}

FooterLinkBar.propTypes = {
  resellerName: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired, //eslint-disable-line
}

export default enhance(FooterLinkBar)
