import React from 'react'
import { useTranslation } from 'react-i18next'
import { branch, compose, renderNothing } from 'recompose'
import PropTypes from 'prop-types'
import { specialLinkSelector } from 'Selectors/common/linkSelectors'
import { connect } from 'react-redux'
import Link from './Link'

const SpecialLink = ({ style, type, link }) => {
  const { t } = useTranslation('common')
  return (
    <Link
      link={{ ...link, name: t(`common:${type}`) }}
      target="_blank"
      style={style}
    />
  )
}

SpecialLink.propTypes = {
  type: PropTypes.string.isRequired,
}

const mapStateToProps = (state, { type }) => ({
  link: specialLinkSelector(type)(state),
})

const enhance = compose(
  connect(mapStateToProps),
  branch(({ link }) => !link, renderNothing),
)

export default enhance(SpecialLink)
