import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import QuantitySpinner from 'Components/Common/QuantitySpinner'
import {
  Container,
  PriceContainer,
  QuantityContainer,
  Trash,
} from 'Components/Common/Cart/LineStyledComponents'

/* eslint-disable react/style-prop-object */

const Line = ({ quantity, increment, decrement, remove, price, children }) => (
  <Container className={classnames('grid-x', 'cart-line')}>
    <QuantityContainer className={classnames('cell')}>
      <Trash onClick={remove} role="button">
        <FontAwesomeIcon icon="trash" />
      </Trash>
      <QuantitySpinner
        style={{ width: '70%' }}
        quantity={quantity}
        increment={increment}
        decrement={decrement}
      />
    </QuantityContainer>
    <div className="cell auto">{children}</div>
    <div className="cell medium-2">
      <PriceContainer>
        <FormattedNumber value={price} style="currency" currency="EUR" />
      </PriceContainer>
    </div>
  </Container>
)

Line.propTypes = {
  quantity: PropTypes.number.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default Line
