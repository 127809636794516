import max from 'lodash/max'

export const START_FETCH = 'START_FETCH'
export const STOP_FETCH = 'STOP_FETCH'

export const startFetch = identifier => ({
  type: START_FETCH,
  payload: { identifier },
})

export const stopFetch = identifier => ({
  type: STOP_FETCH,
  payload: { identifier },
})

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case START_FETCH:
      return {
        ...state,
        [payload.identifier]: state[payload.identifier]
          ? state[payload.identifier] + 1
          : 1,
      }
    case STOP_FETCH:
      return {
        ...state,
        [payload.identifier]: state[payload.identifier]
          ? max([state[payload.identifier] - 1, 0])
          : 0,
      }
    default:
      return state
  }
}

export default reducer
