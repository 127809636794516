import React from 'react'
import { Helmet } from 'react-helmet'
import useStoreView from 'Hooks/useStoreView'

const OutdatedBrowserStyle = () => {
  const color = useStoreView('outdatedBrowserBackgroundColor')
  if (!color) {
    return null
  }
  return (
    <Helmet>
      <style>{`
    #outdated, #buttonUpdateBrowser {
        background-color: ${color} !important;
    }`}</style>
    </Helmet>
  )
}

export default OutdatedBrowserStyle
