import classnames from 'classnames'
import flatMap from 'lodash/flatMap'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Media from 'react-media'
import { useSelector } from 'react-redux'
import Title from 'Components/Common/Title'
import Section from 'Components/Common/Layout/Section'
import Slider from 'Components/Common/Layout/Slider'
import DeviceBox from './DeviceBox'

const useStyles = createUseStyles({
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const AluxoDeviceBoxSection = () => {
  const classes = useStyles()
  const devices = useSelector(({ devices: { list: devices } }) =>
    flatMap(
      devices,
      p =>
        p.variants &&
        p.variants
          .filter(v => v.featured && v.active)
          .map(v => ({ device: p, variant: v })),
    ),
  )

  if (devices.length === 0) {
    return null
  }

  return (
    <Media query="(max-width: 640px)">
      {matches => (
        <Section
          paddingTop={matches ? '0' : undefined}
          title={<Title i18nKey="featuredProducts" />}
        >
          <Slider perRow={4}>
            {devices
              .sort((a, b) => a.variant.netPrice - b.variant.netPrice)
              .map(({ device, variant }) => (
                <div key={variant.id}>
                  <div className={classnames(classes.item)}>
                    <DeviceBox device={device} variant={variant} />
                  </div>
                </div>
              ))}
          </Slider>
        </Section>
      )}
    </Media>
  )
}

export default AluxoDeviceBoxSection
