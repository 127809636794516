import NotificationContext from 'Context/NotificationContext'
import React, { useContext } from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const NotificationsContainer = styled.div`
  width: 100%;
  position: relative;
`

const InnerContainer = styled.div`
  position: absolute;
  width: 100%;
`
const StyledNotification = styled.div`
  padding: 4px;
  width: 100%;
  color: ${props => props.theme.firstColor};
  display: flex;
  justify-content: center;
`

const CloseButton = styled.button`
  right: 2em;
  margin-left: 1em;
  color: ${props => props.theme.firstColor};
  cursor: pointer;
`

const Notification = ({ style, id, message, removeNotification }) => (
  <StyledNotification style={style}>
    {message}
    <CloseButton onClick={() => removeNotification(id)}>x</CloseButton>
  </StyledNotification>
)

Notification.propTypes = {
  style: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  removeNotification: PropTypes.func.isRequired,
}

const AnimatedNotification = animated(Notification)

const Notifications = () => {
  const { notifications, removeNotification } = useContext(NotificationContext)

  const transitions = useTransition(notifications, item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <NotificationsContainer>
      <InnerContainer>
        {transitions.map(({ item, props, key }) => (
          <AnimatedNotification
            key={key}
            style={props}
            {...item}
            removeNotification={removeNotification}
          />
        ))}
      </InnerContainer>
    </NotificationsContainer>
  )
}

export default Notifications
