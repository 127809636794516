import useStoreView from 'Hooks/useStoreView'

const useStoreViewNotes = () => {
  const notes = useStoreView('notes')

  return notes.reduce(
    (acc, item) => ({
      ...acc,
      [item.noteKey]: item.content,
    }),
    {},
  )
}

export default useStoreViewNotes
