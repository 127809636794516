import colors from 'Assets/styles/colors'
import CategoryLinks from 'Components/Aluxo/CategoryPage/CategoryLinks'
import Device from 'Components/Aluxo/CategoryPage/Device'
import useStoreView from 'Hooks/useStoreView'
import concat from 'lodash/concat'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import useMedia from 'use-media'
import Heading from 'Components/Common/Layout/Heading'
import MetaData from 'Components/Common/Layout/MetaData'
import Section from 'Components/Common/Layout/Section'
import Title from 'Components/Common/Title'
import useProductSearch from 'Hooks/useProductSearch'
import NoResults from 'Components/Common/CategoryPage/NoResults'
import SearchSection from 'Components/Common/CategoryPage/Search'
import orderBy from 'lodash/orderBy'
import slice from 'lodash/slice'

const Text = styled.div`
  color: ${colors.brownishGrey};
  font-weight: 300;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.7px;
  margin-top: 78px;
`

const CategoryPage = ({
  match: {
    params: { slug },
  },
}) => {
  const { categories, devices, isAffiliateReseller } = useSelector(
    ({
      categories: { list: categories },
      devices: { list: devices },
      resellerInfo,
    }) => ({
      categories,
      devices,
      isAffiliateReseller: get(resellerInfo, 'data.affiliateReseller'),
    }),
  )

  const category = categories.find(c => c.slug === slug)

  const filteredDevices = devices.filter(p =>
    category ? p.category === category.id : true,
  )

  const { results, filter, setFilter } = useProductSearch(filteredDevices)

  const allDevices = concat(
    flatMap(results, device => {
      const variants = slice(
        orderBy(device.variants, ['active', 'netPrice'], ['desc', 'asc']),
        0,
        1,
      )
      return variants.map(variant => ({ variant, device }))
    }),
  )

  const productOverviewMetadata = useStoreView(
    'seoConfiguration.productOverviewMetadata',
  )
  const productOverviewSlug = useStoreView(
    'seoConfiguration.productOverviewSlug',
  )
  const productOverviewSeoText = useStoreView(
    'seoConfiguration.productOverviewSeoText',
  )

  const { t } = useTranslation('catalog')

  const isMobile = useMedia({ maxWidth: 1024 })

  return (
    <>
      <MetaData
        title={category ? category.name : t('products')}
        metaData={category ? category.metaData : productOverviewMetadata}
        canonical={category ? category.slug : productOverviewSlug}
        robots={isAffiliateReseller && 'noindex'}
      />
      <Heading>
        {category ? category.name : <Title i18nKey="category" />}
      </Heading>
      {(!isMobile || category) && (
        <SearchSection filter={filter} setFilter={setFilter} />
      )}
      <CategoryLinks
        categories={categories}
        categorySelected={Boolean(category)}
      />
      {(!isMobile || category) && (
        <Section paddingTop={isMobile ? '8' : '80'} paddingBottom="40">
          {filter !== '' && results.length === 0 ? (
            <NoResults filter={filter} />
          ) : (
            sortBy(
              allDevices,
              'variant.netPrice',
            ).map(({ device, variant }) => (
              <Device key={variant.id} device={device} variant={variant} />
            ))
          )}
        </Section>
      )}
      <Section paddingTop="40" paddingBottom="80">
        <Text
          dangerouslySetInnerHTML={{
            __html: category ? category.description : productOverviewSeoText,
          }}
        />
      </Section>
    </>
  )
}

CategoryPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default withRouter(CategoryPage)
