import colors from 'Assets/styles/colors'

const style = theme => ({
  dots: {
    color: theme.firstColor,
    '@media (min-width: 1400px)': {
      position: 'absolute',
    },
    bottom: '-60px',
    display: 'block',
    width: '100%',
    padding: '0',
    margin: '0',
    listStyle: 'none',
    textAlign: 'center',
    '& li': {
      position: 'relative',

      display: 'inline-block',

      width: '20px',
      height: '20px',
      margin: '0 -2px',
      padding: '0',

      '&.slick-active button:before': {
        opacity: '0.75',
        color: theme.firstColor,
      },

      cursor: 'pointer',
      '& button': {
        fontSize: '0',
        lineHeight: '0',
        display: 'block',
        width: '20px',
        height: '20px',
        padding: '5px',
        cursor: 'pointer',
        color: 'transparent',
        border: '0',
        outline: 'none',
        background: 'transparent',
        '&:before': {
          fontFamily: 'slick',
          fontSize: '12px',
          lineHeight: '20px',

          position: 'absolute',
          top: '0',
          left: '0',

          width: '20px',
          height: '20px',

          content: '"•"',
          textAlign: 'center',

          opacity: '.25',
          color: colors.whiteSix,

          webkitFontSmoothing: 'antialiased',
          mozOsxFontSmoothing: 'grayscale',
        },

        '&:hover, &:focus': {
          outline: 'none',
          '&:before': {
            opacity: 1,
          },
        },
      },
    },
  },
})

export default style
