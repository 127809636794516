import React, { useReducer, useContext } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageContext = React.createContext()
const { Provider } = LanguageContext

const reducer = (state, action) => {
  switch (action.type) {
    case 'setLanguage':
      return { language: action.language }

    default:
      return state
  }
}

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation()
  return (
    <Provider value={useReducer(reducer, { language: i18n.language })}>
      {children}
    </Provider>
  )
}

export const useLanguageStateValue = () => useContext(LanguageContext)
