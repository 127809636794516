import mailIcon from 'Assets/images/mail-icon.png'
import colors from 'Assets/styles/colors'
import { TeamMemberShape } from 'Lib/propTypes'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Image from '../Layout/Image'

const styles = theme => ({
  box: {
    width: 350,
    height: 372,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: colors.greyish29,
    borderRadius: '3px',
    alignItems: 'center',
  },
  iconContainer: {
    width: '100%',
    height: 284,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.firstColor,
  },
  text: {
    display: 'block',
    letterSpacing: '0.4px',
    color: colors.white,
    textAlign: 'center',
  },
  name: {
    extend: 'text',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: '1.19',
  },
  companyPosition: {
    extend: 'text',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '1.33',
  },
  mail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    backgroundColor: theme.darkerFirstColor,
    border: 'solid 5px #ffffff',
    borderRadius: '48px',
    position: 'absolute',
    right: '-24px',
  },
  mailIcon: {
    display: 'block',
  },
  details: {
    height: '88px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.darkerFirstColor,
    position: 'relative',
  },
})

const useStyles = createUseStyles(styles)

const MemberBox = ({ image, name, email, companyPosition }) => {
  const classes = useStyles()

  return (
    <div className={classes.box}>
      <div className={classes.iconContainer}>
        <Image src={image} alt={name} width={150} height={230} />
      </div>
      <div className={classes.details}>
        <span className={classes.name}>{name}</span>
        <span className={classes.companyPosition}>{companyPosition}</span>
        <div className={classes.mail}>
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <img src={mailIcon} alt={email} />
          </a>
        </div>
      </div>
    </div>
  )
}

MemberBox.propTypes = {
  ...TeamMemberShape,
}

export default MemberBox
