import { put, select, takeLatest } from 'redux-saga/effects'

import {
  PRODUCT_SET_ATTRIBUTE,
  PRODUCT_SET_PRODUCT,
  setAttributeSets,
  setSelectedProduct,
  updateAttributeSets as updateAttributeSetsAction,
} from 'Ducks/common/productConfiguration'

function* updateAttributeSets() {
  const state = yield select()
  const product = state.products.list.find(
    ({ id }) => id === state.productConfiguration.product,
  )
  if (!product) {
    return
  }

  const attributeSets = product.productVariants.reduce(
    (attributeSets, product) => {
      product.attributeValues.forEach(({ attribute, value }) => {
        const set = attributeSets.find(set => set.attribute === attribute)
        if (!set) {
          attributeSets.push({
            attribute,
            values: [{ value, disabled: false }],
          })
        } else if (!set.values.find(({ value: v }) => v === value)) {
          set.values.push({ value, disabled: false })
        }
      })
      return attributeSets
    },
    [],
  )
  yield put(setAttributeSets(attributeSets))
}

function* updateAvailableAttributes() {
  const { products, productConfiguration } = yield select()
  const product = products.list.find(
    ({ id }) => id === productConfiguration.product,
  )
  if (!product) {
    return
  }

  const sets = productConfiguration.attributeSets.map(
    ({ attribute, values }) => {
      const filteredSelectedAttributes = productConfiguration.selectedAttributes.filter(
        selectedAttribute => selectedAttribute.attribute !== attribute,
      )

      const availableProducts = product.productVariants.filter(productVariant =>
        filteredSelectedAttributes.reduce(
          (acc, { attribute, value }) =>
            acc &&
            (!value ||
              productVariant.attributeValues.find(
                attrValue =>
                  attrValue.attribute === attribute &&
                  attrValue.value === value,
              )),
          true,
        ),
      )

      const attributeSets = availableProducts.reduce(
        (attributeSets, product) => {
          product.attributeValues.forEach(({ attribute, value }) => {
            const set = attributeSets.find(set => set.attribute === attribute)

            if (!set) {
              attributeSets.push({
                attribute,
                values: [{ value, disabled: false }],
              })
            } else if (!set.values.find(({ value: v }) => v === value)) {
              set.values.push({ value, disabled: false })
            }
          })
          return attributeSets
        },
        [],
      )

      return {
        attribute,
        values: [
          ...values.map(v => ({
            ...v,
            disabled: !attributeSets
              .find(set => set.attribute === attribute)
              .values.find(v2 => v2.value === v.value),
          })),
        ],
      }
    },
  )

  yield put(updateAttributeSetsAction(sets))
}

function* findSelectedProduct() {
  const state = yield select()
  const {
    products: { list: products },
    productConfiguration,
  } = state

  const product = products.find(p => p.id === productConfiguration.product)

  const productVariant = product.productVariants.find(p =>
    p.attributeValues.reduce(
      (prev, { attribute, value }) =>
        prev &&
        productConfiguration.selectedAttributes.find(
          selectedAttribute =>
            selectedAttribute.attribute === attribute &&
            selectedAttribute.value === value,
        ),
      true,
    ),
  )

  yield put(setSelectedProduct(productVariant))
}

function* updateProductConfigurationPriceSaga() {
  yield takeLatest(PRODUCT_SET_PRODUCT, updateAttributeSets)
  yield takeLatest(PRODUCT_SET_PRODUCT, findSelectedProduct)
  yield takeLatest(PRODUCT_SET_ATTRIBUTE, updateAvailableAttributes)
  yield takeLatest(PRODUCT_SET_ATTRIBUTE, findSelectedProduct)
}

export default updateProductConfigurationPriceSaga
