import AccessorySelect from 'Components/Common/Device/AccessorySelect'
import Slider from 'Components/Common/Device/AccessorySlider'
import ListItem from 'Components/Common/Device/ListItem'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { accessoryForVariantSelector } from 'Selectors/common/accessorySelectors'

const DeviceDetailsFormAccessories = ({ device, variant, input }) => {
  const deviceAccessoryProducts = useSelector(state =>
    accessoryForVariantSelector(state, { device, variant }),
  )
  const { t } = useTranslation('catalog')

  const {
    values: { qualifiedBundles },
  } = useFormState({ subscription: { values: true } })

  if (deviceAccessoryProducts.length <= 0) return null

  return (
    <ListItem header={t('accessories')}>
      <Slider>
        {deviceAccessoryProducts.map(({ product, variant }) => {
          const bundled = qualifiedBundles.some(b =>
            b.accessories.some(p => p.id === variant.id),
          )

          const handleSelect = input.value.some(
            acc => acc.variant === variant.id,
          )
            ? () =>
                input.onChange(
                  input.value.filter(acc => acc.variant !== variant.id),
                )
            : () =>
                input.onChange([
                  ...input.value,
                  {
                    product: product.id,
                    variant: variant.id,
                    quantity: 1,
                    netPrice: variant.netPrice,
                  },
                ])
          return (
            <div key={variant.id}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AccessorySelect
                  bundled={bundled}
                  selected={input.value.some(acc => acc.variant === variant.id)}
                  toggleSelected={bundled ? () => {} : handleSelect}
                  key={variant.id}
                  product={product}
                  variant={variant}
                />
              </div>
            </div>
          )
        })}
      </Slider>
    </ListItem>
  )
}

DeviceDetailsFormAccessories.propTypes = {
  device: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

export default DeviceDetailsFormAccessories
