import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import Select from 'react-select'
import { inputStyles } from '../Input'

const useStyles = createUseStyles(inputStyles)

const SelectField = ({
  searchable,
  placeholder,
  clearable,
  options,
  input: { value, onChange, name },
  meta: { touched, error, submitError, warning },
}) => {
  const { t } = useTranslation('validation')
  const classes = useStyles()
  const handleChange = ({ value }) => onChange(value)
  const selected = options.find(v => v.value === value)
  return (
    <div
      data-select
      data-name={name}
      className={classnames(classes.selectInput, {
        [classes.error]: touched && (error || submitError),
      })}
    >
      <Select
        className="r-select"
        classNamePrefix="r-select"
        isSearchable={searchable}
        placeholder={placeholder}
        value={selected}
        isClearable={clearable}
        options={options}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            height: '100%',
            minHeight: 57,
            maxHeight: 59,
            borderRadius: state.menuIsOpen ? '3px 3px 0 0' : 3,
          }),
        }}
      />
      {touched &&
        (((error || submitError) && (
          <span className="error">
            {t(`validation:error.${error || submitError}`)}
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  )
}

SelectField.propTypes = {
  searchable: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  clearable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  input: PropTypes.object.isRequired, //eslint-disable-line
  meta: PropTypes.object.isRequired, //eslint-disable-line
}

SelectField.defaultProps = {
  searchable: false,
  clearable: false,
}

export default SelectField
