import PropTypes from 'prop-types'

export const TestimonialShape = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
}

export const TestimonialType = PropTypes.shape(TestimonialShape)

export const TeamMemberShape = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
}

export const TeamMemberType = PropTypes.shape(TeamMemberShape)

export const MonthlyPriceShape = {
  id: PropTypes.string.isRequired,
  period: PropTypes.number.isRequired,
  netPrice: PropTypes.number.isRequired,
}

export const MonthlyPriceType = PropTypes.shape(MonthlyPriceShape)

export const DeviceImageShape = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  thumbnail: PropTypes.string,
  cartThumbnail: PropTypes.string,
}

export const DeviceImageType = PropTypes.shape(DeviceImageShape)

export const DeviceShape = {
  id: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(DeviceImageType).isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export const DeviceConfigurationShape = {
  variant: PropTypes.string,
  service: PropTypes.string,
  protectionPlan: PropTypes.string,
  protectionPlanType: PropTypes.string.isRequired,
  period: PropTypes.number,
  quantity: PropTypes.number.isRequired,
}

export const DeviceType = PropTypes.shape(DeviceShape)

export const VariantShape = {
  '@id': PropTypes.string.isRequired,
  '@type': PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  netPrice: PropTypes.number.isRequired,
  reseller: PropTypes.string.isRequired,
  sku: PropTypes.string,
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      spec: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  stock: PropTypes.number.isRequired,
}

export const VariantType = PropTypes.shape(VariantShape)

export const AttributeValueShape = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
}

export const AttributeValueType = PropTypes.shape(AttributeValueShape)

export const ProductVariantShape = {
  id: PropTypes.string.isRequired,
  sku: PropTypes.string,
  attributeValues: PropTypes.arrayOf(AttributeValueType).isRequired,
  netPrice: PropTypes.number.isRequired,
}

export const ProductVariantType = PropTypes.shape(ProductVariantShape)

export const CustomProductShape = {
  id: PropTypes.string.isRequired,
  sku: PropTypes.PropTypes.string,
  shortDescription: PropTypes.string,
  vat: PropTypes.number.isRequired,
  directDelivery: PropTypes.bool.isRequired,
  // informationTabs: [],
  category: PropTypes.string,
  cartThumbnail: PropTypes.string,
  thumbnail: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  productVariants: PropTypes.arrayOf(ProductVariantType).isRequired,
  assignedDevices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  isAccessory: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
}

export const CustomProductType = PropTypes.shape(CustomProductShape)

export const DeviceConfigurationServiceType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
})

export const ServiceShape = {
  id: PropTypes.string.isRequired,
  netPrice: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
}

export const ServiceType = PropTypes.shape(ServiceShape)
