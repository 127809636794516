import { flatMap, get, min, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import ButtonLink from 'Components/Common/Button/ButtonLink'
import Item from './Item'

const mapStateToProps = (state, { device: { variants } }) => {
  const monthlyPrices = flatMap(variants, id =>
    Object.values(state.alsoProductVariants.entities[id].monthlyPrices),
  )

  const minRate = min(monthlyPrices.filter(p => p > 0))

  const firstVariantId = variants[0]
  const variantName = state.alsoProductVariants.entities[firstVariantId].name

  return { minRate, variantName }
}

const enhance = compose(connect(mapStateToProps))

/* eslint-disable react/style-prop-object */

const DeviceVariant = ({
  device: { images, name, slug, manufacturer, shortDescription, variants },
  minRate,
  variantName,
}) => {
  const { t } = useTranslation('catalog')
  return (
    <Item
      imageEntity={get(sortBy(images, 'position'), '0.@id')}
      name={name}
      variantName={
        variants.length > 1
          ? 'In verschiedenen Ausführungen verfügbar'
          : variantName
      }
      slug={slug}
      description={shortDescription}
      manufacturer={manufacturer}
      rate={minRate}
      link={
        <ButtonLink to={{ pathname: `/${slug}`, state: {} }}>
          {t('goToProduct')}
        </ButtonLink>
      }
    />
  )
}

DeviceVariant.propTypes = {
  minRate: PropTypes.number.isRequired,
  device: PropTypes.shape({
    images: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    variants: PropTypes.array.isRequired,
  }).isRequired,
}

DeviceVariant.defaultProps = {}

export default enhance(DeviceVariant)
