import React from 'react'
import PropTypes from 'prop-types'
import { branch, compose, renderComponent } from 'recompose'
import { Link as RouterLink } from 'react-router-dom'
import isUrl from 'validator/lib/isURL'

const Link = ({ link: { name, to = '/' }, className, style, target }) => (
  <RouterLink to={to} className={className} target={target} style={style}>
    {name}
  </RouterLink>
)

Link.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.object, //eslint-disable-line
  target: PropTypes.string,
}

Link.defaultProps = {
  className: null,
  style: {},
  target: undefined,
}

const ExternalLink = ({ link: { name, to = '/' }, className, style }) => (
  <a
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
    style={style}
  >
    {name}
  </a>
)

ExternalLink.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.object, //eslint-disable-line
}

ExternalLink.defaultProps = {
  className: null,
}

const enhance = compose(
  branch(({ link: { to } }) => isUrl(to), renderComponent(ExternalLink)),
)

export default enhance(Link)
