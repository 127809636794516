import ButtonLink from 'Components/Common/Button/ButtonLink'
import Heading from 'Components/Common/Layout/Heading'
import MetaData from 'Components/Common/Layout/MetaData'
import Section from 'Components/Common/Layout/Section'
import Title from 'Components/Common/Title'
import { ButtonGroup } from 'Components/Common/UI/Cart'
import useStoreView from 'Hooks/useStoreView'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'
import CartContent from './CartContent'
import Totals from './Totals'

const CheckoutButton = styled(ButtonLink)`
  border-radius: 0 3px 3px 0;
`

const Cart = () => {
  const platformName = useStoreView('name')
  const alsoCart = useSelector(state => state.alsoCart)
  const { t } = useTranslation('cart')

  return (
    <>
      <MetaData
        title={`${t('title')} - ${platformName}`}
        robots="noindex,nofollow"
        canonical="warenkorb"
      />
      <Heading>
        <Title i18nKey="cart" />
      </Heading>
      <Section padding="80">
        <CartContent />
      </Section>
      {alsoCart.workplaces.length > 0 && (
        <Section padding="0">
          <Totals />
        </Section>
      )}
      <Section paddingTop="80" paddingBottom="80">
        <Flex justifyContent="flex-end">
          <ButtonGroup>
            <CheckoutButton to="/kasse" height={64}>
              {t('button.proceedToCheckout')}
            </CheckoutButton>
          </ButtonGroup>
        </Flex>
      </Section>
    </>
  )
}

export default Cart
