import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { withRouter, Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { compose, withHandlers } from 'recompose'
import { createUseStyles } from 'react-jss'
import { FORM_ERROR } from 'final-form'
import { sendPasswordReset } from 'Lib/fetch'
import SubmitButton from '../Button/SubmitButton'
import { renderField } from '../Layout/Form/FormField'
import styles from './styles'
import { passwordRegex } from '../Checkout/CheckoutValidation'

const validate = values => {
  const requiredMessage = 'required'
  const errors = {}
  if (!values.password) {
    errors.password = requiredMessage
  } else if (!passwordRegex.test(values.password)) {
    errors.password = 'insecurePassword'
  }
  if (!values.passwordRepeat) {
    errors.passwordRepeat = requiredMessage
  } else if (values.passwordRepeat !== values.password) {
    errors.passwordRepeat = 'passwordMismatch'
  }

  return errors
}

const useStyles = createUseStyles(styles)

const PasswordResetForm = ({ onSubmit }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, submitError, submitSucceeded }) =>
        submitSucceeded ? (
          <p>
            <Trans
              i18nKey="auth:passwordResetSuccess"
              components={[<Link to="/account/login" />]}
            />
          </p>
        ) : (
          <form className="small-gutter-form" onSubmit={handleSubmit}>
            <div className="grid-x grid-margin-x">
              <div className="cell medium-12 large-4">
                <Field
                  type="password"
                  component={renderField}
                  label={t('password')}
                  name="password"
                  expand
                />
              </div>
              <div className="cell medium-12 large-4">
                <Field
                  type="password"
                  component={renderField}
                  label={t('passwordRepeat')}
                  name="passwordRepeat"
                  expand
                />
              </div>
              <div className="cell medium-12 large-4">
                <SubmitButton
                  height={59}
                  fetching={submitting}
                  disabled={submitting}
                >
                  {t('submitPasswordReset')}
                </SubmitButton>
              </div>

              {submitError && (
                <div className="cell">
                  <span className={classes.authError}>{t(submitError)}</span>
                </div>
              )}
            </div>
          </form>
        )
      }
    />
  )
}

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const enhance = compose(
  withRouter,
  withHandlers({
    onSubmit: ({
      match: {
        params: { token },
      },
      // eslint-disable-next-line
    }) => async values => {
      try {
        await sendPasswordReset({ ...values, token })
      } catch (e) {
        return { [FORM_ERROR]: 'genericError' }
      }
    },
  }),
)

export default enhance(PasswordResetForm)
