/* eslint-disable react/style-prop-object */
import clsx from 'clsx'
import { useStyles } from 'Components/Also/Cart/Print/Cart'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const AlsoCartPrintAccessory = ({ field, config, term, workplaceQuantity }) => {
  const classes = useStyles()
  const accessory = useSelector(
    state => state.alsoProductVariants.entities[config.variant],
  )

  const { t } = useTranslation('catalog')

  const fieldConfig = useSelector(state =>
    get(state, `alsoConfig.fields.${field}`),
  )

  const accessoryType = t(`alsoFieldNames.${fieldConfig.DisplayName}`)

  const monthlyPrice = accessory.monthlyPrices[term]

  const quantity = config.quantity * workplaceQuantity

  return (
    <tr>
      <td className={clsx(classes.textLine, classes.colorShaded)}>
        {accessoryType}
      </td>
      <td className={clsx(classes.text, classes.expand)}>{accessory.name}</td>
      <td className={classes.numeral}>
        <FormattedNumber value={monthlyPrice} style="currency" currency="EUR" />
      </td>
      <td className={classes.numeral}>{quantity}</td>

      <td className={clsx(classes.numeral, classes.totalsColumn)}>
        <FormattedNumber
          value={monthlyPrice * quantity}
          style="currency"
          currency="EUR"
        />
      </td>
    </tr>
  )
}

AlsoCartPrintAccessory.propTypes = {
  field: PropTypes.string.isRequired,
  config: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  term: PropTypes.number.isRequired,
  workplaceQuantity: PropTypes.number.isRequired,
}

export default AlsoCartPrintAccessory
