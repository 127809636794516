import { createSelector } from 'reselect'
import { flatMap } from 'lodash'

export const allAccessorySelector = createSelector(
  state => state.products.list,
  products =>
    flatMap(products, product =>
      product.productVariants.map(variant => ({ product, variant })),
    ),
)

// eslint-disable-next-line
export const accessoryForVariantSelector = createSelector(
  (state, props) => {
    const device = state.devices.list.find(d => d.id === props.device)
    const variant = device.variants.find(v => v.id === props.variant)

    return { device, variant }
  },
  allAccessorySelector,
  ({ variant: deviceVariant }, products) =>
    deviceVariant.accessorySKUs
      .map(sku => products.find(p => p.variant.sku === sku))
      .filter(p => p),
)

export const accessoryForVariantAndGenericSelector = createSelector(
  (state, props) => {
    const device = state.devices.list.find(d => d.id === props.device)
    const variant = device.variants.find(v => v.id === props.variant)

    return { device, variant }
  },
  allAccessorySelector,
  ({ variant: deviceVariant }, products) => {
    const genericProductsThatDoNotBelongToDevice = products.filter(
      ({ product, variant }) =>
        !deviceVariant.accessorySKUs.some(sku => variant.sku === sku) &&
        product.genericAccessory,
    )

    return [
      ...deviceVariant.accessorySKUs.map(sku =>
        products.find(p => p.variant.sku === sku),
      ),
      ...genericProductsThatDoNotBelongToDevice,
    ].filter(p => p)
  },
)
