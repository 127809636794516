import React from 'react'
import { FormSpy, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { get } from 'lodash'
import { serviceExtensionsSelector } from 'Selectors/common/listSelectors'
import Select from 'Components/Common/Layout/Select'
import ListItem from 'Components/Common/Device/ListItem'
import PropTypes from 'prop-types'

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
`

/* eslint-disable react/style-prop-object */

const ServiceExtensionLabel = ({ name, period }) => {
  const { t } = useTranslation('catalog')
  return (
    <Label>{t('serviceExtensionLabel', { name, term: period / 12 })}</Label>
  )
}

ServiceExtensionLabel.propTypes = {
  name: PropTypes.string.isRequired,
  period: PropTypes.number.isRequired,
}

const ServiceExtensionSelectComponent = ({
  input,
  services,
  period,
  includedServiceExtension,
}) => {
  const { t } = useTranslation('catalog')

  const options = services
    .sort((a, b) => a.position - b.position)
    .map(service => ({
      value: service.id,
      label: <ServiceExtensionLabel {...service} period={period} />,
    }))

  const dummyEntry = {
    value: null,
    label: (
      <Label>
        {t('dummyExtension', { includedServiceExtension, term: period / 12 })}
      </Label>
    ),
  }

  const allOptions = includedServiceExtension
    ? [dummyEntry, ...options]
    : options

  return (
    <Select
      className="r-select"
      classNamePrefix="r-select"
      value={allOptions.find(v => v.value === input.value)}
      options={allOptions}
      onChange={value => {
        const service = value ? value.value : null
        input.onChange(service)
      }}
      clearable={false}
      validate={value => !value && 'required'}
      searchable={false}
      noResultsText={false}
      placeholder={t('dummyExtension', {
        includedServiceExtension,
        term: period / 12,
      })}
    />
  )
}

ServiceExtensionSelectComponent.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  period: PropTypes.number.isRequired,
  includedServiceExtension: PropTypes.string.isRequired,
}

const ServiceExtensionSelect = ({ values }) => {
  const { device, period } = values
  const { t } = useTranslation('catalog')

  const services = useSelector(serviceExtensionsSelector({ device, period }))

  const infoSlug = useSelector(({ storeView }) =>
    get(storeView, 'data.seoConfiguration.serviceExtensionOverviewPage.slug'),
  )

  const { includedServiceExtension } = useSelector(state =>
    state.devices.list.find(d => d.id === device),
  )

  if (services.length === 0) {
    return null
  }

  return (
    <ListItem
      header={t('serviceExtension')}
      info={infoSlug ? `/${infoSlug}` : null}
    >
      <Field
        name="serviceExtension"
        component={ServiceExtensionSelectComponent}
        period={period}
        services={services}
        includedServiceExtension={includedServiceExtension}
      />
    </ListItem>
  )
}

ServiceExtensionSelect.propTypes = {
  values: PropTypes.shape({
    device: PropTypes.string.isRequired,
    period: PropTypes.number.isRequired,
  }).isRequired,
}

const ServiceExtensionSelectCondition = () => (
  <FormSpy subscription={{ values: true }}>
    {({ values }) => <ServiceExtensionSelect values={values} />}
  </FormSpy>
)

export default ServiceExtensionSelectCondition
