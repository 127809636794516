import colors from 'Assets/styles/colors'
import styled from 'styled-components/macro'

export const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;
  width: 600px;
  & > * {
    flex: 1;
  }
`

export const TotalsContainer = styled.div`
  font-family: Titillium Web;
  display: flex;
  justify-content: flex-end;
  font-size: 22px;
  width: 100%;
`

export const TableRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: ${props =>
    props.discount ? props.theme.firstColor : colors.greyishBrownSeven};
  padding: 8px 0;
`

export const TotalsTableHeader = styled(TableRow)`
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: ${colors.greyishBrownFive};
  margin-bottom: 16px;
`

export const TotalsDiscountRow = styled(TableRow)`
  color: ${props => props.theme.firstColor};
`

export const TotalsTotalsRow = styled(TableRow)`
  font-weight: 600;
  margin-top: 16px;
`

export const TotalsTextCell = styled.div`
  width: 300px;
  font-weight: 600;
`

export const TotalsCurrencyCell = styled.div`
  width: 150px;
  text-align: right;
`

export const PrintLink = styled.div`
  text-align: right;
  font-size: 12px;
  a {
    color: ${colors.greyishBrownFive};
  }
`
