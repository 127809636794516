import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
// import officeInclusiveImage from 'Assets/images/office-inklusive.png'
import colors from 'Assets/styles/colors'
import PriceButtonLink from 'Components/Common/Button/PriceButtonLink'
import { ProductImage } from 'Components/Common/Layout/Image'

const useStyles = createUseStyles(theme => ({
  box: {
    width: '273px',
    height: '376px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: colors.greyish29,
    borderRadius: '3px',
    padding: '30px 20px',
  },
  productImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '160px',
    width: '100%',
  },
  name: {
    display: 'block',
    fontSize: '21px',
    fontWeight: '600',
    color: theme.firstColor,
    textAlign: 'center',
    letterSpacing: '0.4px',
    lineHeight: '1.19',
    marginTop: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  descriptionList: {
    display: 'flex',
    fontSize: '17px',
    color: colors.warmGrey,
    textAlign: 'center',
    marginTop: '16px',
    justifyContent: 'space-between',
    '& > div:not(:last-child)': {
      borderRight: {
        width: '1px',
        style: 'solid',
        color: colors.greyish29,
      },
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  descriptionSpec: {
    fontSize: '20px',
    lineHeight: 0.83,
    letterSpacing: 0.4,
    fontWeight: 900,
  },
  descriptionTitle: {
    fontSize: '12px',
    lineHeight: 1.26,
    letterSpacing: 0.2,
    marginTop: 4,
    fontWeight: 900,
  },
  subDescription: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: 0.3,
    color: colors.warmGrey,
    textAlign: 'center',
    marginBottom: '12px',
    minHeight: '27px',
  },
  monthlyPricing: {
    display: 'block',
    fontSize: '15px',
    lineHeight: 1.67,
    letterSpacing: 0.3,
    color: colors.warmGrey,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: '16px',
    marginBottom: '12px',
  },
}))

const DeviceBox = ({ device: { images, slug, name }, rate }) => {
  const { t } = useTranslation('catalog')
  const classes = useStyles()

  return (
    <div className={classes.box}>
      <div className={classes.productImageContainer}>
        <ProductImage
          imageEntity={get(images, '0.@id')}
          alt={name}
          height={160}
          width={231}
        />
      </div>
      <span className={classes.name}>{name}</span>
      <span className={classes.monthlyPricing}>{t('monthlyPricing')}</span>
      <span className={classes.subDescription} />
      <PriceButtonLink
        price={rate}
        to={{
          pathname: `/${slug}`,
        }}
        label={t('goToProduct')}
      />
    </div>
  )
}

DeviceBox.propTypes = {
  device: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      }),
    ).isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  rate: PropTypes.number.isRequired,
}

export default DeviceBox
