import { last, orderBy } from 'lodash'
import it from 'param.macro'
import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import {
  PROTECTION_PLAN,
  PROTECTION_PLAN_WITH_THEFT_PROTECTION,
} from 'Lib/constants'

export const getProtectionPlan = (
  type,
  price,
  period,
  protectionPlans,
  vat,
) => {
  switch (type) {
    case PROTECTION_PLAN: {
      const filteredPlans = orderBy(
        protectionPlans,
        'protectionPlan.amount',
        'asc',
      ).filter(
        ({ protectionPlan: p }) => p.duration === period && !p.theftProtection,
      )

      const plan = filteredPlans.find(
        ({ protectionPlan: p }) => p.amount > price * (1 + vat),
      )
      return plan || last(filteredPlans)
    }
    case PROTECTION_PLAN_WITH_THEFT_PROTECTION: {
      const filteredPlans = orderBy(
        protectionPlans,
        'protectionPlan.amount',
        'asc',
      ).filter(
        ({ protectionPlan: p }) => p.duration === period && p.theftProtection,
      )

      const plan = filteredPlans.find(
        ({ protectionPlan: p }) => p.amount > price * (1 + vat),
      )
      return plan || last(filteredPlans)
    }
    default: {
      return null
    }
  }
}

const ProtectionPlanSubscriber = ({ values }) => {
  const {
    device: deviceId,
    protectionPlanType,
    period,
    accessories,
    serviceExtension,
  } = values

  const device = useSelector(({ devices: { list: devices } }) =>
    devices.find(it.id === deviceId),
  )

  const allProtectionPlans = useSelector(
    ({ protectionPlans: { list } }) => list,
  )

  const allServiceExtensions = useSelector(
    ({ serviceExtensions: { list } }) => list,
  )

  const vat = useSelector(({ config: { vat } }) => vat)

  const protectionPlans =
    device.manufacturer.protectionPlanSKUs.length > 0
      ? allProtectionPlans.filter(p =>
          device.manufacturer.protectionPlanSKUs.some(
            sku => p.protectionPlan.sku === sku,
          ),
        )
      : allProtectionPlans.filter(p => p.protectionPlan.generic)

  const variantPrice = device.variants.find(v => v.id === values.variant)
    .netPrice

  const accessoryPrice = accessories
    .map(a => a.quantity * a.netPrice)
    .reduce((acc, price) => acc + price, 0)

  const serviceExtensionPrice = serviceExtension
    ? allServiceExtensions.find(e => e.id === values.serviceExtension).netPrice
    : 0

  const configurationPrice =
    variantPrice + accessoryPrice + serviceExtensionPrice

  const protectionPlan = getProtectionPlan(
    protectionPlanType,
    configurationPrice,
    period,
    protectionPlans,
    vat,
  )

  const form = useForm()

  const protectionPlanId = protectionPlan ? protectionPlan.id : null

  useEffect(() => {
    form.change('protectionPlan', protectionPlan ? protectionPlan.id : null)
  }, [protectionPlanId])

  return null
}

export default ProtectionPlanSubscriber
