import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMachine } from '@xstate/react'
import colors from 'Assets/styles/colors'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components/macro'
import { Machine } from 'xstate'

const toggleMachine = Machine({
  id: 'toggle',
  initial: 'invisible',
  states: {
    invisible: {
      on: { SHOW: 'visible', TOGGLE: 'visible' },
    },
    visible: {
      on: { HIDE: 'invisible', TOGGLE: 'invisible' },
    },
  },
})

const TooltipContentDiv = styled.div`
  background-color: white;
  padding: 5px 15px;
  color: black;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  & > div {
    pointer-events: none;
  }
`

const IconArea = styled.div`
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  width: 30px;
  text-align: center;
  transition: color 400ms ease-in-out;
  color: ${colors.greyishBrownFive};
  &:hover {
    color: ${props => props.theme.firstColor};
  }
`

const TooltipContent = ({ content, send }) => {
  const clickRef = useRef(null)

  const hide = () => {
    send('HIDE')
  }

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const clickNode = clickRef.current
      if (clickNode) {
        clickNode.addEventListener('mouseout', hide)
        return () => {
          clickNode.removeEventListener('mouseout', hide)
        }
      }
    },
    [clickRef.current], // Recall only if ref changes
  )

  useClickAway(clickRef, () => {
    hide()
  })

  return (
    <TooltipContentDiv ref={clickRef}>
      <IconArea onClick={() => send('HIDE')}>
        <FontAwesomeIcon icon={['far', 'times-circle']} />
      </IconArea>
      <div
        className="tooltip-content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </TooltipContentDiv>
  )
}

TooltipContent.propTypes = {
  content: PropTypes.string.isRequired,
  send: PropTypes.func.isRequired,
}

const ToolTip = ({ content }) => {
  const [current, send] = useMachine(toggleMachine)

  const iconRef = useRef(null)

  const handleMouseOver = () => send('SHOW')

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      const iconNode = iconRef.current
      if (iconNode) {
        iconNode.addEventListener('mouseover', handleMouseOver)

        return () => {
          iconNode.removeEventListener('mouseover', handleMouseOver)
        }
      }
    },
    [iconRef.current], // Recall only if ref changes
  )

  if (!content) {
    return null
  }

  return (
    <>
      {current.value === 'invisible' && (
        <IconArea onClick={() => send('TOGGLE')} ref={iconRef}>
          <FontAwesomeIcon icon="info" />
        </IconArea>
      )}
      {current.value === 'visible' && (
        <TooltipContent
          className="tooltip-content"
          content={content}
          send={send}
        />
      )}
    </>
  )
}

ToolTip.propTypes = {
  content: PropTypes.string.isRequired,
}

export default ToolTip
