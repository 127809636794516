import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import useStoreView from 'Hooks/useStoreView'
import get from 'lodash/get'
import React from 'react'
import { createUseStyles } from 'react-jss'
import Media from 'react-media'
import { useSelector } from 'react-redux'
import { headerLinkSelector } from 'Selectors/common/linkSelectors'
import LanguageSwitcher from '../LanguageSwitcher'
import Link from '../Link'
import Section from './Section'

const styles = theme => ({
  topBar: {
    height: '40px',
    alignItems: 'center',
  },
  text: {
    lineHeight: '1.67',
    letterSpacing: '0.3px',
    fontSize: '15px',
    fontWeight: '600',
    textDecoration: 'none',
  },
  phone: {
    extend: 'text',
    fontWeight: '600',
    color: colors.lightBlack,
  },
  mail: {
    extend: 'text',
    color: theme.firstColor,
  },
  link: {
    extend: 'text',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: colors.lightBlack,
  },
  icon: {
    marginRight: '8px',
  },
  sectionFixed: {
    '@media (max-width: 640px)': {
      position: 'fixed',
      'z-index': '99',
      top: '0',
    },
  },
})

const useStyles = createUseStyles(styles)

const HeaderBar = () => {
  const classes = useStyles()
  const contactEmail = useStoreView('contactEmail')
  const phone = useSelector(state =>
    get(state.resellerInfo, 'data.address.phone'),
  )
  const links = useSelector(headerLinkSelector)

  return (
    <Section
      className={classnames(classes.sectionFixed)}
      padding="0"
      backgroundColor={colors.whiteFour}
    >
      <div
        className={classnames(
          'grid-x',
          'grid-padding-x',
          'align-justify',
          classes.topBar,
        )}
      >
        <div className={classnames('cell', 'shrink', classes.phone)}>
          <FontAwesomeIcon
            icon="phone"
            aria-hidden="true"
            className={classes.icon}
          />
          <a className={classes.phone} href={`tel:${phone}`}>
            {phone}
          </a>
        </div>
        <div className={classnames('cell', 'shrink', classes.mail)}>
          <FontAwesomeIcon
            icon="envelope"
            aria-hidden="true"
            className={classes.icon}
          />
          <a className={classes.mail} href={`mailto:${contactEmail}`}>
            {contactEmail}
          </a>
        </div>
        <Media query="(min-width: 640px)">
          {matches =>
            matches && (
              <div className="cell auto grid-x grid-margin-x align-right">
                {links.map(({ id, ...link }) => (
                  <Link
                    key={id}
                    link={link}
                    className={classnames('cell', 'shrink', classes.link)}
                  />
                ))}
              </div>
            )
          }
        </Media>
        <LanguageSwitcher />
      </div>
    </Section>
  )
}

export default HeaderBar
