import Section from 'Components/Common/Layout/Section'
import Slider from 'Components/Common/Layout/Slider'
import { DetailsHeader } from 'Components/Common/UI/DeviceAccessories'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { accessoryProductVariantForGroupSelector } from 'Selectors/also/productSelectors'
import DeviceAccessoryProduct from './DeviceAccessoryProduct'

const AccessoryMainSlider = ({ group, device }) => {
  const variants = useSelector(state =>
    accessoryProductVariantForGroupSelector(state, { group }).filter(
      v =>
        v.genericAccessory || device.accessorySKUs.some(sku => v.sku === sku),
    ),
  )

  const fieldConfig = useSelector(state =>
    get(state, `alsoConfig.fields.${group}`),
  )

  const { t } = useTranslation('catalog')

  if (!fieldConfig) {
    return null
  }

  if (variants.length === 0) {
    return null
  }

  return (
    <Section key="accessories" padding="30">
      <DetailsHeader>
        {t(`alsoFieldNames.${fieldConfig.DisplayName}`)}
      </DetailsHeader>

      <Field
        name="term"
        subscription={{ value: true }}
        render={({ input: { value: term } }) => (
          <Field
            name={fieldConfig.Name}
            render={({ input }) => (
              <Slider
                perRow={3}
                settings={{
                  infinite: false,
                  centerMode: false,
                  centerPadding: '60px',
                  initialSlide: 0,
                  slidesToShow: 3,
                }}
              >
                {variants.map(variant => {
                  const selected = Boolean(
                    input.value && input.value.variant === variant.id,
                  )

                  const selectHandler = () =>
                    selected
                      ? input.onChange(null)
                      : input.onChange({ variant: variant.id, quantity: 1 })

                  const disabled = !(variant.monthlyPrices[term] > 0)
                  return (
                    <div key={variant.id}>
                      <DeviceAccessoryProduct
                        variant={variant}
                        selected={selected}
                        onSelect={selectHandler}
                        disabled={disabled}
                      />
                    </div>
                  )
                })}
              </Slider>
            )}
          />
        )}
      />
    </Section>
  )
}

AccessoryMainSlider.propTypes = {
  group: PropTypes.string.isRequired,
  device: PropTypes.shape({
    accessorySKUs: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

export default AccessoryMainSlider
