import classnames from 'classnames'
import {
  Period,
  PeriodMonths,
  PeriodNumber,
} from 'Components/Common/UI/PeriodSelect'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const PeriodSelect = ({ period, className, onChange }) => (
  <div className={classnames('grid-x', className)}>
    {[24, 36].map(p => (
      <Period
        key={p}
        role="button"
        active={period === p}
        onKeyPress={() => onChange(p)}
        onClick={() => onChange(p)}
        className={classnames('cell', 'auto')}
      >
        <PeriodNumber active={period === p}>{p}</PeriodNumber>
        <PeriodMonths active={period === p}>Monate</PeriodMonths>
      </Period>
    ))}
  </div>
)

PeriodSelect.propTypes = {
  period: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

PeriodSelect.defaultProps = {
  className: null,
}

export const StyledPeriodSelect = styled(PeriodSelect)`
  border-radius: 3px;
  & > *:first-child {
    border-radius: 3px 0 0 3px;
  }
  & > *:last-child {
    border-radius: 0 3px 3px 0;
  }
`

export default PeriodSelect
