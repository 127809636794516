import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import EmptyCart from 'Components/Common/Cart/EmptyCart'
import typography from 'Components/Common/Layout/typography'
import concat from 'lodash/concat'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import DeviceLine from './DeviceLine'
import ProductLine from './ProductLine'

/* eslint-disable react/no-array-index-key */

const styles = {
  header: {
    ...typography.text,
    width: '100%',
    fontSize: '23px',
    fontWeight: 'bold',
    lineHeight: '1.09',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: colors.greyishBrownFive,
    '& > div': {
      padding: 20,
    },
  },
  quantityHeader: {
    width: '15%',
  },
  textNumber: {
    textAlign: 'right',
  },
  productTitle: {
    marginLeft: '336px',
  },
}
const useStyles = createUseStyles(styles)

const CartContent = () => {
  const classes = useStyles()
  const { t } = useTranslation('cart')
  const cart = useSelector(state => state.cart)

  if (concat(cart.devices, cart.products).length === 0) {
    return <EmptyCart />
  }

  return (
    <>
      <div className={classnames('grid-x', classes.header)}>
        <div className={classnames('cell', 'medium-2', classes.quantityHeader)}>
          {t('quantity')}
        </div>
        <div className={classnames('cell', 'auto', classes.productTitle)}>
          {t('product')}
        </div>
        <div className={classnames('cell', 'medium-2', classes.textNumber)}>
          {t('monthly')}
        </div>
      </div>
      {cart.devices.map((d, i) => (
        <DeviceLine
          key={`device-${d.device}-${d.variant}-${i}`}
          index={i}
          deviceConfiguration={d}
        />
      ))}
      {cart.products.map((p, i) => (
        <ProductLine
          key={`product-${p.product}-${p.productVariant}-${i}`}
          index={i}
          productConfiguration={p}
        />
      ))}
    </>
  )
}

export default CartContent
