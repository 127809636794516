import React from 'react'
import { get, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import ButtonLink from 'Components/Common/Button/ButtonLink'
import { DeviceType, VariantType } from 'Lib/propTypes'
import Item from './Item'

/* eslint-disable react/style-prop-object */

const Device = ({ device, variant }) => {
  const { t } = useTranslation('catalog')

  if (!device.active || !variant.active) {
    return null
  }

  const { images, name, slug, manufacturer } = device
  const { id: variantId, netPrice, description, specs } = variant

  const image = get(sortBy(images, 'position'), '0.@id')

  return (
    <Item
      imageEntity={image}
      name={name}
      slug={slug}
      description={description}
      manufacturer={manufacturer.name}
      price={netPrice}
      variantId={variantId}
      specs={specs}
      link={
        <ButtonLink
          className="rent-now"
          to={{ pathname: `/${slug}`, state: { variantId } }}
        >
          {t('goToProduct')}
        </ButtonLink>
      }
    />
  )
}

Device.propTypes = {
  device: DeviceType.isRequired,
  variant: VariantType.isRequired,
}

Device.defaultProps = {}

export default Device
