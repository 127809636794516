import React from 'react'
import PropTypes from 'prop-types'
import { branch, renderComponent } from 'recompose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './LoadingIndicator.css'

export const LoadingIndicator = ({ small }) => (
  <div className="loader-container">
    <FontAwesomeIcon icon="circle-notch" spin />
  </div>
)

LoadingIndicator.propTypes = {
  small: PropTypes.bool,
}

LoadingIndicator.defaultProps = {
  small: false,
}

export const withSpinnerWhileLoading = condition =>
  branch(condition, renderComponent(LoadingIndicator))

export const withSpinnerWhileFetchingProp = withSpinnerWhileLoading(
  ({ fetching }) => fetching,
)

export default LoadingIndicator
