import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import CurrencyNumber from '../CurrencyNumber'
import styles from './styles'

const useStyles = createUseStyles(styles)

const ButtonLink = ({
  to,
  quantity,
  price,
  style,
  height,
  children,
  className,
  hollow,
}) => {
  const classes = useStyles({ hollow })
  return (
    <div
      className={classnames(classes.box, className)}
      style={{ ...style, height: `${height}px` }}
    >
      {quantity && (
        <div className={classes.quantity}>
          <i
            className={classnames('fa', 'fa-caret-up', classes.quantityButton)}
            aria-hidden="true"
          />
          <span className={classes.quantityNumber}>{quantity}</span>
          <i
            className={classnames(
              'fa',
              'fa-caret-down',
              classes.quantityButton,
            )}
            aria-hidden="true"
          />
        </div>
      )}
      {price && (
        <div className={classes.price}>
          <CurrencyNumber value={price} />
        </div>
      )}

      <Link to={to} className={classes.text}>
        {children}&nbsp;
        <FontAwesomeIcon icon="angle-right" />
      </Link>
    </div>
  )
}

ButtonLink.defaultProps = {
  height: 40,
  to: '/product',
}

export default ButtonLink
