import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v1'

const NotificationContext = React.createContext()
const { Provider, Consumer } = NotificationContext

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])

  const removeNotification = id => {
    setNotifications(notifications => {
      const notification = notifications.find(n => n.id === id)
      clearTimeout(notification.timer)

      return notifications.filter(n => n.id !== notification.id)
    })
  }

  const addNotification = notification => {
    const id = uuid()
    const timer = setTimeout(() => removeNotification(id), 10000)

    setNotifications(notifications => [
      ...notifications,
      { id, ...notification, timer },
    ])
  }

  return (
    <Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </Provider>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NotificationContext
export { NotificationProvider, Consumer as NotificationConsumer }
