import { addDeviceToCart, setPeriod } from 'Ducks/common/cart'
import createDecorator from 'final-form-calculate'
import { PROTECTION_PLAN } from 'Lib/constants'
import { get, minBy, orderBy } from 'lodash'
import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import history from 'Lib/history'
import { accessoryForVariantAndGenericSelector } from 'Selectors/common/accessorySelectors'
import {
  deviceConfigurationPriceSelector,
  discountedDeviceConfigurationPriceSelector,
} from 'Selectors/common/priceSelectors'
import InformationTabs from 'Components/Common/InformationTabs'
import Section from 'Components/Common/Layout/Section'
import BundleSubscriber from 'Components/Aluxo/Device/BundleSubscriber'
import DeviceAccessories from 'Components/Aluxo/Device/DeviceAccessories'
import DeviceDetails from 'Components/Aluxo/Device/DeviceDetails'
import ProtectionPlanSubscriber, {
  getProtectionPlan,
} from 'Components/Aluxo/Device/ProtectionPlanSubscriber'
import RateSubscriber from 'Components/Aluxo/Device/RateSubscriber'
import { calculateRate, getServicesPrice } from 'Lib/calculations'

const StyledForm = styled.form`
  width: 100%;
`

const DeviceForm = () => {
  const { slug } = useParams()
  const { state } = useLocation()

  const {
    device,
    leasingFactors,
    allProtectionPlans,
    allServices,
    allServiceExtensions,
    promoBundles,
    vat,
  } = useSelector(
    ({
      devices: { list: devices },
      services: { list: allServices },
      serviceExtensions: { list: allServiceExtensions },
      protectionPlans: { list: protectionPlans },
      promoBundles: { list: promoBundles },
      config: { leasingFactors, vat },
    }) => ({
      device: devices.find(p => p.slug === slug),
      allServices,
      allServiceExtensions: orderBy(allServiceExtensions, 'netPrice'),
      allProtectionPlans: protectionPlans,
      promoBundles,
      leasingFactors,
      vat,
    }),
  )

  const dispatch = useDispatch()

  const serviceExtensions = allServiceExtensions
    .filter(e => device.serviceExtensionSKUs.some(sku => sku === e.sku))
    .sort((a, b) => a.netPrice - b.netPrice)

  const period = 36
  const initialValues = {
    period,
    device: device.id,

    variant: get(
      state,
      'variantId',
      minBy(
        device.variants.filter(v => v.active),
        'netPrice',
      ).id,
    ),

    accessories: [],
    qualifiedBundles: [],
    bundle: null,
    quantity: 1,
    services: {},
    serviceExtension:
      (!device.includedServiceExtension &&
        get(
          serviceExtensions.filter(p => p.term === period),
          '[0].id',
        )) ||
      null,
    protectionPlan: null,
    protectionPlanType: PROTECTION_PLAN,
  }

  const qualifiedBundles = promoBundles.filter(
    b => b.device.id === initialValues.variant,
  )

  const bestBundle =
    qualifiedBundles.length > 0 &&
    qualifiedBundles.sort((a, b) => a.netPrice - b.netPrice)[0]

  const accessories = useSelector(state =>
    accessoryForVariantAndGenericSelector(state, initialValues),
  )

  initialValues.accessories = accessories
    .filter(
      a =>
        bestBundle && bestBundle.accessories.some(p => p.id === a.variant.id),
    )
    .map(accessory => ({
      variant: accessory.variant.id,
      product: accessory.product.id,
      quantity: 1,
      netPrice: accessory.variant.netPrice,
    }))

  initialValues.qualifiedBundles = qualifiedBundles
  initialValues.bundle = bestBundle

  const regularPrice = useSelector(state =>
    deviceConfigurationPriceSelector(state, initialValues),
  )

  const discountedPrice = useSelector(state =>
    discountedDeviceConfigurationPriceSelector(state, initialValues),
  )

  const protectionPlans =
    device.manufacturer.protectionPlanSKUs.length > 0
      ? allProtectionPlans.filter(p =>
          device.manufacturer.protectionPlanSKUs.some(
            sku => p.protectionPlan.sku === sku,
          ),
        )
      : allProtectionPlans.filter(p => p.protectionPlan.generic)

  const initialProtectionPlan = getProtectionPlan(
    initialValues.protectionPlanType,
    regularPrice,
    initialValues.period,
    protectionPlans,
    vat,
  )

  initialValues.totalPrice = initialProtectionPlan
    ? discountedPrice + initialProtectionPlan.netPrice
    : discountedPrice

  initialValues.protectionPlan = initialProtectionPlan
    ? initialProtectionPlan.id
    : null

  initialValues.rate =
    calculateRate({
      leasingFactors,
      period: initialValues.period,
      totalPrice: initialValues.totalPrice,
    }) +
    getServicesPrice(
      initialValues.quantity,
      initialValues.services,
      allServices,
    )

  const handleSubmit = ({ period, ...values }) => {
    dispatch(addDeviceToCart(values))
    dispatch(setPeriod(period))
    history.push('/warenkorb')
  }

  const decorator = React.useMemo(() => {
    const decorator = createDecorator({
      field: ['period'],
      updates: (value /* , name, allValues */) => {
        let filteredServiceExtensions = serviceExtensions.filter(
          se => se.term === value,
        )

        if (filteredServiceExtensions.length === 0) {
          filteredServiceExtensions = serviceExtensions
        }

        return {
          serviceExtension:
            !device.includedServiceExtension &&
            get(filteredServiceExtensions, '0.id'),
        }
      },
    })
    return decorator
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      decorators={[decorator]}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Section padding="80">
            <DeviceDetails device={device} />
          </Section>
          <FormSpy
            subscription={{ values: true }}
            component={BundleSubscriber}
          />
          <FormSpy
            subscription={{ values: true }}
            component={ProtectionPlanSubscriber}
          />

          <FormSpy subscription={{ values: true }} component={RateSubscriber} />

          <Field name="variant" subscription={{ value: true }}>
            {({ input: { value: variant } }) => (
              <Field
                name="accessories"
                render={({ input }) => (
                  <DeviceAccessories
                    input={input}
                    variant={variant}
                    device={device.id}
                  />
                )}
              />
            )}
          </Field>
          <Section padding="80">
            <InformationTabs informationTabs={device.informationTabs} />
          </Section>
        </StyledForm>
      )}
    />
  )
}

export default DeviceForm
