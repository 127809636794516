import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { flexCentered } from '../Layout/helpers'
import { ProductImage } from '../Layout/Image'
import typography from '../Layout/typography'

const styles = {
  container: {
    ...typography.text,
    display: 'flex',
  },
  imageContainer: {
    ...flexCentered,
    width: 240,
    margin: [0, 48],
  },
  detailsContainer: {
    marginLeft: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: colors.greyishBrownThree,
    margin: [-8, 0],
    '& > *': {
      margin: [8, 0],
    },
  },
  deviceName: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '1.25',
    letterSpacing: '0.4px',
    textDecoration: 'none',
    color: colors.greyishBrownThree,
  },
}

const useStyles = createUseStyles(styles)

const LineItem = ({ imageEntity, name, link, children }) => {
  const classes = useStyles()
  return (
    <div className={classnames('grid-x', classes.container)}>
      <div className={classes.imageContainer}>
        <ProductImage
          imageEntity={imageEntity}
          alt={name}
          title={name}
          width={290}
          height={180}
        />
      </div>
      <div className={classes.detailsContainer}>
        <Link to={link} className={classes.deviceName}>
          {name}
        </Link>
        {children}
      </div>
    </div>
  )
}

LineItem.propTypes = {
  imageEntity: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LineItem
