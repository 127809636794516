import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-final-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { accessoryForVariantAndGenericSelector } from 'Selectors/common/accessorySelectors'

const BundleSubscriber = ({ values }) => {
  const accessories = useSelector(state =>
    accessoryForVariantAndGenericSelector(state, values),
  )

  const promoBundles = useSelector(
    ({ promoBundles: { list: promoBundles } }) => promoBundles,
  )

  const qualifiedBundles = promoBundles
    .filter(b => b.device.id === values.variant)
    .sort((a, b) => a.netPrice - b.netPrice)

  const bestBundle = qualifiedBundles.length > 0 && qualifiedBundles[0]

  const applicableAccessories = accessories
    .filter(
      a =>
        bestBundle && bestBundle.accessories.some(p => p.id === a.variant.id),
    )
    .map(accessory => ({
      variant: accessory.variant.id,
      product: accessory.product.id,
      quantity: 1,
      netPrice: accessory.variant.netPrice,
    }))

  const selectedBundle =
    qualifiedBundles.find(b =>
      b.accessories.every(p =>
        values.accessories.find(a => a.variant === p.id),
      ),
    ) || null

  const form = useForm()

  useDeepCompareEffect(() => {
    form.change('qualifiedBundles', qualifiedBundles)
    form.change('accessories', applicableAccessories)
  }, [qualifiedBundles, applicableAccessories])

  useEffect(() => {
    form.change('bundle', selectedBundle)
  }, [selectedBundle])

  return null
}

export default BundleSubscriber
