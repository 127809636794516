import { createSelector } from 'reselect'

export const servicesSelector = createSelector(
  state => state.services.list,
  services => services,
)

export const managedServicesSelector = createSelector(
  servicesSelector,
  services => services.filter(({ managed }) => managed),
)

export const unmanagedServicesSelector = createSelector(
  servicesSelector,
  services => services.filter(({ managed }) => !managed),
)

export const officeServicesSelector = createSelector(
  servicesSelector,
  services => services.filter(({ type }) => type === 'Office Lizenz'),
)

export const typedServicesSelector = type =>
  createSelector(servicesSelector, services =>
    services.filter(s => s.type === type),
  )

export const typeSlugServicesSelector = typeSlug =>
  createSelector(servicesSelector, services =>
    services.filter(s => s.typeSlug === typeSlug),
  )

export const marketingLogosSelector = createSelector(
  state => state.marketingLogos.list,
  marketingLogos => marketingLogos,
)

export const serviceExtensionsSelector = ({ device, period }) =>
  createSelector(
    state => state.devices.list.find(d => d.id === device),
    state => state.serviceExtensions.list,
    (device, serviceExtensions) => {
      const extensionsForDevice = serviceExtensions
        .filter(e => device.serviceExtensionSKUs.some(sku => sku === e.sku))
        .sort((a, b) => a.netPrice - b.netPrice)

      const extensionsForTerm = extensionsForDevice.filter(
        s => s.term === period,
      )
      return extensionsForTerm.length > 0
        ? extensionsForTerm
        : extensionsForDevice
    },
  )
