import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { calculateRate, getServicesPrice } from 'Lib/calculations'
import { discountedDeviceConfigurationPriceSelector } from 'Selectors/common/priceSelectors'

const calculatePriceAndRate = values => {
  const discountedPrice = useSelector(state =>
    discountedDeviceConfigurationPriceSelector(state, values),
  )

  const services = useSelector(({ services: { list } }) => list)
  const leasingFactors = useSelector(
    ({ config: { leasingFactors } }) => leasingFactors,
  )

  const totalPrice = values.quantity * discountedPrice

  const rate =
    calculateRate({
      leasingFactors,
      period: values.period,
      totalPrice,
    }) + getServicesPrice(values.quantity, values.services, services)

  return {
    totalPrice,
    rate,
  }
}

const RateSubscriber = ({ values }) => {
  const { totalPrice, rate } = calculatePriceAndRate(values)

  const form = useForm()

  useEffect(() => {
    form.change('totalPrice', totalPrice)
    form.change('rate', rate)
  }, [totalPrice, rate])

  return null
}

export default RateSubscriber
