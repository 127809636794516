import colors from 'Assets/styles/colors'
import LineItem from 'Components/Common/Cart/LineItem'
import { CustomProductType, ProductVariantType } from 'Lib/propTypes'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, withProps } from 'recompose'

const styles = {
  accessoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  attributeContainer: {
    border: {
      style: 'solid',
      width: 1,
      color: colors.whiteSix,
    },
    padding: 8,
    font: {
      size: 15,
    },
  },
  selectType: {
    color: colors.warmGreyFive,
  },
}

const useStyles = createUseStyles(styles)

const enhance = compose(
  withRouter,
  connect(({ products: { list: products } }, { productConfiguration }) => ({
    product: products.find(d => d.id === productConfiguration.product),
  })),
  withProps(({ product, productConfiguration }) => ({
    productVariant: product.productVariants.find(
      p => p.id === productConfiguration.productVariant,
    ),
  })),
)

const Product = ({ product, productVariant }) => {
  const classes = useStyles()
  const { slug, name } = product
  const imageEntity = productVariant.imageEntity || product.imageEntity
  return (
    <LineItem imageEntity={imageEntity} name={name} link={`/product/${slug}`}>
      {productVariant.attributeValues.length > 0 && (
        <div className={classes.attributeContainer}>
          {productVariant.attributeValues.map(({ attribute, value }) => (
            <div key={attribute}>
              <span className={classes.selectType}>{attribute}: </span>
              {value}
            </div>
          ))}
        </div>
      )}
    </LineItem>
  )
}

Product.propTypes = {
  product: CustomProductType.isRequired,
  productVariant: ProductVariantType.isRequired,
}

export default enhance(Product)
