import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import sliderStyles from '../Layout/sliderStyles'

const styles = theme => ({
  arrow: {
    lineHeight: '0',
    position: 'absolute',
    top: '50%',
    padding: '0',
    transform: 'useTranslation(0, -50%)',
    cursor: 'pointer',
    height: '20px',
    width: '20px',
    backgroundColor: 'white',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border-color 0.5s ease, color 0.5s ease',
    opacity: 0.5,
    color: theme.firstColor,
    '&:hover': {
      opacity: 1,
    },
  },
  nextArrow: {
    extend: 'arrow',
    right: '-40px',
  },
  prevArrow: {
    extend: 'arrow',
    left: '-40px',
  },
})

const useArrowStyles = createUseStyles(styles)

export const NextArrow = ({ onClick }) => {
  const classes = useArrowStyles()

  return (
    <div
      role="button"
      onKeyPress={onClick}
      tabIndex={0}
      className={classes.nextArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="caret-right" />
    </div>
  )
}

export const PrevArrow = ({ onClick }) => {
  const classes = useArrowStyles()

  return (
    <div
      role="button"
      onKeyPress={onClick}
      tabIndex={0}
      className={classes.prevArrow}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="caret-left" />
    </div>
  )
}

const sliderSettings = ({ count }) => {
  const perRow = 6
  return {
    infinite: false,
    speed: 500,
    slidesToShow: perRow,
    slidesToScroll: 1,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          arrows: count > 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          // slidesToShow: 4,
          // arrows: count > 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
}

const useStyles = createUseStyles(sliderStyles)

const Slider = ({ children }) => {
  const classes = useStyles()
  return (
    <SlickSlider
      {...sliderSettings({
        count: children.length,
      })}
      dotsClass={classes.dots}
    >
      {children}
    </SlickSlider>
  )
}

Slider.propTypes = {
  settings: PropTypes.object, // eslint-disable-line
  children: PropTypes.node, // eslint-disable-line
  hideArrows: PropTypes.bool,
  perRow: PropTypes.number,
}

Slider.defaultProps = {
  children: [],
  perRow: 3,
  hideArrows: false,
}

export default Slider
