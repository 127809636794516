import color from 'color'
import styled from 'styled-components/macro'

// eslint-disable-next-line
export const SelectContainer = styled.div`
  & .r-select__control--is-focused:not(.r-select__control--menu-is-open) {
    box-shadow: 0 0 5px ${props => props.theme.firstColor};
    border: 1px solid ${props => props.theme.firstColor};
  }

  & .r-select__control--is-focused,
  & .r-select__control--is-focused:hover {
    border-color: hsl(0, 0%, 80%);
    box-shadow: none;
  }

  & .r-select__option--is-selected {
    background-color: ${props =>
      color(props.theme.firstColor)
        .fade(0.5)
        .string()};
    color: rgba(0, 0, 0, 0.87);
  }

  & .r-select__option--is-focused {
    background-color: ${props =>
      color(props.theme.firstColor)
        .fade(0.3)
        .string()};
    color: rgba(0, 0, 0, 0.87);
  }

  & .r-select__menu {
    z-index: 10;
  }

  & .r-select__single-value {
    width: 100%;
  }
`

export const ImageSliderContainer = styled.div`
  @media (min-width: 800px) {
    margin: 0 40px 20px;
  }
`
