import React from 'react'
import config from 'Lib/config'
import useSectionVisible from 'Hooks/useSectionVisible'
import AlsoDeviceBoxSection from 'Components/Also/Home/DeviceBoxSection/DeviceBoxSection'
import AluxoDeviceBoxSection from 'Components/Aluxo/Home/DeviceBoxSection/DeviceBoxSection'

const CommonDeviceBoxSection = () => {
  const visible = useSectionVisible('featured')
  if (!visible) {
    return null
  }

  return config.reseller.also ? (
    <AlsoDeviceBoxSection />
  ) : (
    <AluxoDeviceBoxSection />
  )
}

export default CommonDeviceBoxSection
