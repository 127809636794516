import React from 'react'
import useStoreView from 'Hooks/useStoreView'
import SocialLink from './SocialLink'

const platforms = [
  {
    path: 'facebookURL',
    icon: 'facebook-f',
  },
  {
    path: 'twitterURL',
    icon: 'twitter',
  },

  {
    path: 'googlePlusURL',
    icon: 'google-plus-g',
  },
  {
    path: 'youtubeURL',
    icon: 'youtube',
  },
]

const SocialLinks = () => {
  const links = platforms.map(({ path, icon }) => ({
    path,
    icon,
    url: useStoreView(path),
  }))

  return (
    <div className="grid-x grid-margin-x align-center">
      {links.map(link => (
        <SocialLink key={link.path} {...link} />
      ))}
    </div>
  )
}

export default SocialLinks
