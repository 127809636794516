import React from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import Section from 'Components/Common/Layout/Section'
import CategoryLink from 'Components/Common/CategoryPage/CategoryLink'

const CategoryLinks = ({ categories }) => (
  <Section key="links" padding="0">
    <div className="grid-x align-justify">
      {sortBy(categories, 'position').map(c => (
        <CategoryLink key={c.id} {...c} />
      ))}
    </div>
  </Section>
)

CategoryLinks.propTypes = {
  categories: PropTypes.array.isRequired, // eslint-disable-line
}

export default CategoryLinks
