import React from 'react'
import { createUseStyles } from 'react-jss'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Be sure to include styles at some point, probably during your bootstrapping
import 'slick-carousel/slick/slick.css'
import isUrl from 'validator/lib/isURL'
import { useSelector } from 'react-redux'
import useSectionVisible from 'Hooks/useSectionVisible'
import history from 'Lib/history'
import Image from '../Layout/Image'
import Section from '../Layout/Section'
import sliderStyles from '../Layout/sliderStyles'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
}

const useStyles = createUseStyles(sliderStyles)

const Slider = () => {
  const classes = useStyles()
  const slides = useSelector(({ slides: { list: slides } }) => slides)
  const visible = useSectionVisible('slider')
  if (!visible || slides.length === 0) {
    return null
  }
  return (
    <Section padding="80">
      <SlickSlider {...settings} dotsClass={classes.dots}>
        {slides.map(({ id, image, link, name }) => (
          <div key={id}>
            {link && isUrl(link) ? (
              <a href={link}>
                <Image
                  src={image}
                  alt={name}
                  link={link}
                  width={1300}
                  height={551}
                />
              </a>
            ) : (
              <Image
                src={image}
                alt={name}
                link={link}
                onClick={() => history.push(link)}
                width={1300}
                height={551}
              />
            )}
          </div>
        ))}
      </SlickSlider>
    </Section>
  )
}

export default Slider
