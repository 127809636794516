import React from 'react'
import { useTranslation } from 'react-i18next'
import useStoreView from 'Hooks/useStoreView'
import Title from 'Components/Common/Title'
import Heading from '../Layout/Heading'
import MetaData from '../Layout/MetaData'
import Section from '../Layout/Section'
import CheckoutFormSection from './CheckoutFormSection'
import LoginOrNewCustomerForm from './LoginOrNewCustomerForm'

const Checkout = () => {
  const platformName = useStoreView('name')
  const { t } = useTranslation('checkout')
  return (
    <>
      <MetaData title={`${t('title')} - ${platformName}`} canonical="kasse" />
      <Heading>
        <Title i18nKey="checkout" />
      </Heading>
      <Section paddingTop="80" paddingBottom="20">
        <LoginOrNewCustomerForm />
      </Section>
      <CheckoutFormSection />
    </>
  )
}

export default Checkout
