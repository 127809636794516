import { normalize, schema, denormalize } from 'normalizr'

const options = {
  idAttribute: 'id',
}

const category = new schema.Entity(
  'category',
  {},
  {
    ...options,
    processStrategy: (value, parent) => ({
      ...value,
      products: value.products ? value.products.push(parent.id) : [parent.id],
    }),
  },
)

const variant = new schema.Entity(
  'variant',
  {},
  {
    ...options,
    processStrategy: (value, parent) => ({
      ...value,
      product: parent.id,
      type: parent.type,
    }),
  },
)

const product = new schema.Entity(
  'product',
  {
    category,
    variants: [variant],
  },
  {
    ...options,
    processStrategy: value => ({
      ...value,
      active:
        value.active &&
        value.variants.reduce((acc, variant) => acc || variant.active, false),
    }),
  },
)

const resellerProduct = new schema.Entity(
  'resellerProduct',
  {
    product,
  },
  options,
)

variant.define({
  product,
})

category.define({
  products: [product],
})

export const arrayOfResellerProducts = new schema.Array(resellerProduct)

export const normalizeAlsoResellerProducts = data =>
  normalize(data, arrayOfResellerProducts)

export const denormalizeVariant = (data, entities) =>
  denormalize(data, variant, entities)

export const denormalizeVariants = (data, entities) =>
  denormalize(data, new schema.Array(variant), entities)
