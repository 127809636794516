export default {
  home: 'Startseite',
  aboutUs: 'Über uns',
  products: 'Produkte',
  contactUs: 'Kontakt',
  orderNow: 'Jetzt bestellen',
  orderSummary: 'Bestellübersicht',
  myAccount: 'Mein Konto',
  customerLogout: 'Ausloggen',
  customerLogin: 'Kundenlogin',
  button: {
    toggleNavigation: 'Navigation einblenden',
  },
  webagentur: 'Webagentur',
  itSystemhaus: 'IT-Systemhaus',
  blog: 'Blog',
  workplace: 'Workplace',
}
