import colors from 'Assets/styles/colors'
import typography from '../Layout/typography'
import { flexCentered } from '../Layout/helpers'

export default theme => ({
  box: {
    ...typography.text,
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: theme.firstColor,
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    '& > *': {
      width: '100%',
      height: '100%',
    },
  },
  quantity: {
    textAlign: 'center',
    flexGrow: '1',
    ...flexCentered,
    flexDirection: 'column',
    color: colors.greyishBrown,
    fontSize: '22px',
    lineHeight: '16px',
    width: '50%',
    borderRight: {
      style: 'solid',
      width: 1,
      color: theme.firstColor,
    },
  },
  price: {
    textAlign: 'center',
    flexGrow: '1',
    ...flexCentered,
    lineHeight: '40px',
    color: colors.greyishBrown,
    fontSize: '17px',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
    backgroundColor: props => (props.hollow ? colors.white : theme.firstColor),
    color: props => (props.hollow ? theme.firstColor : colors.white),
    fontSize: '15px',
    fontWeight: 'bold',
    flexGrow: '1',
    padding: '8px 24px',
    borderRadius: '0 3px 3px 0',
    cursor: 'pointer',
    textDecoration: 'none',

    textTransform: 'uppercase',
    ...flexCentered,
  },
  priceText: {
    extend: 'text',
    backgroundColor: props => (props.hollow ? colors.white : theme.firstColor),
    color: props => (props.hollow ? theme.firstColor : colors.white),
    padding: 0,
    '& a': {
      textDecoration: 'none',
      color: 'white',
    },
  },
})
