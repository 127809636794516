import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'
import useSectionVisible from 'Hooks/useSectionVisible'
import useStoreView from 'Hooks/useStoreView'
import Section from '../Layout/Section'

const Text = styled.div`
  color: ${colors.brownishGrey};
  font-weight: 300;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.7px;
  text-align: center;
`

const SEOSection = () => {
  const visible = useSectionVisible('seo')

  const title = useStoreView('seoConfiguration.homepageSeoTitle')
  const content = useStoreView('seoConfiguration.homepageSeoText')

  if (!visible) {
    return null
  }

  return (
    <Section title={title} titleAsH1>
      <div
        className={classnames(
          'grid-x',
          'grid-padding-x',
          'small-up-1',
          'medium-up-3',
          'large-up-3',
          'align-justify',
        )}
      >
        <Text dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Section>
  )
}

export default SEOSection
