import { mapLanguage } from 'Lib/i18nMiddleware'
import { SET_LANGUAGE } from 'Ducks/common/config'

export const STORE_VIEW_FETCH_REQUEST = 'STORE_VIEW::FETCH_REQUEST'
export const STORE_VIEW_FETCH_FAIL = 'STORE_VIEW::FETCH_FAIL'
export const STORE_VIEW_FETCH_SUCCESS = 'STORE_VIEW::FETCH_SUCCESS'

const initialState = {
  data: null,
  fetching: false,
  default: null,
}

export const fetchResellerInfo = () => ({
  type: STORE_VIEW_FETCH_REQUEST,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_VIEW_FETCH_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case STORE_VIEW_FETCH_SUCCESS: {
      return {
        ...state,
        data: {
          ...action.data,
          // ...mapLanguage(action.lang)(action.data),
          notes: action.data.notes.map(mapLanguage(action.lang)),
        },
        default: action.data,
        fetching: false,
      }
    }
    case STORE_VIEW_FETCH_FAIL: {
      return initialState
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        data: {
          // ...mapLanguage(action.payload.lang)(state.default),
          notes: state.default.notes.map(mapLanguage(action.payload.lang)),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
