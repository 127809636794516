export default {
  monthly: 'Monatlich',
  fixed: 'Einmalig',
  costs: 'Kosten',
  discount: 'Rabatt',
  title: 'Warenkorb',
  subtotal: 'Zwischensumme',
  tax: '{{vat}}% MwSt',
  product: 'Produkt',
  cartNumber: 'Anzahl',
  price: 'Preis',
  monthlyPrice: 'Preis pro Monat',
  monthlyTotal: 'Gesamtpreis pro Monat',
  quantity: 'Anzahl',
  total: 'Gesamtpreis',
  periodLabel: 'Laufzeit',
  shoppingCartSummary: 'Warenkorb Summe',
  selectAtLeastOneDevice:
    'Um Ihren Warenkorb zu verändern, benötigen Sie ein Produkt, dass Sie Ihrem Warenkorb hinzugefügt haben.',
  cartIsEmpty: 'Ihr Warenkorb ist leer.',
  workplace: 'Arbeitsplatz {{number}}',
  workplaces: 'Arbeitsplätze {{from}} - {{to}}',
  placeholder: {
    rentalPeriod: 'Bitte Laufzeit wählen',
  },
  subTotal: 'Zwischensumme',
  button: {
    proceedToCheckout: 'Weiter zur Kasse',
  },
}
