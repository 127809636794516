import React from 'react'
import Section from '../Layout/Section'
import PasswordResetForm from './PasswordResetForm'

const PasswordResetRequest = () => (
  <Section>
    <div className="grid-x grid-padding-x">
      <div className="cell small-12 medium-8 medium-offset-2">
        <PasswordResetForm />
      </div>
    </div>
  </Section>
)

export default PasswordResetRequest
