import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const AccessoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 48px;
  max-width: 400px;

  & > * {
    margin: 4px;
  }
`

export const SelectContainer = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.whiteSix};
  padding: 8px;

  font-size: 15px;
`

export const SelectType = styled.span`
  color: ${colors.warmGreyFive};
`
