import { ProductImage } from 'Components/Common/Layout/Image'
import QuantitySpinner from 'Components/Common/QuantitySpinner'
import { updateDeviceAccessoryQuantity } from 'Ducks/common/cart'
import { CustomProductType, ProductVariantShape } from 'Lib/propTypes'
import { max } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { createSelector } from 'reselect'

const styles = {
  accessoryContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  quantityContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export const accessorySelector = createSelector(
  (state, props) => state.products.list.find(({ id }) => id === props.product),
  (state, props) =>
    state.products.list
      .find(({ id }) => id === props.product)
      .productVariants.find(({ id }) => id === props.variant),
  (product, variant) => ({ product, variant }),
)

const mapStateToProps = (state, props) => ({
  accessory: accessorySelector(state, props),
})

const useStyles = createUseStyles(styles)
const enhance = compose(
  withRouter,
  connect(mapStateToProps, { updateDeviceAccessoryQuantity }),
)

const DeviceAccessory = ({
  updateDeviceAccessoryQuantity,
  index,
  ...props
}) => {
  const classes = useStyles()
  const { variant, accessory, quantity } = props

  const imageEntity =
    accessory.variant.imageEntity || accessory.product.imageEntity

  return (
    <div className={classes.accessoryContainer}>
      <div className={classes.quantityContainer}>
        <QuantitySpinner
          size={15}
          quantity={quantity}
          increment={() =>
            updateDeviceAccessoryQuantity({
              index,
              variant,
              quantity: quantity + 1,
            })
          }
          decrement={() =>
            updateDeviceAccessoryQuantity({
              index,
              variant,
              quantity: max([quantity - 1, 1]),
            })
          }
        />
      </div>
      <div style={{ marginLeft: 8 }}>
        <ProductImage
          imageEntity={imageEntity}
          alt={accessory.product.name}
          title={accessory.product.name}
          width={100}
          height={40}
        />
      </div>
    </div>
  )
}

DeviceAccessory.propTypes = {
  accessory: PropTypes.shape({
    product: CustomProductType,
    variant: ProductVariantShape,
  }).isRequired,
  variant: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  updateDeviceAccessoryQuantity: PropTypes.func.isRequired,
}

export default enhance(DeviceAccessory)
