import colors from 'Assets/styles/colors'

export default theme => ({
  text: {
    fontSize: '19px',
    fontWeight: 600,
  },
  phone: {
    extend: 'text',
    textDecoration: 'none',
    fontWeight: '600',
    lineHeight: '1.32',
    letterSpacing: '0.4px',
    color: colors.lightBlack,
  },
  logo: {
    '& > div': {
      justifyContent: 'left',
    },
  },
  mail: {
    extend: 'text',
    textDecoration: 'none',
    lineHeight: '0.89',
    letterSpacing: '1.1px',
    color: theme.firstColor,
  },
  fullHeight: {
    height: '100%',
  },
  navigationHeader: {
    extend: 'text',
    fontSize: '19.6px',
    fontWeight: 900,
    lineHeight: '1.0',
    letterSpacing: '0.4px',
    color: colors.greyishBrown,
    textTransform: 'uppercase',
    marginBottom: 48,
  },
  navigationItems: {
    extend: 'text',
    fontSize: '16px',
    lineHeight: '2.44',
    letterSpacing: '0.3px',
    color: colors.lightBlack,
    textDecoration: 'none',
    '&:not(:last-child)': {
      marginBottom: 5,
    },
  },
  socialIcon: {
    width: '47px',
    height: '47px',
    opacity: '0.3',
    backgroundColor: colors.pinkishGrey,
    border: 'solid 1px',
    borderRadius: '47px',
    borderColor: colors.brownisGreyTwo,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: colors.white,
      backgroundColor: theme.firstColor,
      opacity: 1,
      border: '0px ',
    },
  },
  section: {
    padding: '0 88px',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },

    '&:not(:last-child)': {
      borderRight: {
        width: 1,
        style: 'solid',
        color: colors.whiteSeven,
      },
    },
    '& > div:last-child': {
      marginBottom: '-14px',
    },

    /* Tablet Portrait and lower */
    '@media(max-width: 1023px)': {
      /* Switch border styles */
      '&:not(:last-child)': {
        borderRight: {
          width: '0px',
        },
      },
      '&:not(:last-child) > div': {
        borderBottom: {
          width: '1px',
          style: 'solid',
          color: colors.whiteSeven,
        },
        paddingTop: '2em',
        paddingBottom: '2em',
      },

      /* Reset first and last items padding */
      '&:last-of-type': {
        paddingRight: 88,
      },
      '&:first-of-type': {
        paddingLeft: 88,
      },
    },

    '@media(max-width: 481px)': {
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
    },
  },
  socialSection: {
    extend: 'section',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    /* Tablet Portrait and lower */
    '@media(max-width: 1023px)': {
      /* Add Padding */
      '& > div:first-of-type': {
        paddingTop: '2em',
      },
    },
  },
  footerText: {
    display: 'flex',
    justifyContent: 'center',
    padding: [20, 8],
    marginTop: 40,

    '& span': {
      extend: 'text',
      color: colors.lightBlack,
      fontSize: 16,
      display: 'block',
      margin: [8, 0],
      padding: [0, 16],
    },
    '& a': {
      extend: 'text',
      color: colors.lightBlack,
      fontSize: 16,
      textDecoration: 'none',
      display: 'block',
      margin: [8, 0],
      padding: [0, 16],
    },
    '& > *:not(:last-child)': {
      borderRight: {
        width: 1,
        style: 'solid',
        color: colors.whiteSeven,
      },
    },

    /* Tablet Portrait and lower */
    '@media(max-width: 1023px)': {
      textAlign: 'center',
      '& > *:not(:last-child)': {
        borderRight: {
          width: '0px',
        },
      },
      '& span, & a': {
        margin: [3, 0],
      },
    },
  },
  link: {
    color: 'inherit',
  },
})
