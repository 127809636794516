import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from '../Layout/Form/SelectField'

export const legalForms = [
  'GmbH',
  'GmbH & Co KG',
  'AG',
  'KG',
  'GbR',
  'Gewerbebetrieb',
  'OHG',
  'e.K. - eingetragener Kaufmann',
  'Staat',
  'Kirche',
  'Partei',
  'Freiberufler',
  'e.V.',
  'e.G.',
  'UG',
]

const LegalFormSelect = props => {
  const { t } = useTranslation('checkout')
  return (
    <SelectField
      {...props}
      placeholder={t('form.legalForm')}
      options={legalForms.map(legalForm => ({
        value: legalForm,
        label: legalForm,
      }))}
    />
  )
}

export default LegalFormSelect
