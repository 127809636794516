import { get, merge } from 'lodash'
import { SET_LANGUAGE } from '../common/config'

export const ALSO_PRODUCT_VARIANTS_FETCH_LIST_REQUEST =
  'ALSO_PRODUCT_VARIANTS::FETCH_LIST_REQUEST'
export const ALSO_PRODUCT_VARIANTS_FETCH_LIST_FAIL =
  'ALSO_PRODUCT_VARIANTS::FETCH_LIST_FAIL'
export const ALSO_PRODUCT_VARIANTS_FETCH_LIST_SUCCESS =
  'ALSO_PRODUCT_VARIANTS::FETCH_LIST_SUCCESS'

const initialState = { ids: [], entities: {}, default: {}, fetching: false }

export const fetchProductList = () => ({
  type: ALSO_PRODUCT_VARIANTS_FETCH_LIST_REQUEST,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALSO_PRODUCT_VARIANTS_FETCH_LIST_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case ALSO_PRODUCT_VARIANTS_FETCH_LIST_SUCCESS: {
      return {
        ...state,
        ids: Object.keys(action.data),
        entities: action.data,
        default: action.data,
        fetching: false,
      }
    }
    case ALSO_PRODUCT_VARIANTS_FETCH_LIST_FAIL: {
      return {
        ...state,
        list: [],
        fetching: false,
      }
    }

    case SET_LANGUAGE: {
      return {
        ...state,
        entities: state.ids.reduce(
          (prev, id) => ({
            ...prev,
            [id]: merge(
              {},
              state.default[id],
              get(state.default[id], `translations.${action.payload.lang}`),
            ),
          }),
          {},
        ),
      }
    }
    default:
      return state
  }
}

export default reducer
