import ReactRouterPropTypes from 'react-router-prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import flags from 'Lib/config'
import { fetchPageList } from 'Ducks/common/pages'
import Heading from '../Layout/Heading'
import MetaData from '../Layout/MetaData'
import Section from '../Layout/Section'
import NotFound from './NotFound'

const Page = ({
  location: { state },
  match: {
    params: { slug },
  },
}) => {
  const dispatch = useDispatch()
  const page = useSelector(({ pages: { list } }) =>
    list.find(p => p.slug === slug),
  )

  useEffect(() => {
    dispatch(fetchPageList())
  }, [slug])

  if (!page) {
    return <NotFound />
  }

  const { content, title, metaData } = page

  return (
    <>
      <MetaData
        title={title}
        metaData={metaData}
        canonical={slug}
        robots={slug === 'bestellbestaetigung' && 'noindex,nofollow'}
      />
      <Heading>{title}</Heading>
      <Section padding="80">
        <div dangerouslySetInnerHTML={{ __html: content }} />
        {!flags.reseller.also &&
          slug === 'bestellbestaetigung' &&
          get(state, 'orderNumber') && (
            <div>
              <h2>Informationen zu ihrer Bestellung</h2>
              <p>
                <b>Bestellnummer</b>: {state.orderNumber}
              </p>
            </div>
          )}
      </Section>
    </>
  )
}

Page.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
}

export default Page
