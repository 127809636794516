import PropTypes from 'prop-types'

const numberFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
})

const CurrencyNumber = ({ value }) => numberFormat.format(value)

CurrencyNumber.propTypes = {
  value: PropTypes.number.isRequired,
}

export default CurrencyNumber
