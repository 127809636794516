import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import styled from 'styled-components/macro'
import Section from 'Components/Common/Layout/Section'
import CategoryLink from 'Components/Common/CategoryPage/CategoryLink'
import useStoreView from 'Hooks/useStoreView'
import history from 'Lib/history'
import { Title } from './CategoryLinkStyledComponents'

const BackLinkContainer = styled.div`
  margin-top: 16px;
  padding: 8px;
  span {
    color: ${props => props.theme.firstColor};
  }
`

const CategoryLinks = ({ categories, categorySelected }) => {
  const isMobile = useMedia({ maxWidth: 1024 })
  const allProductsSlug = useStoreView('seoConfiguration.productOverviewSlug')

  if (isMobile && categorySelected) {
    return (
      <BackLinkContainer
        role="button"
        tabIndex={0}
        onClick={() => history.push(allProductsSlug)}
        onKeyPress={() => history.push(allProductsSlug)}
      >
        <Title>Zurück zur Übersicht</Title>
      </BackLinkContainer>
    )
  }

  return (
    <Section key="links" padding="0">
      <div className="grid-x align-justify">
        {categories
          .sort((a, b) => a.position - b.position)
          .filter(c => c.active && c.visible && !c.isAccessory)
          .map(c => (
            <CategoryLink key={c.id} {...c} />
          ))}
      </div>
    </Section>
  )
}

CategoryLinks.propTypes = {
  categories: PropTypes.array.isRequired, // eslint-disable-line
  categorySelected: PropTypes.bool,
}

CategoryLinks.defaultProps = {
  categorySelected: false,
}

export default CategoryLinks
