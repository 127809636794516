import typography from 'Components/Common/Layout/typography'
import colors from 'Assets/styles/colors'

export default {
  container: {
    ...typography.text,
    // height: '302px',
    borderRadius: '3px',
    backgroundColor: '#ffffff',
    border: {
      width: 1,
      style: 'solid',
      color: colors.whiteFive,
    },
    width: '100%',
    padding: [40, 0],
    margin: [16, 0],
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [0, 16],
    position: 'relative',
  },
  priceContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: [0, 16],
    alignItems: 'center',
    '& div:not(:first-child)': {
      marginTop: '24px',
    },
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:first-child) ': {
      marginTop: 32,
    },
    padding: [0, 16],
    '@media (min-width: 1024px)': {
      borderRight: {
        width: 1,
        style: 'solid',
        color: colors.whiteFive,
      },
    },
  },
  name: {
    fontSize: '30px',
    fontWeight: '600',
    lineHeight: '0.83',
    letterSpacing: '0.6px',
    textAlign: 'left',
    textDecoration: 'none',
    color: colors.greyishBrownThree,
  },
  description: {
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '1.39',
    letterSpacing: '0.5px',
    color: colors.brownishGrey,
  },
  price: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '0.78',
    textAlign: 'center',
    color: colors.greyishBrown,
  },
}
