import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStoreView from 'Hooks/useStoreView'
import get from 'lodash/get'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import Image from '../../Layout/Image'

const printStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '8px',
    },
  },
}

const usePrintStyles = createUseStyles(printStyles)

const PrintFooter = () => {
  const classes = usePrintStyles()

  const phone = useSelector(state =>
    get(state, 'resellerInfo.data.address.phone'),
  )
  const contactEmail = useStoreView('contactEmail')
  const companyLogoFooter = useStoreView('companyLogoFooter')
  const contactUrl = useStoreView('contactUrl')

  const resellerName = useSelector(state =>
    get(state.resellerInfo, 'data.name'),
  )

  return (
    <div className={classes.root}>
      <Image src={companyLogoFooter} alt={contactUrl} width={250} height={62} />
      <div>
        <div>{resellerName}</div>
        <div>
          <FontAwesomeIcon icon="phone" />
          &nbsp;{phone}
        </div>
        <div>
          <FontAwesomeIcon icon="envelope" />
          &nbsp;{contactEmail}
        </div>
        <div>
          <FontAwesomeIcon icon="globe" />
          &nbsp;{contactUrl}
        </div>
      </div>
    </div>
  )
}

export default PrintFooter
