import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classnames from 'classnames'
import { CustomProductType, ProductVariantType } from 'Lib/propTypes'
import Accessory from '../Accessory'

const enhance = compose(withRouter)

const AccessorySelect = ({
  slug,
  product,
  variant,
  selected,
  toggleSelected,
  bundled,
}) => {
  const color = variant.attributeValues.find(a => a.attribute === 'Farbe')
  const colorValue = color && capitalize(color.value)

  const imageEntity = variant.imageEntity || product.imageEntity

  return (
    <Accessory
      className={classnames(`product-variant-${variant.id}`, {
        selected,
      })}
      bundled={bundled}
      name={product.name}
      hideColor
      imageEntity={imageEntity}
      slug={slug}
      selected={selected}
      selectable
      color={colorValue}
      interactiveProps={{
        onClick: toggleSelected,
        onKeyPress: toggleSelected,
        role: 'button',
        tabIndex: 0,
      }}
    />
  )
}

AccessorySelect.propTypes = {
  product: CustomProductType.isRequired,
  variant: ProductVariantType.isRequired,
  selected: PropTypes.bool,
  toggleSelected: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
}

AccessorySelect.defaultProps = {
  selected: false,
}

export default enhance(AccessorySelect)
