import color from 'color'
import get from 'lodash/get'
import trim from 'lodash/trim'
import React, { useMemo } from 'react'
import { ThemeProvider } from 'react-jss'
import { useSelector } from 'react-redux'

// TODO: remove this with react-jss
const ConnectedTheme = ({ children }) => {
  const re = /[0-9A-Fa-f]{6}/g

  const colors = useSelector(state => ({
    firstColor: trim(get(state.storeView, 'data.firstColor')) || '#a8ce38',
    errorColor: trim(get(state.storeView, 'data.errorColor')) || 'red',
  }))

  Object.keys(colors).forEach(key => {
    if (re.test(colors[key]) && colors[key].substr(0, 1) !== '#') {
      colors[key] = `#${colors[key]}`
    }
  })
  const theme = useMemo(
    () => ({
      ...colors,
      darkerFirstColor: color(colors.firstColor)
        .darken(0.2)
        .string(),
    }),
    [colors.firstColor, colors.errorColor],
  )
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ConnectedTheme
