import colors from 'Assets/styles/colors'
import DeviceBoxSection from 'Components/Common/Home/DeviceBoxSection'
import useStoreView from 'Hooks/useStoreView'
import React from 'react'
import { ShowForScreenSize } from 'react-foundation-components/lib/visibility'
import styled from 'styled-components/macro'
import MetaData from '../Layout/MetaData'
import BenefitSection from './BenefitSection'
import Logos from './Logos'
import SEOSection from './SEOSection'
import Slider from './Slider'
import StatsSection from './StatsSection'
import TeamSection from './TeamSection'
import TestimonialSection from './TestimonialSection'

const Container = styled.div`
  width: 100%;
  & > section:nth-of-type(2n) {
    background-color: ${colors.whiteFour};
  }
`

const Home = () => {
  const metaData = useStoreView('metaData')
  return (
    <Container>
      <MetaData title="Home" metaData={metaData} />
      <ShowForScreenSize screenSize="medium">
        <Slider />
      </ShowForScreenSize>
      <DeviceBoxSection />
      <BenefitSection />
      <TestimonialSection />
      <Logos />
      <TeamSection />
      <StatsSection />
      <SEOSection />
    </Container>
  )
}

export default Home
