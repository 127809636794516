import cart from 'Ducks/common/cart'
import devices from 'Ducks/common/devices'
import products from 'Ducks/common/products'
import alsoProducts from 'Ducks/also/alsoProducts'
import alsoProductVariants from 'Ducks/also/alsoProductVariants'
import alsoServices from 'Ducks/also/alsoServices'
import alsoCategories from 'Ducks/also/alsoCategories'
import alsoCart from 'Ducks/also/alsoCart'
import alsoConfig from 'Ducks/also/alsoConfig'
import categories from 'Ducks/common/categories'
import productConfiguration from 'Ducks/common/productConfiguration'
import services from 'Ducks/common/services'
import serviceExtensions from 'Ducks/common/serviceExtensions'
import auth from 'Ducks/common/auth'
import config from 'Ducks/common/config'
import testimonials from 'Ducks/common/testimonials'
import resellerInfo from 'Ducks/common/resellerInfo'
import storeView from 'Ducks/common/storeView'
import team from 'Ducks/common/team'
import protectionPlans from 'Ducks/common/protectionPlans'
import pages from 'Ducks/common/pages'
import links from 'Ducks/common/links'
import slides from 'Ducks/common/slides'
import benefits from 'Ducks/common/benefits'
import marketingLogos from 'Ducks/common/marketingLogos'
import fetch from 'Ducks/common/fetch'
import promoBundles from 'Ducks/common/promoBundles'

const waasApp = {
  cart,
  devices,
  products,
  alsoProducts,
  alsoProductVariants,
  alsoCategories,
  alsoCart,
  alsoServices,
  alsoConfig,
  productConfiguration,
  categories,
  services,
  serviceExtensions,
  auth,
  config,
  testimonials,
  team,
  resellerInfo,
  storeView,
  protectionPlans,
  pages,
  links,
  slides,
  benefits,
  marketingLogos,
  promoBundles,
  fetch,
}

export default waasApp
