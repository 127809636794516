import classnames from 'classnames'
import {
  Period,
  PeriodMonths,
  PeriodNumber,
} from 'Components/Common/UI/PeriodSelect'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { termsForVariantSelector } from 'Selectors/also/productSelectors'

const AlsoTermSelect = ({ term, onChange, availableTerms, small }) => (
  <div className="grid-x">
    {availableTerms.map(p => (
      <Period
        key={p}
        role="button"
        tabIndex={0}
        active={term === p}
        onKeyPress={() => onChange(p)}
        onClick={() => onChange(p)}
        className={classnames('cell', 'auto')}
      >
        <PeriodNumber active={term === p}>{p}</PeriodNumber>
        {!small && <PeriodMonths active={term === p}>Monate</PeriodMonths>}
      </Period>
    ))}
  </div>
)

AlsoTermSelect.propTypes = {
  term: PropTypes.number.isRequired,
  small: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  availableTerms: PropTypes.arrayOf(PropTypes.number).isRequired,
}

AlsoTermSelect.defaultProps = {
  small: false,
}

const mapStateToProps = (state, props) => ({
  availableTerms: termsForVariantSelector(state, {
    variant: props.workplaceUserDevice,
  }),
})
const enhance = compose(connect(mapStateToProps))

const WrappedAlsoTermSelect = enhance(AlsoTermSelect)

export const StyledAlsoTermSelect = styled(WrappedAlsoTermSelect)`
  border-radius: 3px;
  & > *:first-child {
    border-radius: 3px 0 0 3px;
  }
  & > *:last-child {
    border-radius: 0 3px 3px 0;
  }
`

export default WrappedAlsoTermSelect
