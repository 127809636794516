import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    fontSize: '36px',
    fontStyle: 'bold',
  },
}

const useStyles = createUseStyles(styles)

const EmptyCart = () => {
  const classes = useStyles()
  const { t } = useTranslation('cart')
  return (
    <div className={classes.container}>
      <span className={classes.content}>{t('cartIsEmpty')}</span>
    </div>
  )
}

export default EmptyCart
