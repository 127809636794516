import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  decrementDeviceQuantity,
  incrementDeviceQuantity,
  removeDeviceFromCart,
} from 'Ducks/common/cart'
import { DeviceConfigurationShape } from 'Lib/propTypes'
import { deviceConfigurationMonthlyRateSelector } from 'Selectors/common/priceSelectors'
import Device from './Device'
import Line from './Line'

const DeviceLine = ({ deviceConfiguration, index }) => {
  const price = useSelector(state =>
    deviceConfigurationMonthlyRateSelector(state, deviceConfiguration),
  )
  const dispatch = useDispatch()

  return (
    <Line
      quantity={deviceConfiguration.quantity}
      increment={() => dispatch(incrementDeviceQuantity(index))}
      decrement={() => dispatch(decrementDeviceQuantity(index))}
      remove={() => dispatch(removeDeviceFromCart(index))}
      price={price}
    >
      <Device deviceConfiguration={deviceConfiguration} index={index} />
    </Line>
  )
}

DeviceLine.propTypes = {
  deviceConfiguration: PropTypes.shape(DeviceConfigurationShape).isRequired,
  index: PropTypes.number.isRequired,
}

export default DeviceLine
