import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import styled from 'styled-components/macro'

const StyledContainer = styled.div`
  border: 2px solid;
  border-radius: 3px;
  border-color: ${props => props.theme.firstColor};
  padding: 4px;
`

const Bundles = ({ bundles }) => {
  if (bundles.length === 0) return null
  return bundles.map(({ bundle }) => (
    <StyledContainer>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: bundle.shortDescription,
        }}
      />
      <div>
        {`Gültigkeitszeitraum: ${DateTime.fromISO(
          bundle.validFrom,
        ).toLocaleString()} bis ${DateTime.fromISO(
          bundle.validTo,
        ).toLocaleString()}`}
      </div>
    </StyledContainer>
  ))
}

Bundles.propTypes = {
  bundles: PropTypes.arrayOf(
    PropTypes.shape({
      validFrom: PropTypes.string.isRequired,
      validTo: PropTypes.string.isRequired,
      shortDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Bundles
