import colors from 'Assets/styles/colors'
import classnames from 'classnames'
import color from 'color'
import flatMap from 'lodash/flatMap'
import padStart from 'lodash/padStart'
import range from 'lodash/range'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import PrivacyStatement from '../../Checkout/PrivacyStatement'
import { renderField } from '../../Layout/Form/FormField'
import validate from './ContactValidation'
import renderDropdown from './DropDown'
import SendButton from './SendButton'

const styles = theme => ({
  text: {
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '1.78',
    letterSpacing: '0.7px',
    textAlign: 'center',
    color: colors.white,
  },
  input: {
    height: 57,
    textAlign: 'left',
    border: 0,
    paddingLeft: '4px',
    letterSpacing: '0.4px',
    lineHeight: '57px',
    color: colors.warmGreyFive,
    '&::placeholder': {
      color: colors.pinkishGrey,
    },
  },
  fieldGroupContainer: {
    '@media (min-width: 1020px)': {
      paddingRight: '2px !important',
    },
  },
  fieldGroup: {
    '& > *': {
      margin: [4, 0],
      '@media (min-width: 640px)': {
        padding: [0, 4],
      },
    },
  },
  select: {
    height: 57,
  },
  form: {
    '& .r-select__option--is-selected': {
      backgroundColor: color(theme.firstColor)
        .fade(0.5)
        .string(),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .r-select__option--is-focused': {
      backgroundColor: color(theme.firstColor)
        .fade(0.3)
        .string(),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .r-select__control--is-focused, & .r-select__control--is-focused:hover': {
      borderColor: 'hsl(0, 0%, 80%)',
      boxShadow: 'none',
    },
    '& .r-select__menu': {
      marginTop: 0,
      borderRadius: '0 0 4px 4px',
    },
  },
  checkboxLabel: {
    color: colors.white,
  },
})

const useStyles = createUseStyles(styles)

const ContactForm = ({ onSubmit }) => {
  const classes = useStyles()

  const { t } = useTranslation('contact')
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, submitError }) => (
        <form
          onSubmit={handleSubmit}
          name="contact"
          className={classnames('contact', 'small-gutter-form', classes.form)}
        >
          <div className="grid-x grid-padding-x align-center">
            <div
              className={classnames(
                'cell',
                'medium-12',
                'large-10',
                classes.fieldGroupContainer,
              )}
            >
              <div className={classnames('cell', 'grid-x', classes.fieldGroup)}>
                <div className="cell medium-4">
                  <Field
                    type="text"
                    icon="address-card"
                    expand
                    dark
                    component={renderField}
                    label={t('name')}
                    name="name"
                    id="name"
                  />
                </div>
                <div className="cell medium-4">
                  <Field
                    type="text"
                    icon="phone"
                    expand
                    dark
                    component={renderField}
                    label={t('phone')}
                    name="phone"
                    id="phone"
                  />
                </div>
                <div className="cell medium-4">
                  <Field
                    type="text"
                    icon="envelope"
                    expand
                    dark
                    component={renderField}
                    label={t('email')}
                    name="email"
                    id="email"
                  />
                </div>
                <div className="cell medium-4">
                  <Field
                    type="text"
                    icon="building"
                    expand
                    dark
                    component={renderField}
                    label={t('company')}
                    name="company"
                    id="company"
                  />
                </div>
                <div className="cell medium-4">
                  <Field
                    name="weekday"
                    icon="calendar-alt"
                    dark
                    component={renderDropdown}
                    placeholder="Gewünschter Wochentag"
                    options={[
                      'Montag',
                      'Dienstag',
                      'Mittwoch',
                      'Donnerstag',
                      'Freitag',
                    ].map(d => ({
                      value: d,
                      label: d,
                    }))}
                  />
                </div>
                <div className="cell medium-4">
                  <Field
                    name="time"
                    icon="clock"
                    dark
                    placeholder="Gewünschte Uhrzeit"
                    options={flatMap(range(9, 17), hour => {
                      const firstHalf = `${padStart(
                        hour,
                        2,
                        '0',
                      )}:00 - ${padStart(hour, 2, '0')}:30`
                      const secondHalf = `${padStart(
                        hour,
                        2,
                        '0',
                      )}:30 - ${padStart(hour + 1, 2, '0')}:00`
                      return [
                        { value: firstHalf, label: firstHalf },
                        { value: secondHalf, label: secondHalf },
                      ]
                    })}
                    component={renderDropdown}
                  />
                </div>

                <PrivacyStatement style={{ color: 'white' }} />
              </div>
            </div>
            <SendButton sending={submitting} />
          </div>
          {submitError && (
            <div>
              <span className={classes.authError}>{t('error')}</span>
            </div>
          )}
        </form>
      )}
    />
  )
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ContactForm
