import React from 'react'
import { useSelector } from 'react-redux'
import Section from 'Components/Common/Layout/Section'

const ServiceExtensionsOverview = () => {
  const serviceExtensions = useSelector(
    ({ serviceExtensions }) => serviceExtensions.list,
  )

  return (
    <Section>
      {serviceExtensions
        .sort((a, b) => a.position - b.position)
        .map(s => (
          <div key={s.id}>
            <h2>{s.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: s.description }} />
          </div>
        ))}
    </Section>
  )
}

export default ServiceExtensionsOverview
