import CurrencyNumber from 'Components/Common/CurrencyNumber'
import ListItem from 'Components/Common/Device/ListItem'
import 'Components/Common/Device/style.css'
import Select from 'Components/Common/Layout/Select'
import { groupBy } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

const transformToOptions = services =>
  services.map(service => ({
    value: service.id,
    label: (
      <div css="margin-right: 10px">
        <div>
          {service.service.name} -{' '}
          <CurrencyNumber value={service.monthlyPrice} />
        </div>
      </div>
    ),
  }))

const ServiceSelect = ({ selected, services, setService }) => {
  const options = transformToOptions(services)

  const selectedObject = options.find(o => o.id === selected)
  return (
    <Select
      className="r-select"
      classNamePrefix="r-select"
      defaultValue={null}
      options={options}
      isClearable
      placeholder="Bitte wählen"
      onChange={option =>
        option ? setService(option.value) : setService(null)
      }
      isSearchable={false}
      value={selectedObject}
    />
  )
}

ServiceSelect.propTypes = {
  selected: PropTypes.string,
  services: PropTypes.array.isRequired,
  setService: PropTypes.func.isRequired,
}

ServiceSelect.defaultProps = {
  selected: null,
}

const ServiceSelects = () => {
  const alsoServices = useSelector(state =>
    state.alsoServices.ids.map(id => state.alsoServices.entities[id]),
  )

  const servicesActive = useSelector(({ resellerInfo }) =>
    get(resellerInfo, 'data.flags.alsoServices'),
  )

  if (!servicesActive) {
    return null
  }

  const groups = groupBy(alsoServices, 'service.category')

  return Object.keys(groups).map(g => (
    <ListItem header={g}>
      <Field
        name={`Services.${g}`}
        render={({ input }) => (
          <ServiceSelect
            selected={input.value}
            services={groups[g]}
            setService={input.onChange}
          />
        )}
      />
    </ListItem>
  ))
}

export default ServiceSelects
