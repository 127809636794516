import colors from 'Assets/styles/colors'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

const style = theme => ({
  stickyNote: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    color: colors.white,
    backgroundColor: colors.lightBlack,
    padding: '0.25em 1em',
    fontSize: '0.75em',
    zIndex: 9999,
  },
})
const useStyles = createUseStyles(style)

const StickyResellerNote = () => {
  const classes = useStyles()

  const vat = useSelector(({ config: { vat } }) => vat)

  return (
    <div className={classes.stickyNote}>
      Unser Angebot richtet sich ausschließlich an gewerbliche Endkunden. Alle
      Preise zzgl. {vat * 100}% MwSt.
    </div>
  )
}

export default StickyResellerNote
