import { FORM_ERROR } from 'final-form'
import { sendPasswordResetRequest } from 'Lib/fetch'
import { isEmail } from 'Lib/validationHelper'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import SubmitButton from '../Button/SubmitButton'
import { renderField } from '../Layout/Form/FormField'
import styles from './styles'

const validate = values => {
  const requiredMessage = 'required'
  const errors = {}
  if (!values.email) {
    errors.email = requiredMessage
  } else if (!isEmail(values.email)) {
    errors.email = 'invalidEmail'
  }
  return errors
}

const onSubmit = async values => {
  try {
    await sendPasswordResetRequest(values)
    // this.props.push(parsed.ref || '/')
  } catch (e) {
    console.log(e)
    return { [FORM_ERROR]: 'genericError' }
  }
}
const useStyles = createUseStyles(styles)

const PasswordResetRequestForm = () => {
  const { t } = useTranslation('auth')
  const classes = useStyles()
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, submitError, submitSucceeded }) =>
        submitSucceeded ? (
          <p>
            <Field
              name="email"
              subscription={{ value: true }}
              render={({ input: { value } }) =>
                t('auth:passwordResetEmailSent', { email: value })
              }
            />
          </p>
        ) : (
          <form className="small-gutter-form" onSubmit={handleSubmit}>
            <div className="grid-x grid-margin-x">
              <div className="cell medium-12 large-6">
                <Field
                  type="text"
                  component={renderField}
                  label={t('email')}
                  name="email"
                  expand
                />
              </div>
              <div className="cell medium-12 large-6">
                <SubmitButton
                  height={59}
                  fetching={submitting}
                  disabled={submitting}
                >
                  {t('submitPasswordResetRequest')}
                </SubmitButton>
              </div>
              {submitError && (
                <div className="cell">
                  <span className={classes.authError}>{t(submitError)}</span>
                </div>
              )}
            </div>
          </form>
        )
      }
    />
  )
}

export default PasswordResetRequestForm
