import { FORM_ERROR } from 'final-form'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { login } from 'Lib/fetch'
import { isEmail } from 'Lib/validationHelper'
import { setToken } from 'Ducks/common/auth'
import { FETCH_DISCOUNTABLES_REQUEST } from 'Sagas/fetchSaga'
import slugify from 'slugify'
import { AUTH_TOKEN } from 'Lib/constants'
import SubmitButton from '../Button/SubmitButton'
import { renderField } from '../Layout/Form/FormField'
import styles from './styles'

const validate = values => {
  const requiredMessage = 'required'
  const errors = {}
  if (!values.password) {
    errors.password = requiredMessage
  }
  if (!values.email) {
    errors.email = requiredMessage
  } else if (!isEmail(values.email)) {
    errors.email = 'invalidEmail'
  }
  return errors
}

const useStyles = createUseStyles(styles)

const LoginForm = ({ onSuccess }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const dispatch = useDispatch()

  const handleSubmit = async values => {
    try {
      const {
        data: { token },
      } = await login(values)
      const decoded = jwtDecode(token)
      if (!decoded.roles.find(r => r === 'ROLE_CUSTOMER')) {
        throw new Error('Not a customer')
      }
      dispatch(setToken(token))
      sessionStorage.setItem(AUTH_TOKEN, token)
      dispatch({ type: FETCH_DISCOUNTABLES_REQUEST })
      onSuccess()
    } catch (e) {
      if (e.response) {
        return { [FORM_ERROR]: e.response.data.message }
      }
      return { [FORM_ERROR]: 'genericError' }
    }
    return undefined
  }

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit} className="small-gutter-form">
            <div className="grid-x grid-margin-x">
              <div className="cell medium-12 large-4">
                <Field
                  type="text"
                  component={renderField}
                  label={t('email')}
                  name="email"
                  expand
                />
              </div>
              <div className="cell medium-12 large-4">
                <Field
                  type="password"
                  component={renderField}
                  label={t('password')}
                  name="password"
                  expand
                />
              </div>
              <div className="cell medium-12 large-4">
                <SubmitButton
                  height={59}
                  fetching={submitting}
                  disabled={submitting}
                >
                  {t('submitLogin')}
                </SubmitButton>
              </div>
            </div>
            {submitError && (
              <div>
                <span className={classes.authError}>
                  {t(
                    slugify(submitError, {
                      replacement: '-', // replace spaces with replacement
                      remove: /\./, // regex to remove characters
                      lower: true,
                    }),
                  )}
                </span>
              </div>
            )}
          </form>
        )}
      />
    </div>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func,
}

LoginForm.defaultProps = {
  onSuccess: () => {},
}

export default LoginForm
