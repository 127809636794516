import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import './imagePlaceholder.css'
import { url } from 'Lib/fetch'

const styles = {
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    /* Set Width to auto to prevent responsive image sizing */
    '@media(max-width: 1023px)': {
      width: 'auto !important',
    },
  },
  linkContainer: {
    extend: 'imageContainer',
    cursor: 'pointer',
  },
  placeholder: {
    display: 'flex',
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
}

const useStyles = createUseStyles(styles)

const Image = ({ src, alt, title, height, width, onClick, link, style }) => {
  const classes = useStyles()
  let imageProps = {}
  if (onClick) {
    imageProps = {
      ...imageProps,
      onClick,
      tabIndex: 0,
      onKeyPress: onClick,
      role: 'button',
    }
  }

  return (
    <div
      className={classNames({
        [classes.linkContainer]: link,
        [classes.imageContainer]: !link,
      })}
      style={{ height, width, ...style }}
    >
      <img src={src} alt={alt} title={title} {...imageProps} />
    </div>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  link: PropTypes.string,
}

Image.defaultProps = {
  src: '',
  title: '',
  onClick: null,
  link: null,
}

export default Image

const Placeholder = ({ width, height, title, alt }) => {
  const classes = useStyles()

  return (
    <div
      style={{
        width,
        height,
      }}
      className={classes.placeholder}
      alt={alt}
      title={title}
    >
      {title}
    </div>
  )
}

export const ProductImage = props => {
  const { imageEntity, width, height } = props

  if (!imageEntity) {
    return <Placeholder {...props} />
  }
  return <Image {...props} src={`${url}${imageEntity}/${width}/${height}`} />
}

ProductImage.propTypes = {
  imageEntity: PropTypes.string,
}

ProductImage.defaultProps = {
  imageEntity: undefined,
}
