import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import head from 'lodash/head'
import { connect } from 'react-redux'
import { typeSlugServicesSelector } from 'Selectors/common/listSelectors'
import Heading from 'Components/Common/Layout/Heading'
import Section from 'Components/Common/Layout/Section'
import MetaData from 'Components/Common/Layout/MetaData'

const CloudServices = ({
  services,
  match: {
    params: { slug },
  },
}) => [
  <MetaData key="metadata" title={slug} canonical={slug} />,
  <Heading key="heading">{get(head(services), 'type')}</Heading>,
  <Section key="content" padding="80">
    {services
      .sort((a, b) => a.position - b.position)
      .map(s => (
        <div key={s.id}>
          <h2>{s.name}</h2>
          <div dangerouslySetInnerHTML={{ __html: s.description }} />
        </div>
      ))}
  </Section>,
]

CloudServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      type: PropTypes.string.isRequired,
    }),
  ),
}

CloudServices.defaultProps = {
  services: [],
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { slug },
    },
  },
) => ({
  services: typeSlugServicesSelector(slug)(state),
})

export default connect(mapStateToProps)(CloudServices)
