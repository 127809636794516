import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { sortBy, head } from 'lodash'
import Select from 'Components/Common/Layout/Select'

const options = variants =>
  sortBy(variants, 'netPrice').map(v => {
    const label =
      v.specs.length > 0 ? v.specs.map(spec => spec.spec).join(' / ') : v.name
    return {
      value: v.id,
      label: <div>{label}</div>,
    }
  })

const DeviceVariantSelect = ({ selected, variants, setVariant }) => {
  const variantsAsOptions = useMemo(() => options(variants), [])
  const selectedObject = variantsAsOptions.find(v => v.value === selected)
  return (
    <Select
      className="r-select"
      classNamePrefix="r-select"
      defaultValue={head(variantsAsOptions)}
      options={variantsAsOptions}
      onChange={({ value }) => setVariant(value)}
      value={selectedObject}
    />
  )
}

DeviceVariantSelect.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      netPrice: PropTypes.number.isRequired,
      specs: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setVariant: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
}

export default DeviceVariantSelect
