/* eslint-disable react/style-prop-object */
import clsx from 'clsx'
import { sumBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { totalMonthlySelector } from 'Selectors/also/priceSelectors'
import AlsoCartPrintLine from 'Components/Also/Cart/Print/CartLine'
import './print.scss'

const styles = {
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
    '& th,td': {
      padding: '4px',
      whiteSpace: 'nowrap',
    },
  },
  text: {
    textAlign: 'left',
  },
  numeral: {
    textAlign: 'right',
  },
  expand: {
    // width: '99%',
    whiteSpace: [['normal'], '!important'],
  },
  workplaceRow: {
    color: 'gray',
  },
  colorShaded: {
    color: 'gray',
  },
  totalCell: {
    fontWeight: 'bold',
    '& > div': {
      display: 'inline-block',
      width: '240px',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  firstFooterRow: {
    '& th,td': {
      paddingTop: '20px',
    },
  },
  totalsColumn: {
    // width: '400px',
  },
}

export const useStyles = createUseStyles(styles)

const AlsoCartPrintCart = () => {
  const monthlyTotal = useSelector(totalMonthlySelector)

  const alsoCart = useSelector(state => state.alsoCart)
  const classes = useStyles()

  const { t } = useTranslation('cart')

  const vat = useSelector(({ config: { vat } }) => vat)

  return (
    <div className={classes.root}>
      <h1>Warenkorb</h1>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.text}>Art</th>
            <th className={clsx(classes.text, classes.expand)}>Name</th>
            <th className={classes.numeral}>{t('monthlyPrice')}</th>
            <th className={classes.numeral}>{t('quantity')}</th>
            <th className={clsx(classes.numeral, classes.totalsColumn)}>
              {t('monthlyTotal')}
            </th>
          </tr>
        </thead>
        {alsoCart.workplaces.map((line, index, workplaces) => (
          <AlsoCartPrintLine
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            workplaceNumber={sumBy(workplaces.slice(0, index), 'quantity') + 1}
            workplace={line}
          />
        ))}
        <tfoot>
          <tr className={classes.firstFooterRow}>
            <td
              colSpan="5"
              className={clsx(
                classes.numeral,
                classes.totalCell,
                classes.totalsColumn,
              )}
            >
              <div>
                <div>
                  <span>{t('monthly')}</span>
                  <FormattedNumber
                    value={monthlyTotal}
                    style="currency"
                    currency="EUR"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td
              colSpan="5"
              className={clsx(
                classes.numeral,
                classes.totalCell,
                classes.totalsColumn,
              )}
            >
              <div>
                <div>
                  <span>{t('tax', { vat: vat * 100 })}</span>
                  <FormattedNumber
                    value={monthlyTotal * vat}
                    style="currency"
                    currency="EUR"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td
              colSpan="5"
              className={clsx(
                classes.numeral,
                classes.totalCell,
                classes.totalsColumn,
              )}
            >
              <div>
                <div>
                  <span>{t('monthlyTotal')}</span>
                  <FormattedNumber
                    value={monthlyTotal * (1 + vat)}
                    style="currency"
                    currency="EUR"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default AlsoCartPrintCart
