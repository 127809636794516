import * as Sentry from '@sentry/browser'
import { LanguageProvider } from 'Context/LanguageContext'
import history from 'Lib/history'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { addLocaleData, IntlProvider } from 'react-intl'
import de from 'react-intl/locale-data/de'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'
import App from './App'
import './Assets/css/foundation.css'
import ConnectedSCTheme from './ConnectedSCTheme'
import ConnectedTheme from './ConnectedTheme'
import i18n from './i18n'
import './index.css'
import './polyfill'
import store from './store'

Sentry.init({
  dsn: 'https://ac012d1d72ed427c8dc0811a80ff94bd@sentry.io/1387938',
})

addLocaleData([...de])

const persistor = persistStore(store)

ReactDOM.render(
  <IntlProvider locale="de">
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <LanguageProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                <ConnectedTheme>
                  <ConnectedSCTheme>
                    <App />
                  </ConnectedSCTheme>
                </ConnectedTheme>
              </Router>
            </PersistGate>
          </Provider>
        </LanguageProvider>
      </Suspense>
    </I18nextProvider>
  </IntlProvider>,
  document.getElementById('root'),
)
