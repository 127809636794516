export const RESELLER_INFO_FETCH_REQUEST = 'RESELLER_INFO::FETCH_REQUEST'
export const RESELLER_INFO_FETCH_FAIL = 'RESELLER_INFO::FETCH_FAIL'
export const RESELLER_INFO_FETCH_SUCCESS = 'RESELLER_INFO::FETCH_SUCCESS'

const initialState = {
  data: null,
  fetching: false,
}

export const fetchResellerInfo = () => ({
  type: RESELLER_INFO_FETCH_REQUEST,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESELLER_INFO_FETCH_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case RESELLER_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.data,
        fetching: false,
      }
    }
    case RESELLER_INFO_FETCH_FAIL: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
