import Select from 'Components/Common/Layout/Select'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import { Trans } from 'react-i18next'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { typedServicesSelector } from 'Selectors/common/listSelectors'
import styled from 'styled-components/macro'

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
`

/* eslint-disable react/style-prop-object */

const ServiceLabel = ({ name, netPrice }) => (
  <Label>
    {name} <FormattedNumber value={netPrice} style="currency" currency="EUR" />
  </Label>
)

ServiceLabel.propTypes = {
  name: PropTypes.string.isRequired,
  netPrice: PropTypes.number.isRequired,
}

const ServiceSelect = ({ type }) => {
  const services = useSelector(typedServicesSelector(type))

  const options = services
    .sort((a, b) => a.position - b.position)
    .map(service => ({
      value: service.id,
      label: <ServiceLabel {...service} />,
    }))

  return (
    <Field
      name={`services.${type}`}
      render={({ input }) => (
        <Select
          className="r-select"
          classNamePrefix="r-select"
          options={options}
          onChange={value => {
            const service = value ? value.value : null
            input.onChange(service)
          }}
          value={options.find(v => v.value === input.value)}
          isClearable
          placeholder={<Trans i18nKey="withoutOnlineService">{type}</Trans>}
        />
      )}
    />
  )
}

ServiceSelect.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ServiceSelect
