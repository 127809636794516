import account from './account'
import auth from './auth'
import cart from './cart'
import catalog from './catalog'
import checkout from './checkout'
import common from './common'
import contact from './contact'
import footer from './footer'
import headings from './headings'
import navigation from './navigation'
import notifications from './notifications'
import order from './order'
import validation from './validation'

export default {
  account,
  auth,
  cart,
  catalog,
  checkout,
  common,
  contact,
  footer,
  headings,
  navigation,
  notifications,
  order,
  validation,
}
