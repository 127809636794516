import classnames from 'classnames'
import { Counter, StyledFontAwesomeIcon } from 'Components/Common/UI/Header'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import config from 'Lib/config'
import { alsoTotalCartQuantity, totalCartQuantity } from 'Lib/cartHelper'
import { MenuLink } from './Header'

const mapStateToProps = state => ({
  quantity: config.reseller.also
    ? alsoTotalCartQuantity(state)
    : totalCartQuantity(state),
})

const enhance = compose(withRouter, connect(mapStateToProps))

const CartLink = ({ quantity }) => (
  <MenuLink
    to="/warenkorb"
    label={
      <span className="fa-layers fa-fw hide-for-small-only">
        <StyledFontAwesomeIcon icon="shopping-cart" />

        <Counter className={classnames('fa-layers-counter')}>
          {quantity}
        </Counter>
      </span>
    }
  />
)

CartLink.propTypes = {
  quantity: PropTypes.number.isRequired,
}

export default enhance(CartLink)
