import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { isValidBIC, isValidIBAN } from 'ibantools'
import classnames from 'classnames'
import { renderField } from 'Components/Common/Layout/Form/FormField'
import {
  FieldGroup,
  FieldGroupHeader,
  FieldgroupComment,
} from 'Components/Common/Checkout/StyledComponents'

const BankDetailsForm = ({ source, note }) => {
  const { t } = useTranslation('checkout')

  return (
    <FieldGroup className={classnames('grid-x', 'grid-padding-x')}>
      <div className="cell small-12">
        <FieldGroupHeader>{t('form.paymentData')}</FieldGroupHeader>
      </div>
      {note && (
        <div className="cell small-12">
          <FieldgroupComment>{note}</FieldgroupComment>
        </div>
      )}
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          component={renderField}
          label={t('form.bank')}
          name={source ? `${source}.bank` : 'bank'}
          id={source ? `${source}.bank` : 'bank'}
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          validate={value => value && !isValidIBAN(value) && 'invalidIBAN'}
          component={renderField}
          label={t('form.IBAN')}
          name={source ? `${source}.IBAN` : 'IBAN'}
          id={source ? `${source}.IBAN` : 'IBAN'}
        />
      </div>
      <div className="cell medium-4">
        <Field
          type="text"
          expand
          validate={value => value && !isValidBIC(value) && 'invalidBIC'}
          component={renderField}
          label={t('form.BIC')}
          name={source ? `${source}.BIC` : 'BIC'}
          id={source ? `${source}.BIC` : 'BIC'}
        />
      </div>
    </FieldGroup>
  )
}

BankDetailsForm.propTypes = {
  source: PropTypes.string,
  note: PropTypes.string,
}

BankDetailsForm.defaultProps = {
  source: null,
  note: null,
}

export default BankDetailsForm
