import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { FormattedNumber } from 'react-intl'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './styles'
import { LoadingIndicator } from '../Layout/LoadingIndicator'
import QuantitySpinner from '../QuantitySpinner'

/* eslint-disable react/style-prop-object */

const useStyles = createUseStyles(styles)

const PriceButton = ({
  quantity,
  price,
  fetching,
  style,
  height,
  children,
  onClick,
  onDecreaseClick,
  onIncreaseClick,
  exact,
  className,
  hideAngle,
  loading,
  hollow,
}) => {
  const { t } = useTranslation('catalog')
  const classes = useStyles({ hollow })
  return (
    <div
      className={classnames(classes.box, className)}
      style={{ ...style, height: `${height}px` }}
    >
      <div className={classes.halfContainer}>
        {quantity && (
          <QuantitySpinner
            increment={onIncreaseClick}
            decrement={onDecreaseClick}
            quantity={quantity}
            className={classes.quantity}
          />
        )}
        {fetching ? (
          <LoadingIndicator small />
        ) : (
          <div className={classes.price}>
            {!exact && (
              <span>
                {t('priceFrom')}
                &nbsp;
              </span>
            )}
            <FormattedNumber style="currency" currency="EUR" value={price} />
          </div>
        )}
      </div>
      <div className={classes.halfContainer}>
        <button onClick={onClick} className={classes.priceText}>
          {children}
          {!hideAngle &&
            (loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '4px' }}
                icon="circle-notch"
                spin
              />
            ) : (
              <FontAwesomeIcon
                style={{ marginLeft: '4px' }}
                icon="angle-right"
              />
            ))}
        </button>
      </div>
    </div>
  )
}

PriceButton.propTypes = {
  quantity: PropTypes.number,
  price: PropTypes.number,
  fetching: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line
  height: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  onDecreaseClick: PropTypes.func,
  onIncreaseClick: PropTypes.func,
  exact: PropTypes.bool,
  className: PropTypes.string,
  hideAngle: PropTypes.bool,
  loading: PropTypes.bool,
  hollow: PropTypes.bool,
}

PriceButton.defaultProps = {
  height: 40,
  quantity: undefined,
  price: undefined,
  exact: false,
  loading: false,
  fetching: false,
  hideAngle: false,
  className: undefined,
  onDecreaseClick: undefined,
  onIncreaseClick: undefined,
  style: {},
  hollow: false,
}

export default PriceButton

/* eslint-ensable react/style-prop-object */
