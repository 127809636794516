import React, { useEffect, useRef } from 'react'
import Title from 'Components/Common/Title'
import MetaData from 'Components/Common/Layout/MetaData'
import Section from 'Components/Common/Layout/Section'
import useStoreView from 'Hooks/useStoreView'

function loadExplanation(id, ref) {
  if (!id) {
    return
  }
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = 'CookieDeclaration'
  script.src = `https://consent.cookiebot.com/${id}/cd.js`
  ref.current.appendChild(script)
}

const CookieDeclaration = () => {
  const cookieBotId = useStoreView('cookieBotId')
  const ref = useRef(null)

  useEffect(() => {
    loadExplanation(cookieBotId, ref)
  }, [cookieBotId])

  return (
    <>
      <MetaData title="Cookies" canonical="cookies" />
      <Section title={<Title i18nKey="cookies" />}>
        <div ref={ref} />
      </Section>
    </>
  )
}
export default CookieDeclaration
