import color from 'color'
import AlsoCartPrintCart from 'Components/Also/Cart/Print/Cart'
import Slug from 'Components/Aluxo/Slug'
import Account from 'Components/Common/Account/Account'
import Cart from 'Components/Common/Cart'
import Checkout from 'Components/Common/Checkout/Checkout'
import Home from 'Components/Common/Home'
import ContactSection from 'Components/Common/Home/Contact/ContactSection'
import Footer from 'Components/Common/Home/Footer'
import PrintFooter from 'Components/Common/Home/Footer/PrintFooter'
import DocumentHeadTags from 'Components/Common/Layout/DocumentHeadTags'
import Header from 'Components/Common/Layout/Header'
import HeaderBar from 'Components/Common/Layout/HeaderBar'
import OutdatedBrowserStyle from 'Components/Common/Layout/OutdatedBrowserStyle'
import PasswordForm from 'Components/Common/Layout/PasswordForm'
import StickyResellerNote from 'Components/Common/Layout/StickyResellerNote'
import Product from 'Components/Common/Product/Product'
import useStoreView from 'Hooks/useStoreView'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Switch } from 'react-router-dom'
import { FETCH_ALL_REQUEST } from 'Sagas/fetchSaga'
import styled, { createGlobalStyle } from 'styled-components/macro'
import Favicon from 'Components/Common/Layout/Favicon'
import colors from './Assets/styles/colors'
import CookieBotIntegration from './CookieBotIntegration'
import CookieDeclaration from './CookieDeclaration'
import GoogleAnalyticsIntegration from './GoogleAnalyticsIntegration'
import Route from './ScrollToTopRoute'

const RootElement = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const GlobalStyle = createGlobalStyle`
  color: ${colors.greyishBrownThree};
  *:focus {
    outline-color: ${props =>
      color(props.theme.firstColor)
        .fade(0.8)
        .string()};
  }
`

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: FETCH_ALL_REQUEST })
  }, [])

  const [password, setPassword] = useState(
    localStorage.getItem('password') || '',
  )
  const passwordProtected = useStoreView('password')
  if (passwordProtected && passwordProtected !== password) {
    return (
      <PasswordForm
        password={password}
        setPassword={setPassword}
        passwordProtected={passwordProtected}
      />
    )
  }

  return (
    <Switch>
      <Route path="/warenkorb/print">
        <div>
          <AlsoCartPrintCart />
          <PrintFooter />
        </div>
      </Route>
      <Route>
        <RootElement>
          <Favicon />
          <DocumentHeadTags />
          <GlobalStyle />
          <OutdatedBrowserStyle />
          <GoogleAnalyticsIntegration />
          <HeaderBar />
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/product/:slug" exact component={Product} />
            <Route path="/warenkorb" exact component={Cart} />
            <Route path="/kasse" exact component={Checkout} />
            <Route path="/account" component={Account} />
            <Route path="/cookies" component={CookieDeclaration} />
            <Route path="/:slug" component={Slug} />
          </Switch>
          <ContactSection />
          <Footer />
          <StickyResellerNote />
          <CookieBotIntegration />
        </RootElement>
      </Route>
    </Switch>
  )
}

export default App
