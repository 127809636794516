export default {
  pageNotFound: 'Seite nicht gefunden',
  select: 'Bitte wählen',
  moreInformation: 'Klicken Sie hier für mehr Informationen',
  privacyStatement: 'Datenschutzerklärung',
  terms: 'Allgemeine Geschäftsbedingungen',
  impressum: 'Impressum',
  links: {
    account: 'Mein Konto',
  },
  actions: {
    save: 'Speichern',
  },
  feedback: {
    saved: 'Gespeichert',
    genericError: 'Es ist ein Fehler aufgetreten',
  },
}
