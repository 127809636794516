import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { zipObjectDeep, get, groupBy } from 'lodash'
import queryString from 'query-string'
import messages from 'I18n/messages'
import { fetchTranslations, postMissingTranslationKey } from 'Lib/fetch'

const parsed = queryString.parse(window.location.search)

const { showTranslationKeys } = parsed

const missingKeyHandler = (() => {
  const missingKeyCache = []
  return (lng, ns, key) => {
    if (!showTranslationKeys && !missingKeyCache.includes(`${ns}:${key}`)) {
      missingKeyCache.push(`${ns}:${key}`)
      postMissingTranslationKey({
        namespace: ns,
        key,
        type: 'frontend',
      })
    }
  }
})()

export const defaultLang = localStorage.getItem('language') || 'de'

i18n.use(LanguageDetector).init({
  // have a common namespace used around the full app
  fallbackLng: !showTranslationKeys ? 'de' : undefined,
  ns: ['common'],
  defaultNS: 'common',
  resources: messages,
  appendNamespaceToMissingKey: true,

  cache: {
    enabled: false,
  },

  react: {
    wait: false,
  },

  interpolation: {
    escapeValue: false, // not needed for react as it does escape per default to prevent xss!
  },

  saveMissing: true,
  missingKeyHandler,
})

if (!showTranslationKeys) {
  fetchTranslations().then(res => {
    const entities = res.data['hydra:member']

    const availableLocales = res.headers['x-available-locales'].split(', ')

    const namespaces = groupBy(entities, 'namespace')

    Object.keys(namespaces).forEach(namespace => {
      availableLocales.forEach(lang => {
        i18n.addResourceBundle(
          lang,
          namespace,
          zipObjectDeep(
            namespaces[namespace].map(s => s.key),
            namespaces[namespace].map(
              s => get(s, `translations[${lang}].content`) || s.content,
            ),
          ),
          true,
          true,
        )
      })
    })

    i18n.changeLanguage(defaultLang)
  })
}

export default i18n
