import styled from 'styled-components/macro'
import colors from 'Assets/styles/colors'

export const Container = styled.div`
  font-family: Titillium Web;
  border-radius: 4px;
  background-color: ${colors.white};
  min-height: 128px;

  & > div {
    padding: 20px;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }

  border: solid 1px ${colors.whiteEight};
`

export const QuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.firstColor};
  font-size: 15px;
  line-height: 1.47;
  margin: 40px 0;
  width: 15% !important;

  border-right: solid 1px ${colors.whiteFive};

  & > * {
    margin: 8px;
  }
`

export const Trash = styled.div`
  color: ${colors.warmGreyFour};
  cursor: pointer;
  font-size: 22px;
  opacity: 0.7;
  width: 30%;
  text-align: center;
  transition: opacity ease-out 400ms;

  &:hover {
    opacity: 1;
  }
`
export const TermTitle = styled.div`
  font-size: 15px;
  line-height: 1.47;
  text-align: center;
  color: ${colors.warmGreyFour};
  text-transform: uppercase;
`
export const PriceOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -10px 0;
  & > * {
    margin: 10px 0;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.greyishBrownSix};
  /* height: 100%; */
  font-size: 22px;
  justify-content: flex-end;
  font-weight: bold;
  line-height: 1.14;

  & > span {
    display: block;
  }
`
