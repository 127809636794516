import colors from 'Assets/styles/colors'
import color from 'color'
import DeviceAccessoryProduct from 'Components/Aluxo/Device/DeviceAccessoryProduct'
import Section from 'Components/Common/Layout/Section'
import Slider from 'Components/Common/Layout/Slider'
import typography from 'Components/Common/Layout/typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { accessoryForVariantAndGenericSelector } from 'Selectors/common/accessorySelectors'

const styles = theme => ({
  container: {
    ...typography.text,
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    border: {
      width: 1,
      style: 'solid',
      color: colors.whiteFive,
    },
    width: '100%',
    padding: [40, 64],
    margin: [16, 0],
  },
  selectContainer: {
    '& .is-focused:not(.is-open) > .Select-control': {
      boxShadow: `0 0 5px ${theme.firstColor}`,
      border: `1px solid ${theme.firstColor}`,
    },
    '& .Select-option': {
      '&.is-selected': {
        backgroundColor: color(theme.firstColor)
          .fade(0.5)
          .string(),
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '&.is-focused': {
        backgroundColor: color(theme.firstColor)
          .fade(0.3)
          .string(),
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
  image: {
    width: '100%',
  },
  detailsHeader: {
    font: {
      size: '18px',
      weight: 'bold',
    },
    lineHeight: 1.39,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    color: colors.greyishBrownFour,
    marginBottom: 8,
  },
  accessoryContainer: {
    display: 'flex',
    height: 200,

    flexDirection: 'column',
    alignItems: 'center',
  },
  productName: {
    fontSize: '20px',
  },
})

const useStyles = createUseStyles(styles)

const DeviceAccessories = ({ input, device, variant }) => {
  const deviceAccessoryProducts = useSelector(state =>
    accessoryForVariantAndGenericSelector(state, { device, variant }),
  )
  const classes = useStyles()
  const { t } = useTranslation('catalog')
  const {
    values: { qualifiedBundles },
  } = useFormState({ subscription: { values: true } })

  if (deviceAccessoryProducts.length === 0) {
    return null
  }
  return (
    <Section padding="80">
      <div>
        <div className={classes.detailsHeader}>{t('addMoreAccessories')}</div>
        <Slider perRow={4} settings={{ infinite: false }}>
          {deviceAccessoryProducts.map(({ product, variant }) => (
            <div key={variant.id}>
              <DeviceAccessoryProduct
                bundled={qualifiedBundles.some(b =>
                  b.accessories.some(p => p.id === variant.id),
                )}
                selected={input.value.some(acc => acc.variant === variant.id)}
                toggleSelected={
                  input.value.some(acc => acc.variant === variant.id)
                    ? () =>
                        input.onChange(
                          input.value.filter(acc => acc.variant !== variant.id),
                        )
                    : () =>
                        input.onChange([
                          ...input.value,
                          {
                            product: product.id,
                            variant: variant.id,
                            quantity: 1,
                            netPrice: variant.netPrice,
                          },
                        ])
                }
                product={product}
                variant={variant}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  )
}

DeviceAccessories.propTypes = {
  device: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

export default DeviceAccessories
