/* eslint-disable react/style-prop-object */
import clsx from 'clsx'
import { useStyles } from 'Components/Also/Cart/Print/Cart'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'

const AlsoCartPrintServices = ({ services, quantity }) => {
  const classes = useStyles()

  const serviceDetails = useSelector(state =>
    Object.values(services || {}).map(id => state.alsoServices.entities[id]),
  )

  return serviceDetails.map(s => (
    <tr>
      <td className={clsx(classes.textLine, classes.colorShaded)}>
        {s.service.category}
      </td>
      <td className={clsx(classes.text, classes.expand)}>{s.service.name}</td>
      <td className={classes.numeral}>
        <FormattedNumber
          value={s.monthlyPrice}
          style="currency"
          currency="EUR"
        />
      </td>
      <td className={classes.numeral}>{quantity}</td>

      <td className={clsx(classes.numeral, classes.totalsColumn)}>
        <FormattedNumber
          value={s.monthlyPrice * quantity}
          style="currency"
          currency="EUR"
        />
      </td>
    </tr>
  ))
}

AlsoCartPrintServices.propTypes = {
  services: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
}

export default AlsoCartPrintServices
